import IRMSServiceRequest from "../stores/rms-service-requests/models/IRMSServiceRequest";
import IServiceRequestTask from "../stores/tasks/models/ITask";

export default class ServiceRequestHelper {
  public static bikeForTask(sr: IRMSServiceRequest, task: IServiceRequestTask) {
    if(!sr.bikes){
      return null
    }
    const { bike_id } = task;
    const filtr = sr.bikes.filter(b => b.id === bike_id);
    if (filtr.length > 0) {
      return filtr[0];
    }
    return null;
  }
}
