import * as React from "react";

import IBike from "stores/bikes/models/IBike";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

export interface IBikeTimelineObjectProps {
  data:IBike
}

const BikeTimelineObject =(props: IBikeTimelineObjectProps) =>{
  const { data } = props;
  if (!data) {
    return <Loading isLoading={true} />;
  }
  return (
    <div>
      <div>
        <div>
          <Link to={`/bikes/${data.id}`}>
          {data.serial_number}
          </Link>
        </div>
      </div>      
    </div>
  );
}
export default BikeTimelineObject