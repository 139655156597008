import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";

import DatePickerButton from './DatePickerButton'

export interface IToolBarProps {
  label: any;
  localizer: any;
  view: any;
  views: any;
  onNavigate: any;
  onView: any;
};

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "16px"
  }
}));

const Toolbar = (props: IToolBarProps) => {
  const classes = useStyles(props);
  const [selectedDate, setSelectedDate] = useState(null);

  const {
    localizer: { messages },
    label,
  } = props

  const navigateOptions = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  }

  // const views = {
  //   MONTH: 'month',
  //   WEEK: 'week',
  //   WORK_WEEK: 'work_week',
  //   DAY: 'day',
  //   AGENDA: 'agenda',
  // }

  const navigate = (action: any) => {
    props.onNavigate(action)
  }

  const viewFunc = (view: any) => {
    props.onView(view)
  }

  const viewNamesGroup = (messages: any) => {
    let viewNames = props.views
    const view = props.view

    if (viewNames.length > 1) {
      return viewNames.map((name: any) => (
        <Button
          key={name}
          disabled={view === name ? true : false}
          onClick={() => viewFunc(name)}
        >
          {messages[name].toUpperCase()}
        </Button>
      ))
    }
  }

  const onSelectDate = (day: any) => {
    setSelectedDate(day)
    props.onNavigate("DATE", day.toDate())
    props.onView('day')
  };

  return (
    <Grid container={true} className={classes.root}>
      <Grid item={true} xs={4}>
        <Grid container={true} justify="flex-start">
          <ButtonGroup>
            <Button onClick={() => navigate(navigateOptions.PREVIOUS)}>{navigateOptions.PREVIOUS}</Button>
            <Button onClick={() => navigate(navigateOptions.TODAY)}>{navigateOptions.TODAY}</Button>
            <Button onClick={() => navigate(navigateOptions.NEXT)}>{navigateOptions.NEXT}</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item={true} xs={4}>
        <Grid container={true} justify="center">
          <DatePicker
            disableToolbar
            variant="dialog"
            value={selectedDate}
            onChange={onSelectDate}
            TextFieldComponent={
              ({ inputProps, onClick, inputRef }) =>
                <DatePickerButton {...props}
                  text={label}
                  inputProps={inputProps}
                  onClick={onClick}
                  inputRef={inputRef}
                />
            }
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={4}>
        <Grid container={true} justify="flex-end">
          <ButtonGroup>
            {viewNamesGroup(messages)}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Toolbar;
