import IAction from "../IAction";
import ICustomer from './models/ICustomer';
export default class CustomerAction {
  public static readonly GET_CUSTOMER: string = "CustomerAction.GET_CUSTOMER";
  public static readonly GET_CUSTOMER_SUCCESS: string = "CustomerAction.GET_CUSTOMER_SUCCESS";
  public static readonly SET_CURRENT_CUSTOMER: string = "CustomerAction.SET_CURRENT_CUSTOMER";
  public static readonly TIMELINE: string = "CustomerAction.TIMELINE";
  public static readonly TIMELINE_SUCCESS: string = "CustomerAction.TIMELINE_SUCCESS";
  public static readonly SEARCH: string = "CustomerAction.SEARCH";
  public static readonly SEARCH_RESULTS: string = "CustomerAction.SEARCH_RESULTS";
  public static readonly ERROR: string = "CustomerAction.ERROR";
  public static getCustomer(id: string,store:string): IAction<string> {
    return {
      payload: {id,store},
      type: CustomerAction.GET_CUSTOMER
    };
  }

  public static getCustomerSuccess(customer: ICustomer): IAction<string> {
    return {
      payload: customer,
      type: CustomerAction.GET_CUSTOMER_SUCCESS
    };
  }
  public static setSelectedCustomer(customer: ICustomer): IAction<string> {
    return {
      payload: customer,
      type: CustomerAction.SET_CURRENT_CUSTOMER
    };
  }

  public static getTimeline(id:string): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: id,
      type: CustomerAction.TIMELINE
    }
  }
  public static getTimelineSuccess(timeline:any): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: timeline,
      type: CustomerAction.TIMELINE_SUCCESS
    }
  }
  public static search(val:string): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: val,
      type: CustomerAction.SEARCH
    }
  }

  public static searchResults(val:ICustomer[]): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: val,
      type: CustomerAction.SEARCH_RESULTS
    }
  }
  public static error(error: Error): IAction<string> {
    return {
      payload: error,
      type: CustomerAction.ERROR
    };
  }
}
