import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";
import React from "react";
import { TextField } from "final-form-material-ui";

export interface IBikeSearch {
  values?:any
}

const BikeSearch = (props: IBikeSearch) => {
  return (
    <>
      <Grid item={true} xs={10}>
        <Field style={{ display: "flex" }} label="Bike Serial Number (RB519J1...)" name="bike.serial_number" component={TextField} type="input" defaultValue={props.values ? props.values.serial_number : null} />
      </Grid>
    </>
  );
};

export default BikeSearch;
