import * as React from "react";

import { Button, Card, CardContent, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

import ICustomer from "stores/customers/models/ICustomer";
import Loading from "../Loading/Loading";
import RMSTable from "components/Table/RMSTable";
import SyntaxHighlighter from "react-syntax-highlighter";

const expandedView = (orderJson: any) => {
  console.log(orderJson);

  return (
    <div>
      <SyntaxHighlighter>{JSON.stringify(orderJson, null, 2)}</SyntaxHighlighter>
    </div>
  );
};
export interface IOrderTimelineObjectProps {
  data: any;
  customer: ICustomer;
}

interface IOrderTimelineObjectState {
  showDetails: boolean;
}
class OrderTimelineObject extends React.Component<IOrderTimelineObjectProps, IOrderTimelineObjectState> {
  public showDetails = this._showDetails.bind(this);
  public state = { showDetails: false };
  public render() {
    const { customer, data } = this.props;

    if (!data) {
      return <Loading isLoading={true} />;
    }

    const orderJson = data;

    return (
      <>
        <Typography variant="caption" component="h2">
          {customer && customer.shopify_store && (
            <Button target="_blank" href={`https://${customer.shopify_store}/admin/orders/${data.legacyResourceId}`}>
              Order: {data.name}
            </Button>
          )}
        </Typography>

        <Typography variant="caption">
          {data.store} | ${orderJson.totalPriceSet.shopMoney.amount} ({orderJson.totalPriceSet.shopMoney.currencyCode})
        </Typography>
        <ul>
          <li>Payment Status: {orderJson.financial_status}</li>
          <li>Fullfillment Status: {orderJson.fulfillment_status}</li>
        </ul>

        {orderJson.fulfillments.length > 0 && (
          <Card className="" variant="outlined">
            <CardContent>
              <Typography component="h4">Line Items:</Typography>
              <ul>
                {orderJson.lineItems &&
                  orderJson.lineItems.edges.map((lineItem: any, idx: number) => {
                    

                  return <p key={idx}>{lineItem.node.sku}: {lineItem.node.name} ({lineItem.node.quantity})</p>;
                  })}
              </ul>

              <Typography component="h4">Fulfillments:</Typography>
              <ul>
                {orderJson.fulfillments &&
                  orderJson.fulfillments.map((lineItem: any, idx: number) => {
                    if (lineItem.trackingInfo.length > 0) {
                      return lineItem.trackingInfo.map((to: any,idxx: number) => (
                        <li key={idxx}>
                          <a href={to.url}>{to.number}</a>
                        </li>
                      ));
                    }

                    return <p>N/A</p>;
                  })}
              </ul>
            </CardContent>
          </Card>
        )}

        <RMSTable>
          <>
            <TableBody>
              <TableRow>
                <TableCell>{orderJson.note}</TableCell>
              </TableRow>
              {orderJson.line_items &&
                orderJson.line_items.map((lineItem: any, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{lineItem.name}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </>
        </RMSTable>
        <>
          <Button variant="contained" color="secondary" onClick={this.showDetails}>
            {this.state.showDetails ? "Hide" : "Show"} Order Details
          </Button>
        </>
        {this.state.showDetails && orderJson ? expandedView(orderJson) : null}
      </>
    );
  }

  private _showDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
}
export default OrderTimelineObject;
