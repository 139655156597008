import ApiService, { ENDPOINTS } from "../../utilities/api-service";
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useState } from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import SelectInput from "components/common/FormInputs/SelectInput";
import { TextField } from "final-form-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

interface IWarrantyCodeGeneratorProps {}
interface IWCGValues {
  warranty_type: string;
  issue: string;
  parent_part: string;
  child_part: string;
  quantity: number;
  zendesk: string;
  child_serial_number?: string;
  parent_serial_number?: string;
}

const WarrantyTypeHashMap = {
  WW: {label:"(WW) Warranty Warehouse",category:"normal"},
  WF: {label:"(WF) Warranty Factory",category:"normal"},
  WO: {label:"(WO) General Warranty Issue",category:"normal"},
  WS: {label:"(WS) Warranty Shipping",category:"shipping"},
  CS: {label:"(CS) Customer Service",category:"cs"},
};

const useStyles1 = makeStyles((theme) => ({
  buttonGroup: {
    wordSpacing: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
const compare = (a: any, b: any) => {
  if (a.id > b.id) return 1;
  if (b.id > a.id) return -1;

  return 0;
};
const WarrantCodeGenerator = (props: IWarrantyCodeGeneratorProps) => {
  const classes = useStyles1(props);
  const [codes, setCodes] = useState([]);
  const [parentItem, setParentItem] = useState(null);
  const [isCS, setIsCS] = useState(false);
  const [childItem, setChildItem] = useState(null);
  const [warrantyType, setWarrantyType] = useState(null);
  const [curIssue, setCurIssue] = useState(null);
  const [needsParentSerial, setNeedsParentSerial] = useState(false);
  const [needsChildSerial, setNeedsChildSerial] = useState(false);

  const onSubmit = async (values: IWCGValues) => {
    if(isCS){
      values.parent_part = null
      values.parent_serial_number = null
      values.child_serial_number = null
    }
    if (!needsChildSerial) {
      values.child_serial_number = null;
    }
    if (!needsParentSerial) {
      values.parent_serial_number = null;
    }
    const codeResponse = await ApiService.post(ENDPOINTS.wcg, { ...values });
    if (codeResponse) {
      codes.push(codeResponse.data.code);
      const newCodes = codes.map((c) => c);
      setCodes(newCodes);
    }
  };
  const wcgConfigs = useSelector((state: IStore) => state.commonData.warrantyCodeGeneratorConfigs);
  if (!wcgConfigs) {
    return <Loading isLoading={true} />;
  }

  const warrantyTypes = Object.keys(WarrantyTypeHashMap);

  const needle = warrantyType ? WarrantyTypeHashMap[warrantyType].category : "normal";
  const srcIssues = wcgConfigs.issues.filter((iss) => iss.category === needle);

  const issuesOptions = srcIssues.sort(compare).map((issue, indx) => ({ label: issue.name, value: issue.id, issue }));
  const partsOptions = wcgConfigs.parts.sort(compare).map((part, indx) => ({ label: part.name, value: part.id, part }));
  const warrantyTypesMap = warrantyTypes.map((t) => ({ label: WarrantyTypeHashMap[t].label, value: t, warrantyType: t }));
  const ReactSelectAdapterWCG = ({ input, label, itemType, options, ...rest }: any) => {
    let defaultVal = null;

    switch (itemType) {
      case "issue":
        defaultVal = issuesOptions.filter((p) => p.value === curIssue)[0];
        break;
      case "parent_part":
        defaultVal = partsOptions.filter((p) => p.value === parentItem)[0];
        break;
      case "child_part":
        defaultVal = partsOptions.filter((p) => p.value === childItem)[0];
        break;
      case "warranty_type":
        defaultVal = warrantyTypesMap.filter((v) => v.value === warrantyType)[0];
        break;
    }

    return (
      <SelectInput
        {...input}
        {...rest}
        name={input.name}
        inputLabel={label}
        optionLabel={label}
        defaultValue={defaultVal}
        options={options}
        onChange={(value: any, option: any) => {
          if (option.part && (itemType === "parent_part" || itemType === "child_part")) {
            const { parent_with_serial, child_with_serial } = option.part;
            if (itemType === "parent_part") {
              setParentItem(value);
              setNeedsParentSerial(parent_with_serial);
            } else if (itemType === "child_part") {
              setChildItem(value);
              setNeedsChildSerial(child_with_serial);
            }
          } else if (option.warrantyType) {
            setWarrantyType(value);
            if (value === "CS") {
              setNeedsParentSerial(false);
              setIsCS(true);
            } else {
              setIsCS(false);
            }
          } else {
            setCurIssue(value);
          }
          input.onChange(value);
        }}
      />
    );
  };
  const allCodes = codes.join(", ");
  return (
    <>
      <Box style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography variant="h3">Warranty Code Generator</Typography>
      </Box>
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate={true}>
            <Paper style={{ padding: 16 }}>
              <Grid container={true} alignItems="flex-start" spacing={2}>
                <Grid item={true} xs={12}>
                  <Field itemType={"warranty_type"} name="warranty_type" component={ReactSelectAdapterWCG} label="Warranty Type" options={warrantyTypesMap} fullWidth={true} required={true} />
                </Grid>

                {!isCS && (
                  <Grid item={true} xs={12}>
                    <Field itemType={"parent_part"} name="parent_part" component={ReactSelectAdapterWCG} label="Parent Item" options={partsOptions} fullWidth={true} required={true} />
                  </Grid>
                )}
                {(needsParentSerial && !isCS) && (
                  <Grid item={true} xs={12}>
                    <Field name="parent_serial_number" component={TextField} label="Serial Number" fullWidth={true} required={true} />
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <Field name="child_part" itemType={"child_part"} component={ReactSelectAdapterWCG} label="Child Item" options={partsOptions} fullWidth={true} required={true} />
                </Grid>
                {(needsChildSerial && !isCS) && (
                  <Grid item={true} xs={12}>
                    <Field name="child_serial_number" component={TextField} label="Serial Number (Motor/Battery...)" fullWidth={true} required={true} />
                  </Grid>
                )}
                <Grid item={true} xs={12}>
                  <Field name="issue" itemType={"issue"} component={ReactSelectAdapterWCG} label="Issue" options={issuesOptions} fullWidth={true} required={true} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field name="quantity" fullWidth={true} required={true} type="number" component={TextField} label="Quantity" />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field name="zendesk" fullWidth={true} required={false} component={TextField} label="Zendesk" />
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 16 }}>
                  <Box className={classes.buttonGroup}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                      Generate
                    </Button>
                    <Button
                      onClick={() => {
                        form.reset();
                        setParentItem(null);
                        setNeedsParentSerial(false);
                        setNeedsChildSerial(false);
                        setChildItem(null);
                        setWarrantyType(null);
                        setCodes([]);
                        setCurIssue(null);
                      }}
                      variant="outlined"
                      color="primary">
                      Clear
                    </Button>
                  </Box>
                </Grid>
                {codes.length > 0 && (
                  <Grid item={true} xs={12}>
                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Typography>Code</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="copyall_">
                            <TableCell>
                              <pre style={{ maxWidth: 300 }}>{allCodes}</pre>
                            </TableCell>
                            <TableCell>
                              <CopyToClipboard
                                text={allCodes}
                                onCopy={() => {
                                  console.log("copied all", allCodes);
                                }}>
                                <Button style={{ marginLeft: 10 }} color="primary" variant="contained">
                                  Copy All
                                </Button>
                              </CopyToClipboard>
                            </TableCell>
                          </TableRow>
                          {codes.map((code, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{code}</TableCell>
                              <TableCell>
                                <CopyToClipboard
                                  text={code}
                                  onCopy={() => {
                                    console.log("copied", code);
                                  }}>
                                  <Button style={{ marginLeft: 10 }} color="primary" variant="contained">
                                    Copy
                                  </Button>
                                </CopyToClipboard>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </form>
        )}
      />
    </>
  );
};
export default WarrantCodeGenerator;
