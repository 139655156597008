import { Slider, TableCell, TextField, WithStyles, createStyles } from "@material-ui/core";

import AppModal from "components/common/Modal/AppModal";
import BuildIcon from "@material-ui/icons/BuildRounded";
import BuildOutlined from "@material-ui/icons/BuildOutlined";
import { COLORS } from "theme/theme";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IBike from 'stores/bikes/models/IBike';
import { ICommonDataTypeProps } from "stores/common/models/CommonData";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IServiceRequestTask from "stores/tasks/models/ITask";
import IStore from "stores/IStore";
import IconButton from '@material-ui/core/IconButton';
import Loading from "components/Loading/Loading";
import MyChip from './MyChip';
import React from "react";
import TaskReassignmentForm from "components/ServiceRequest/TaskReassignmentForm";
import { Theme } from "react-select/src/types";
import store from "store";
import { withStyles } from '@material-ui/styles';

interface IServiceTaskProps extends WithStyles<typeof useStyles> {
  bike: IBike;
  edit?: boolean;
  onUpdate?: (task:IServiceRequestTask, type?: "update" | "delete") => void;
  serviceRequest: IRMSServiceRequest;
  task: IServiceRequestTask;
  srRefresh?: () => void
}

interface IServiceTaskState {
  currentTask: number;
  deleteTaskModalOpen: boolean;
  taskReassignmentModalOpen: boolean;
  taskStatusTypes?: ICommonDataTypeProps;
}

const useStyles = (theme: Theme) => createStyles({
  valueLabel: {
    '& *': {
      background: 'transparent',
      color: COLORS.BLUE_DARK,
    },
  }
});

class ServiceTaskItem extends React.Component<
  IServiceTaskProps,
  IServiceTaskState
> {
  public state: IServiceTaskState = {
    currentTask: null,
    deleteTaskModalOpen: false,
    taskReassignmentModalOpen: false
  };
  public onGravityChange = this._onGravityChange.bind(this);
  public onStatusChange = this._onStatusChange.bind(this);
  public onNotesBlur = this._onNotesBlur.bind(this);
  
  public componentDidMount() {
    this._checkForStatusTypes();
  }
  // FIXME: this is being deprectated
  public componentDidUpdate(prevProps: IServiceTaskProps) {
    this._checkForStatusTypes();
  }

  public onDeleteTask = () => {
    this.setState({ deleteTaskModalOpen: false }, () => {
      this.props.onUpdate(this.props.task, "delete");
    })
  }

  public render() {
    // if status types not yet loaded, wait....
    if (!this.state.taskStatusTypes) {
      if(!this.props.edit){
        return <span>Loading...</span>
      }
      return (
        <TableCell>
          <Loading isLoading={true} />
        </TableCell>
      );
    }

    const { classes, serviceRequest, task } = this.props;
    const { taskStatusTypes } = this.state;
    const gravity = task.true_gravity ? task.true_gravity : task.task.gravity;

    if (this.props.edit) {
      return (
        <>
          <TableCell component="th" scope="row">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.onStatusChange}
                  checked={task.status === taskStatusTypes.COMPLETE}
                  icon={<BuildOutlined color="disabled" />}
                  checkedIcon={<BuildIcon color="primary" />}
                  value={task.id}
                />
              }
              label={task.task.name}
            />
          </TableCell>
          <TableCell>
            {this._taskSummary()}
          </TableCell>
          <TableCell>
            <TextField
              placeholder="Notes"
              multiline={true}
              margin="normal"
              variant="outlined"
              defaultValue={task.notes}
              onBlur={this.onNotesBlur}
            />
          </TableCell>
          <TableCell>
            <Slider
              classes={{
                valueLabel: classes.valueLabel
              }}
              defaultValue={gravity}
              aria-label="Gravity"
              aria-labelledby="discrete-slider"
              aria-valuetext={`${gravity}`}
              valueLabelDisplay="on"
              step={1}
              marks={true}
              min={1}
              max={10}
              name="gravity"
              onChangeCommitted={this.onGravityChange}
            />
          </TableCell>
          <TableCell>
            <IconButton aria-label="delete" onClick={() => this.setState({ deleteTaskModalOpen: true })}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => this.setState({ taskReassignmentModalOpen: true })}>
              <EditIcon />
            </IconButton>
          </TableCell>

          <AppModal
            open={this.state.deleteTaskModalOpen}
            cancel={() => this.setState({ currentTask: null, deleteTaskModalOpen: false })}
            submit={() => this.onDeleteTask()}
          >
            Are you sure you want to delete this task?
          </AppModal>

          <AppModal
            open={this.state.taskReassignmentModalOpen}
            cancel={() => this.setState({ currentTask: null, taskReassignmentModalOpen: false })}>
            <TaskReassignmentForm
              isVisible={this.state.taskReassignmentModalOpen}
              serviceRequestId={serviceRequest.id}
              tasks={serviceRequest.tasks}
              currentTaskId={task.id}
              closeModal={() => { 
                this.setState({ taskReassignmentModalOpen: false }, () => {
                  this.props.srRefresh();
                })
              }}
            />
          </AppModal>
        </>
      );
    }
    return (
      this._taskSummary(false)
    );
  }
  private _onStatusChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.checked;
    const {task} = this.props
    task.status = val
      ? this.state.taskStatusTypes.COMPLETE
      : this.state.taskStatusTypes.CREATED;
    this.props.onUpdate(task);
  }
  private _onGravityChange(e: React.ChangeEvent<{}>, value: number | number[]) {
    const task = Object.assign({}, this.props.task);
    const tg = e.target as any
    task.true_gravity = parseInt(tg.textContent, 10);
    this.props.onUpdate(task);
  }
  private _onNotesBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    // const task = Object.assign({}, this.props.task);
    this.props.task.notes = e.target.value
    this.props.onUpdate(this.props.task);
  }
  private _checkForStatusTypes() {
    const storeState: IStore = store.getState();
    if (!this.state.taskStatusTypes && storeState.commonData.statusTypes) {
      this.setState({
        taskStatusTypes: storeState.commonData.statusTypes.taskStatusTypes
      });
    }
  }
  private _taskSummary(showStatus:boolean = true){
    const { task } = this.props;
    return <MyChip label={showStatus ? task.status : task.task.name} task={task} taskStatusTypes={this.state.taskStatusTypes} />
 
  }
}

export default withStyles(useStyles)(ServiceTaskItem);
