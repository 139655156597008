import * as QueryString from "query-string";

import React, { Component, Dispatch } from "react";

import { Action } from "redux";
import BikeSearch from "./BikeSearch";
import BikesList from "components/Bike/BikesList";
import Button from "@material-ui/core/Button";
import CustomerSearch from "./CustomerSearch";
import CustomersList from "components/Customer/CustomersList";
import { Form } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import IAction from "stores/IAction";
import { IDispatchToProps } from "interfaces";
import { ISearchResults } from "stores/search/SearchReducer";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import { RouteComponentProps } from "react-router-dom";
import SearchAction from "stores/search/SearchActions";
import SearchIcon from "@material-ui/icons/Search";
import Select from "react-select";
import ServiceRequestList from "components/ServiceRequest/ServiceRequestList/ServiceRequestList";
import ServiceRequestSearch from "./ServiceRequestSearch";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

interface ISearchProps {
  dispatch: (action: Action) => void;
  searchResults: ISearchResults;
  searchLoading: boolean;
}

type SearchOn = "customer" | "service_request" | "bike";
interface ISearchState {
  searchOn: SearchOn;
}

const options = [
  { label: "Customer Lookup", value: "customer" },
  { label: "Service Request Lookup", value: "service_request" },
  { label: "Bike by Serial Number", value: "bike" }
];

class Search extends Component<ISearchProps & RouteComponentProps, ISearchState> {
  public onSubmitSearch = this._onSubmitSearch.bind(this);
  constructor(props: any) {
    super(props);
    this.state = {
      searchOn: "customer"
    };
  }
  valuesForParams = () => {
    const qs = QueryString.parse(this.props.location.search);
    let values = null;
    if(qs && qs.values){
      try {
        values = JSON.parse(qs.values as string);
      } catch (error) {
        console.log(error, "error parsing search params");
      }
    }
    let store = "US"
    if(qs && qs.store){
      try {
        store = qs.store as string
      } catch (error) {
        console.log(error, "error parsing search params");
      }
    }
    return {values,store};
  };
  componentDidMount() {
    const qs = QueryString.parse(this.props.location.search);
    if (qs) {
      if (qs.searchOn) {
        const searchOn: SearchOn = qs.searchOn as SearchOn;
        this.setState({ searchOn: searchOn }, () => {
          const vals = this.valuesForParams();
          if (vals && vals.values) {
            const searchvals = {store:vals.store};

            searchvals[this.state.searchOn] = vals.values;
            this.onSubmitSearch(searchvals);
          }
        });
      }
    }
  }

  noResults = () => {
    return <Typography variant="body1">No results found.</Typography>;
  };

  searchField() {
    const vals = this.valuesForParams();
    let component = null;
    switch (this.state.searchOn) {
      case "customer":
        component = <CustomerSearch store={vals.store} values={vals.values} />;
        break;
      case "bike":
        component = <BikeSearch values={vals.values} />;
        break;
      case "service_request":
        component = <ServiceRequestSearch values={vals.values} />;
        break;
      default:
        return <div>Unsupported component</div>;
    }

    return (
      <Grid container={true} style={{ marginTop: 10 }} spacing={2}>
        {component}
        <Grid item={true} xs={12} style={{ justifyContent: "flex-end", display: "flex" }}>
          <Button variant="contained" color="primary" type="submit" aria-label="search">
            <SearchIcon /> Find It!
          </Button>
        </Grid>
      </Grid>
    );
  }

  searchResults() {
    return (
      <Grid item={true} xs={12}>
        {this.props.searchResults && (
          <>
            {this.props.searchResults.service_requests.length > 0 && <ServiceRequestList title="" serviceRequests={this.props.searchResults.service_requests} />}

            {this.props.searchResults.bikes.length > 0 && <BikesList bikes={this.props.searchResults.bikes} />}

            {this.props.searchResults.customers.length > 0 && <CustomersList customers={this.props.searchResults.customers} />}

            {this.props.searchResults.service_requests.length === 0 && this.props.searchResults.bikes.length === 0 && this.props.searchResults.customers.length === 0 && this.noResults()}
          </>
        )}
      </Grid>
    );
  }

  render() {
    if (this.props.searchResults) {
      console.log(this.props.searchResults.bikes);
    }

    let label = "Please select a search criteria";
    const filtr = options.filter(opt => opt.value === this.state.searchOn);

    if (filtr.length > 0) {
      label = filtr[0].label;
    }

    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Typography variant={"h6"}>Search</Typography>
          <Select
            value={{ label: label, value: this.state.searchOn }}
            isSearchable={false}
            placeholder={"Select a search option."}
            options={options}
            onChange={(val: any) => {
              console.log(val, "select");
              this.setState({ searchOn: val.value });
            }}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Form
            onSubmit={this.onSubmitSearch}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                {this.searchField()}
              </form>
            )}
          />
        </Grid>
        {this.props.searchLoading && <Loading isLoading={true} />}
        {this.props.searchResults && this.searchResults()}
      </Grid>
    );
  }

  private _onSubmitSearch(values: any) {

    let { store } = values;
    if (!store) {
      store = "US"; //default
    }

    if (!values) {
      return alert("Invalid Input!");
    }
    let searchQuery = null;
    switch (this.state.searchOn) {
      case "bike":
        const bike = values.bike && values.bike.serial_number ? values.bike.serial_number : null;
        searchQuery = values.bike;
        values = { "serial_number:likeLower": `%${bike}%`, orderBy: "serial_number" }; //rangeStart: 0, rangeEnd: 100
        break;
      case "customer":
        // Strip special characters out of the phone number to pass to Shopify through the Hub...
        if(values.customer.phone){
          values.customer.phone = values.customer.phone.replace(/\D/g,'');
        }
        values = Object.entries(values.customer).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {});
        searchQuery = values;
        break;
      case "service_request":
        const serviceRequest = values.service_request && values.service_request.name ? values.service_request.name : null;
        searchQuery = values.service_request;
        values = { "contact.firstname|contact.lastname|contact.email:likeLower": `${serviceRequest}%`, eager: "[bikes,contact]" };
        break;
      default:
        break;
    }
    // update address bar
    const { searchOn } = this.state;

    const params = JSON.stringify(searchQuery);


    this.props.history.push({
      search: "?" + new URLSearchParams({ searchOn, values: params,store }).toString()
    });

    if (values) {
      const encoded = encodeURIComponent(JSON.stringify(values));
      let str = `${this.state.searchOn}=${encoded}`;
      if (store) {
        str = str + `&store=${store}`;
      }

      this.props.dispatch(SearchAction.search(str));
    }
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    searchResults: state.search.results,
    searchLoading: state.search.searching
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAction<any>>): IDispatchToProps => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
