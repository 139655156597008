import * as React from "react";

import { Dispatch, compose } from "redux";

import IAction from "../../stores/IAction";
import { IDispatchToProps } from "interfaces";
import IStore from "../../stores/IStore";
import {
  RouteComponentProps
} from "react-router-dom";
import { connect } from "react-redux";

export interface IStoreProps {}
export interface IMatchParams {  
  
  params: {
    id: string;
  };
}

export interface IStateToProps {
  match: IMatchParams;
}


export interface IConnectedRoutedComponentProps {
  routerProps:RouteComponentProps  
  dispatch: Dispatch;
  data:any;
}
const defaultMapStateToProps = (state: IStore): IStoreProps => ({});

const mapDispatchToProps = (
  dispatch: Dispatch<IAction<any>>
): IDispatchToProps => ({
  dispatch
});

const withConnection = (
  // tslint:disable-next-line
  WrappedComponent: any,
  stateToProps?: (state: IStore) => IStoreProps
) => {
  class ParentComponent extends React.Component<
    IStateToProps & IDispatchToProps & RouteComponentProps
  > {
    public render() {     
      const {location,history,match} = this.props   
      return (
        <div>
          <WrappedComponent
            routerProps={{location,history,match}}
            dispatch={this.props.dispatch}
            data={this.props}
          />
        </div>
      );
    }
  }

  return compose(
    connect(
      stateToProps ? stateToProps : defaultMapStateToProps,
      mapDispatchToProps
    )
  )(ParentComponent);
};

export default withConnection;
