import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Theme, makeStyles } from "@material-ui/core/styles";
import ContactsIcon from "@material-ui/icons/Contacts";
import EditIcon from "@material-ui/icons/Edit";

import AppModal from "components/common/Modal/AppModal";
import CustomerForm from "components/Customer/CustomerForm";
import IconFlag from "components/Customer/IconFlag";
import MapContainer from "components/Map";
import SRContactInfoForm from "components/ServiceRequest/ServiceRequestContactInfoForm";
import SelectInput from "components/common/FormInputs/SelectInput";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import RMSServiceRequestAction from "stores/rms-service-requests/RMSServiceRequestAction";
import ApiService, { ENDPOINTS } from "utilities/api-service";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: "20px"
  },
  divider: {
    marginBottom: "20px"
  },
  link: {
    margin: theme.spacing(1)
  }
}));

interface ISRContactInfoProps {
  serviceRequest: IRMSServiceRequest;
  onUpdateContact?: () => void;
}

const contactAddress = (serviceRequest: IRMSServiceRequest) => {
  return (
    <>
      {serviceRequest.contact && (
        <>
          {serviceRequest.contact.address1 &&<>{ serviceRequest.contact.address1 }<br /></>}
          {serviceRequest.contact.address2 && <>{ serviceRequest.contact.address2 }<br /></>}
          {serviceRequest.contact.locality && serviceRequest.contact.locality}
          {(serviceRequest.contact.locality && (serviceRequest.contact.region || serviceRequest.contact.postcode)) && ", "}
          {serviceRequest.contact.region && serviceRequest.contact.region}
          {(serviceRequest.contact.region && (serviceRequest.contact.postcode)) && ", "}
          {serviceRequest.contact.postcode && serviceRequest.contact.postcode}
          {(serviceRequest.contact.locality || serviceRequest.contact.region || serviceRequest.contact.postcode) && <br />}
          {serviceRequest.contact.country && serviceRequest.contact.country}
        </>
      )}
    </>
  )
}

const ServiceRequestContactInfo = (props: ISRContactInfoProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const { serviceRequest } = props;
  const [assignCustomerModalOpen, setAssignCustomerModalOpen] = useState(false);
  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false);
  const [updateContactInfoModalOpen, setUpdateContactInfoModalOpen] = useState(false);

  const _onUpdateSR = () => {
    dispatch(RMSServiceRequestAction.getServiceRequest(serviceRequest.id.toString()));
  };

  const _updateSR = (value: any, column: string) => {
    const data = {};
    data[column] = value;
    data["id"] = serviceRequest.id;
    ApiService.patch(ENDPOINTS.serviceRequestsId(serviceRequest.id.toString()), data)
      .then(() => {
        _onUpdateSR();
      })
      .catch(error => {
        console.warn(error);
      });
  };

  return (
    <>
      <Box className={classes.box}>
        {serviceRequest.customer && serviceRequest.customer.email && (
          <Box>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h4">{serviceRequest.customer.fullName}</Typography>

              <Button variant="text" component={RouterLink} to={`/customers/${serviceRequest.customer.id}`}>
                <ContactsIcon color="secondary" />
              </Button>
            </div>
            <Typography variant="body1">
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: 10 }}>Shopify Store:</span>
                <span>{<IconFlag store={serviceRequest.customer.shopify_store} />}</span>
              </div>
            </Typography>
            <Typography variant="h6">Email</Typography>

            <Typography variant="body1">
              <Link href={`mailto:${serviceRequest.customer.email}?subject=Rad%20Power%20Bikes%20Service%20Request%20${serviceRequest.id}&amp;body=Hello%20${serviceRequest.customer.firstname}%2C`}>
                {serviceRequest.customer.email}
              </Link>
            </Typography>
            <Typography variant="h6">Phone</Typography>
            <Typography variant="body1">{serviceRequest.customer.phone}</Typography>
          </Box>
        )}
      </Box>

      <Grid container={true} alignItems="flex-start" spacing={2}>
        <Grid item={true} xs={12} sm={6}>
          <Box className={classes.box}>
            <Typography variant="h4">
              Contact Info
              <IconButton aria-label="edit-contact-info" onClick={() => setUpdateContactInfoModalOpen(true)}>
                <EditIcon color="secondary" fontSize="small" />
              </IconButton>
            </Typography>

            <Typography variant="body1">Contact information for this service request.</Typography>

            {(serviceRequest.contact && serviceRequest.contact.alias) &&
              <>
                <Typography variant="h6">Location Name</Typography>
                <Typography variant="body1">
                  {serviceRequest.contact.alias}                  
                </Typography>
              </>
            }

            {(serviceRequest.contact && serviceRequest.contact.firstname && serviceRequest.contact.lastname) &&
              <>
                <Typography variant="h6">Name</Typography>
                <Typography variant="body1">
                  {serviceRequest.contact.firstname ? serviceRequest.contact.firstname : ""}
                  {serviceRequest.contact.firstname && serviceRequest.contact.lastname ? " " : ""}
                  {serviceRequest.contact.lastname ? serviceRequest.contact.lastname : ""}
                </Typography>
              </>
            }

            {(serviceRequest.contact && serviceRequest.contact.email) &&
              <>
                <Typography variant="h6">Email</Typography>
                <Typography variant="body1">
                  <Link href={`mailto:${serviceRequest.contact.email}?subject=Rad%20Power%20Bikes%20Service%20Request%20${serviceRequest.id}`}>{serviceRequest.contact.email}</Link>
                </Typography>
              </>
            }

            {(serviceRequest.contact && serviceRequest.contact.phone) &&
              <>
                <Typography variant="h6">Phone</Typography>
                <Typography variant="body1">{serviceRequest.contact.phone}</Typography>
              </>
            }

            {serviceRequest.contact && (serviceRequest.contact.address1 || serviceRequest.contact.address2 || serviceRequest.contact.locality || serviceRequest.contact.region || serviceRequest.contact.postcode) &&
              <>
                <Typography variant="h6">Address</Typography>
                <Typography variant="body1">
                  {(serviceRequest.contact.latitude && serviceRequest.contact.longitude) ? (
                    <Link target="_new" href={`http://www.google.com/maps/place/${serviceRequest.contact.latitude},${serviceRequest.contact.longitude}/@${serviceRequest.contact.latitude},${serviceRequest.contact.longitude},13z`}>
                      { contactAddress(serviceRequest) }
                    </Link>)
                  : (
                    contactAddress(serviceRequest)
                  )}
                </Typography>
              </>
            }
          </Box>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          {(serviceRequest.contact && serviceRequest.contact.latitude && serviceRequest.contact.longitude) &&
          <Box className={classes.box}>
            <MapContainer coordinates={[{ lat: serviceRequest.contact.latitude, lng: serviceRequest.contact.longitude }]} />
          </Box>}
        </Grid>
      </Grid>

      <Divider className={classes.divider} variant="middle" />

      <AppModal
        open={createCustomerModalOpen || editCustomerModalOpen}
        cancel={() => {
          setCreateCustomerModalOpen(false);
          setEditCustomerModalOpen(false);
        }}>
        {createCustomerModalOpen ? (
          <CustomerForm
            type="CREATE"
            closeModal={response => {
              console.log(response, "create!");
              _updateSR(response.data.id, "customer_id");
              setCreateCustomerModalOpen(false);
              setEditCustomerModalOpen(false);
            }}
          />
        ) : (
          <CustomerForm
            customer={serviceRequest.customer}
            type="UPDATE"
            closeModal={response => {
              console.log(response, "create!");
              _updateSR(response.data.id, "customer_id");
              setCreateCustomerModalOpen(false);
              setEditCustomerModalOpen(false);
            }}
          />
        )}
      </AppModal>

      <AppModal dialogTitle={"Assign a Customer"} open={assignCustomerModalOpen} cancel={() => setAssignCustomerModalOpen(false)}>
        <SelectInput
          url={ENDPOINTS.customers + `?limit=25&query=`}
          inputLabel="Assign a Customer"
          optionLabel={["firstname", "lastname"]}
          isMulti={false}
          onBlur={() => setAssignCustomerModalOpen(false)}
          onChange={value => {
            _updateSR(value, "customer_id");
            setAssignCustomerModalOpen(false);
          }}
          isAsync={true}
        />
      </AppModal>

      <AppModal open={updateContactInfoModalOpen} cancel={() => setUpdateContactInfoModalOpen(false)}>
        <SRContactInfoForm
          serviceRequest={serviceRequest}
          closeModal={(response: any) => {
            setUpdateContactInfoModalOpen(false);
            console.log(response, "update contact");
            _onUpdateSR();
          }}
        />
      </AppModal>
    </>
  );
};

export default ServiceRequestContactInfo;
