import { Calendar, CalendarProps, Event, NavigateAction } from "react-big-calendar";
import IRMSServiceRequest,{RPBServiceRequestStatus} from "stores/rms-service-requests/models/IRMSServiceRequest";
import styles from './styles.css';

import React from "react";
import moment from "moment";
import { styleForStatus } from '../../stores/rms-service-requests/models/IRMSServiceRequest';
import Toolbar from './Toolbar'

export interface IAppCalendarProps {
  calendarProps: CalendarProps;
  customEventView?:any;
  onNavigate?:(newDate: Date, action: NavigateAction)=>void;
  serviceRequests: IRMSServiceRequest[];
}

// format srs into calendar items
const eventsForServiceRequests = (srs: IRMSServiceRequest[]) => {
  const events: Event[] = srs.map((sr: IRMSServiceRequest) => {
    // date

    // set time
    const startTime = moment(sr.scheduled_time, "hh:mm:ss");
    const start = moment(sr.expected_start_time).set({
      hour: startTime.get("hour"),
      minute: startTime.get("minute"),
      second: 0
    });
    const event = {
      resource: sr,
      title: sr.contact ? `${sr.contact.firstname} ${sr.contact.lastname} (${sr.status})` : "XXX",
      start: start.toDate(),
      end: start.add(1, "hour").toDate()
    };
    return event;
  });

  return events;
};

// decorate the cell items in the calendar according to their status
const CalCellItem = (event: Event) => {  
  const sr:IRMSServiceRequest = event.resource
  const style = styleForStatus(sr)
  let newStyle = {
    backgroundColor: style.backgroundColor,
    color: sr.status ===  RPBServiceRequestStatus.CANCELED ? 'white' : "black",
    borderRadius: "0px",
    border: "none",
    fontSize: "14px"
  };
  return {
    className: "",
    style: newStyle
  };
}

let Resource = (props: IAppCalendarProps) => {
  return (
    <Calendar
      className={styles}
      onNavigate={(focusDate, flipUnit, prevOrNext) => props.onNavigate ? props.onNavigate(focusDate, prevOrNext):null}
      events={eventsForServiceRequests(props.serviceRequests || [])}
      localizer={props.calendarProps.localizer}
      defaultView={"week"}
      views={["day", "week"]}
      step={60}
      min={new Date(0, 0, 0, 8, 0, 0)}
      max={new Date(0, 0, 0, 19, 0, 0)}
      defaultDate={new Date()}
      eventPropGetter={CalCellItem}
      components={{
        event: props.customEventView ? props.customEventView : null,
        toolbar: Toolbar
      }}
    />
  );
};

export default Resource;
