import ApiService, { ENDPOINTS } from "utilities/api-service";

import IAction from "stores/IAction";
import SearchAction from "./SearchActions";
import { call } from "redux-saga/effects";
import { put } from "redux-saga/effects";

export default class SearchSaga {
  public static *onSearch(action: IAction<void>) {
    try {
      yield put(SearchAction.searchResults(null));
      yield put(SearchAction.setIsSearching(true));
      const query = action.payload
      const response = yield call(ApiService.get, `${ENDPOINTS.search}?${query}`);
      yield put(SearchAction.searchResults(response.data.results));
      yield put(SearchAction.setIsSearching(false));
    } catch (error) {
      yield put(SearchAction.setIsSearching(false));
      yield put(SearchAction.error(error));
    }
  }
}
