import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import React from "react";
import { TextField } from "final-form-material-ui";

export interface ICancelServiceRequestReasonProps{
    onSubmit:(reason:string)=>void;
}
interface ICancelServiceRequestReasonFormProps {
  reason: string;
  
}
const CancelServiceRequestForm = (props:ICancelServiceRequestReasonProps) => {
  const onSubmit = (values: ICancelServiceRequestReasonFormProps) => {
    if(values.reason){
        return props.onSubmit(values.reason);
    }
    alert("Please provide a valid reason to cancel this service request.")
  };
  const initialValues: ICancelServiceRequestReasonFormProps = {
    reason: ""
  };
  return (
    <div>
      <Typography variant="h4">Cancel Service Request Reason</Typography>
      <Typography variant="caption">Please provide a reason for canceling this request.</Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate={true}>
            <Paper style={{ padding: 16 }}>
              <Grid container={true} alignItems="flex-start" spacing={2}>
                <Grid item={true} xs={12}>
                  <Field fullWidth={true} required={true} name="reason" component={TextField} />
                </Grid>
                <Grid item={true} style={{ marginTop: 16 }}>
                  <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
    </div>
  );
};
export default CancelServiceRequestForm;
