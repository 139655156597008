import ApiService, { ENDPOINTS } from "utilities/api-service";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import React, { useState } from "react";
import Snackbar, { SnackbarVariant } from "components/Snackbar";

import Loading from "../Loading/Loading";
import { TextField } from "final-form-material-ui";

interface INoteFormProps {
  serviceRequestId: number;
  onUpdate?: () => void;
}

interface INoteFormValues {
  note: string;
  file: any;
}


const NoteForm: React.SFC<INoteFormProps> = props => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [processingNote, setProcessingNote] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadFileName, setUploadFileName] = useState(null);

  const FileUpload = ({ input, ...rest }: any) => (
    <Button variant="outlined" component="label">
      Upload File
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e:any)=>{
          setUploadFile(e.target.files[0])
          setUploadFileName(e.target.files[0].name)
        }}
        accept="image/*"
      />
    </Button>
  );
  const showError = (msg:string)=>{
      setProcessingNote(false)
      setSnackbarMessage(msg);
      setSnackbarOpen(true);
      setSnackbarVariant("error");
  }
  const onSubmit = async (values: INoteFormValues) => {
    setProcessingNote(true)
    if(!values.note || values.note.length <= 0){
      showError(`Note field is not valid`)
      return
    }
    try {
      const data = new FormData();

      data.append("note", values.note);
      if (uploadFile) {
        data.append("file", uploadFile);
      }
      console.warn("NNNNOOOOTEEEEE: ", data)
      // data.append('file', new Blob(['test payload'], { type: 'text/csv' }))
      await ApiService.post(
        ENDPOINTS.serviceRequestsIdNotes(props.serviceRequestId.toString()),
        data,
        true
      );
      if (props.onUpdate) {
        props.onUpdate();
        setUploadFile(null);
      }
      setSnackbarMessage("Note successfully created.");
      setSnackbarOpen(true);
      setSnackbarVariant("success");
    } catch (error) {
      showError(`Note creation failed: ${error.message}`)
    }
    setProcessingNote(false)
  };
  if(processingNote){
    return <Loading isLoading={true} />
  }
  return (
    <>
      <div>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Paper style={{ padding: 16 }}>
                <Grid container={true} alignItems="flex-start" spacing={2}>
                  <Grid item={true} xs={12}>
                    <Field
                      name="note"
                      fullWidth={true}
                      multiline={true}
                      required={true}
                      component={TextField}
                      label="Note"
                    />
                  </Grid>
                  <Grid>
                    <Field
                      name="file"
                      fullWidth={true}
                      required={false}
                      component={FileUpload }
                      type="file"
                      label="Attachment"
                    />
                    <Typography variant="body1">{uploadFileName}</Typography>
                  </Grid>


                  <Grid item={true} xs={12} style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
      <Snackbar
        isOpen={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        variant={snackbarVariant as SnackbarVariant}
      />
    </>
  );
};

export default NoteForm;
