import { createMuiTheme } from "@material-ui/core";

// https://material-ui.com/customization/color/
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';


export const COLORS = {
  PRIMARY: {
    BRAND: "#F26326",
    ALERT:"#C60063",
    WARNING:"#FFFF00",
    COLOR1:"#65CBF6",
    COLOR2: "#DDF5FF",
    COLOR3: "#9EE1FF",
    COLOR4: "#448CAA",
    COLOR5: "#214554",
  },
  SECONDARY: {
    BRAND: "#FBD4C3",
    ALERT:"#EFB9D4",
    WARNING:"#FFFFB9",
    COLOR1:"#ABE2FA",
    COLOR2: "#ECF9FF",
    COLOR3: "#CAEEFF",
    COLOR4: "#99C0D0",
    COLOR5: "#8599A1",
  },
  CALENDAR: {
    CANCELED: "#214554",
    COMPLETE: "#99C0D0",
    CREATED: "#9EE1FF",
    DEFERRED: "#FFFFB9",
    PAST_DUE: "#CC0000",
    READY: "#66CC33",
  },
  ERROR: red[500],
  INFO: blue[500],
  SUCCESS: green[500],
  WARNING: orange[500],
  GRAY: "#403F4C",
  SLATE: "#CFD2D3",
  BLUE_DARK:"#192C3D"
};

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY.BRAND
    },
    secondary: {
      main: COLORS.SLATE
    }
  },
  typography: {
    h1: {
      color: COLORS.PRIMARY.BRAND
    },
    h2: {
      color: COLORS.PRIMARY.BRAND
    },
    h3: {
      color: COLORS.PRIMARY.BRAND
    },
    h4: {
      color: COLORS.PRIMARY.BRAND
    },
    h5: {
      color: COLORS.PRIMARY.BRAND
    },
    h6: {
      color: COLORS.PRIMARY.BRAND
    },
    subtitle1: {
      color: COLORS.PRIMARY.BRAND
    }
  },
  overrides: {    
    // Style sheet name ⚛️
    MuiContainer: {
      // Name of the rule
      root: {
        // Some CSS
        padding:0,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 15,
          paddingRight: 15,
        },
        [defaultTheme.breakpoints.down('sm')]: {
          paddingLeft: 5,
          paddingRight: 5,
        }
      },
    }    
  },
});
