import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const maxWidth = 355;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      
      [theme.breakpoints.down("xs")]: {
        width: maxWidth,
        overflowX: "auto"
      }
    },
    table: {
      
    }
  })
);
