import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { COLORS } from '../../theme/theme';

const drawerWidth = 240;


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      
    },
    content: {
      flexGrow: 1,
      
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth
    },
    feedbackLink: {
      textTransform: "uppercase"
    },
    footer: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%"
    },
    appBar: {
      backgroundColor: COLORS.BLUE_DARK,
      color: "white"
    },
    title: {
      color: "white"
    },
    mainToolBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    menuButtonIcon: {
      //   [theme.breakpoints.up("sm")]: {
      //     display: "none"
      //   }
    },
    nested: {
      paddingLeft: theme.spacing(4),
      flexDirection: "column",
      display: "flex"
    },
    toolbar: { ...theme.mixins.toolbar, display: "flex", justifyContent: "center", alignItems: "center" },
    drawerPaper: {
      width: drawerWidth
    }
  })
);
