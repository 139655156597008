import { DatePicker, TimePicker } from "@material-ui/pickers";
import React, { useEffect, useRef, useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import AddServiceRequestTaskForm from "components/ServiceRequest/AddServiceRequestTaskForm";
import AppModal from "components/common/Modal/AppModal";
import BikeDetail from "components/Bike/BikeDetail";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CancelServiceRequestForm from "./CancelServiceRequestForm";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import Divider from "@material-ui/core/Divider";
import { ENDPOINTS } from "utilities/api-service";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IServiceRequestTask from "stores/tasks/models/ITask";
import InputIcon from "@material-ui/icons/Input";
import { Note } from "../Note/Note";
import NoteForm from "components/Note/NoteForm";
import PrintIcon from "@material-ui/icons/Print";
import RMSLocationAction from "stores/rms-locations/RMSLocationAction";
import RMSServiceRequestAction from "stores/rms-service-requests/RMSServiceRequestAction";
import RMSTable from "components/Table/RMSTable";
import ReactToPrint from 'react-to-print';
import SelectInput from "components/common/FormInputs/SelectInput";
import ServiceRequestDetailsPrint from "components/ServiceRequest/ServiceRequestDetailsPrint";
import ServiceRequestHelper from "utilities/service-request-helper";
import ServiceTaskItem from "../Task/ServiceTaskItem";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useStyles } from "./ServiceRequestDetailsComponentStyles";

interface IServiceRequestDetailsComponentProps {
  bikeTasks: any;
  serviceRequest: IRMSServiceRequest;
  serviceRequestError?: Error;
}

const ServiceRequestDetailsComponent = (props: IServiceRequestDetailsComponentProps) => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const classes = useStyles(props);
  const { serviceRequest } = props;

  const _onUpdateTasks = (task: IServiceRequestTask, type: "delete" | "update" = "update") => {
    let tasks = serviceRequest.tasks.map(task => ({...task}));

    if (type === "delete") {
      tasks = tasks.filter(t => t.id !== task.id);
    }

    if (type === "update") {
      const taskIndex = tasks.findIndex((t => t.id === task.id));
      tasks[taskIndex] = task;
    }

    const updatedTasks = {
      id: serviceRequest.id,
      tasks: tasks
    };

    dispatch(RMSServiceRequestAction.updateServiceRequest(updatedTasks));
  };

  const [addSRTaskCancelModalOpen, setAddSRTaskCancelModalOpen] = useState(false);
  const [addSRTaskModalOpen, setAddSRTaskModalOpen] = useState(false);
  const [addSRNoteModalOpen, setAddSRNoteModalOpen] = useState(false);
  const [currentBike, setCurrentBike] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [serviceRequestTime, setServiceRequestTime] = useState(null);
  // const [filteredBikeTasks, setFilteredBikeTasks] = useState(null);
  const [filteredBikeTasks, setFilteredBikeTasks] = useState({ tasks: null, resolve: () => undefined });

  useEffect(() => {
    if (serviceRequest.scheduled_time) {
      let formattedTime = serviceRequest.expected_start_time ? new Date(serviceRequest.expected_start_time) : new Date();
      const hours = serviceRequest.scheduled_time.substring(0, 2);
      const minutes = serviceRequest.scheduled_time.substring(3, 5);
      formattedTime.setHours(parseInt(hours, 10));
      formattedTime.setMinutes(parseInt(minutes, 10));
      setServiceRequestTime(formattedTime);
    } else {
      setServiceRequestTime(null);
    }

    if (serviceRequest.service_location_id) {
      const currentLocation = dispatch(RMSLocationAction.getLocation(serviceRequest.service_location_id.toString()));
      dispatch(RMSLocationAction.selectedLocation(currentLocation as any));
    }

    filteredBikeTasks.resolve();

    return function cleanup() {
      dispatch(RMSLocationAction.selectedLocation(null));
    };
  }, [dispatch, filteredBikeTasks, serviceRequest.tasks, serviceRequest.service_location_id, serviceRequest.expected_start_time, serviceRequest.scheduled_time]);

  const _onUpdateSR = () => {
    setAddSRNoteModalOpen(false);
    dispatch(RMSServiceRequestAction.getServiceRequest(serviceRequest.id.toString()));
  };

  const _doUpdate = (data: any) => {
    data.id = props.serviceRequest.id;
    dispatch(RMSServiceRequestAction.updateServiceRequest(data));
  };

  const _updateSR = (value: any, column: string) => {
    const data = {};
    data[column] = value;
    _doUpdate(data);
  };

  const cancelSR = (cancelation_reason: string) => {
    _doUpdate({ status: "canceled", cancelation_reason });
  };

  const _closeAddSRTaskModal = () => {
    setAddSRTaskCancelModalOpen(false);
    setAddSRTaskModalOpen(false);
    setCurrentBike(null);
    _onUpdateSR();
  };

  const assigneeName =
    serviceRequest.assignee && serviceRequest.assignee.firstname && serviceRequest.assignee.lastname ? serviceRequest.assignee.firstname + " " + serviceRequest.assignee.lastname : "Unassigned";

  const formField = (title: string, formfield: any) => {
    return (
      <Grid container={true}  >
        <Grid xs={12} sm={2} item >
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid xs={12} sm={10}  item={true} >
        {formfield}
        </Grid>
      </Grid>
    );
  };

  const datePicker = () => (
    <DatePicker    
      fullWidth={true}
      inputVariant="outlined"
      emptyLabel="Please set a date."
      clearable
      value={serviceRequest.expected_start_time ? serviceRequest.expected_start_time : null}
      onChange={(value: any) => {
        _updateSR(value, "expected_start_time");
      }}
      animateYearScrolling
    />
  );

  const timePicker = () => (
    <TimePicker
      fullWidth={true}
      inputVariant="outlined"
      emptyLabel="Please schedule a time for this service."
      clearable
      ampm={true}
      value={serviceRequestTime}
      onChange={(value: any) => {
        const formattedValue = value ? moment(value).format("HH:mm") : null;
        _updateSR(formattedValue, "scheduled_time");
      }}
    />
  );

  const locationField = () => {
    if (editLocation) {
      return (
        <SelectInput        
          url={ENDPOINTS.locations}
          inputLabel="Select a Location"
          optionLabel="name"
          isMulti={false}
          onBlur={() => setEditLocation(false)}
          onChange={(value: any) => {
            _updateSR(value, "service_location_id");
            setEditLocation(false);
          }}
          autoFocus={true}
        />
      );
    }
    return (
      <Button fullWidth={true} className={classes.labelCapitalized} variant="outlined" size="small" onClick={() => setEditLocation(true)}>
        <Typography variant="body1">{serviceRequest.location ? serviceRequest.location.name : "Needs Location Assignment!"}</Typography>
      </Button>
    );
  };

  // FIXME - do not pull 500 records at one time! see `url` prop
  const assigneeField = () => {
    if (editUser) {
      return (
        <SelectInput
          url={ENDPOINTS.users+ "?per_page=500"}
          inputLabel="Select a User"
          optionLabel={["firstname", "lastname"]}
          isMulti={false}
          onBlur={() => setEditUser(false)}
          onChange={value => {
            _updateSR(value, "assigned_user_id");
            setEditUser(false);
          }}
          autoFocus={true}
        />
      );
    }
    return (
      <Button fullWidth={true} className={classes.labelCapitalized} variant="outlined" size="small" onClick={() => setEditUser(true)}>
        <Typography variant="body1">{assigneeName}</Typography>
      </Button>
    );
  };

  const statusField = () => {
    if (editStatus) {
      return (
        <SelectInput        
          url={ENDPOINTS.dataTypes}
          inputLabel="Select a Status"
          optionLabel="status"
          isMulti={false}
          onBlur={() => setEditStatus(false)}
          onChange={(value: any) => {
            if (value === "canceled") {
              setAddSRTaskCancelModalOpen(true);
            } else {
              _updateSR(value, "status");
            }
            setEditStatus(false);
          }}
          autoFocus={true}
        />
      );
    }
    return (
      <Button fullWidth={true} className={classes.labelUppercase} variant="outlined" size="small" onClick={() => setEditStatus(true)}>
        <Typography variant="body1">{serviceRequest.status}</Typography>
      </Button>
    );
  };

  const notesField = () => (
    <TextField
    fullWidth={true}
      className={classes.notes}
      placeholder="Notes"
      multiline={true}
      margin="normal"
      variant="outlined"
      defaultValue={serviceRequest.notes}
      onBlur={e => {
        _updateSR(e.currentTarget.value, "notes");
      }}
    />
  );

  return (
    <>
      <Box className={classes.box}>
        <Box className={classes.box}>{formField("Date: ", datePicker())}</Box>
        <Box className={classes.box}>{formField("Time: ", timePicker())}</Box>
        <Box className={classes.box}>{formField("Location:", locationField())}</Box>
        <Box className={classes.box}>{formField("Assignee:", assigneeField())}</Box>
        <Box className={classes.box}>{formField("Status:", statusField())}</Box>
        <Box className={classes.box}>{formField("Description:", notesField())}</Box>
      </Box>

      <Divider className={classes.divider} variant="middle" />

      <Box className={classes.tableTools}>
        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography variant="h4">Notes</Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Grid container={true} alignItems="flex-start" justify="flex-end" direction="row">
              <Fab size="small" color="primary" aria-label="add" onClick={() => setAddSRNoteModalOpen(true)}>
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Box className={classes.root}>
          <Note onUpdate={_onUpdateSR} serviceRequest={props.serviceRequest} />
        </Box>
      </Box>

      <Divider className={classes.divider} variant="middle" />

      <Box className={classes.tableTools}>
        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography variant="h4">Tasks</Typography>
          </Grid>
        </Grid>
      </Box>

      {props.bikeTasks && Object.entries(props.bikeTasks).map((bike, idx) => {
        const _bike = ServiceRequestHelper.bikeForTask(serviceRequest, bike[1][0])
        const bikeSerialNumber = _bike ? _bike.serial_number : "";
        return (
          <div key={`bsn_${idx}`}>
            <Box className={classes.tableTools}>
              <Grid container={true} direction="row" spacing={1}>
                {bikeSerialNumber && bikeSerialNumber !== "UNKNOWN" ? (
                  <Grid item={true} sm={6}>
                    <BikeDetail bike={_bike}/>
                  </Grid>
                ) : (
                  <Grid item={true} sm={6}>
                    <Typography variant="h6"><DirectionsBikeIcon /> {bikeSerialNumber}</Typography>
                  </Grid>
                )}
                <Grid item={true} sm={6}>
                  <Grid container={true} alignItems="flex-start" justify="flex-end" direction="row">
                    <ReactToPrint
                      trigger={() => <Fab size="small" color="primary" aria-label="print-service-request"><PrintIcon /></Fab>}
                      onBeforeGetContent={() => {
                        return new Promise((resolve) => {
                          const filtered = Object.fromEntries(
                            Object.entries(props.bikeTasks).filter(
                              ([key, val])=> key === bike[0]
                            )
                          );

                          setFilteredBikeTasks(prevTasks => ({ tasks: filtered, resolve }));
                        });
                      }}
                      onAfterPrint={() => {
                        return new Promise((resolve) => {
                          setFilteredBikeTasks(prevTasks => ({ tasks: null, resolve }));
                        });
                      }}
                      content={() => printRef.current}
                    />
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        setAddSRTaskModalOpen(true);
                        setCurrentBike(parseInt(bike[0], 10));
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.box}>
              <RMSTable>
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Task</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Gravity</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bike[1] instanceof Array && (bike[1].map((task: IServiceRequestTask) => (
                      <TableRow key={task.id}>
                        <ServiceTaskItem
                          bike={ServiceRequestHelper.bikeForTask(serviceRequest, task)}
                          serviceRequest={serviceRequest}
                          edit={true}
                          key={task.id}
                          onUpdate={(task, type = "update") => _onUpdateTasks(task, type)}
                          srRefresh={() => dispatch(RMSServiceRequestAction.getServiceRequest(serviceRequest.id.toString()))}
                          task={task}
                        />
                      </TableRow>
                    )))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        {serviceRequest.tasks.length} Tasks
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </>
              </RMSTable>
            </Box>
            <Divider className={classes.divider} variant="middle" />
          </div>
        );
      })}

      <Box style={{ marginBottom: "20px" }}>
        <ButtonGroup color="primary" variant="contained">
          <Button
            onClick={() => {
              setAddSRTaskModalOpen(true);
              setCurrentBike(null);
            }}>
            Assign Bike
            <InputIcon style={{ paddingLeft: 10 }} />
          </Button>
        </ButtonGroup>
      </Box>

      <AppModal open={addSRTaskCancelModalOpen} cancel={() => setAddSRTaskCancelModalOpen(false)}>
        <CancelServiceRequestForm
          onSubmit={(reason: string) => {
            cancelSR(reason);
            setAddSRTaskCancelModalOpen(false);
          }}
        />
      </AppModal>

      <AppModal
        open={addSRTaskModalOpen}
        cancel={() => setAddSRTaskModalOpen(false)}>
        <AddServiceRequestTaskForm isVisible={addSRTaskModalOpen} bikeId={currentBike} serviceRequest={serviceRequest} closeModal={() => _closeAddSRTaskModal()} />
      </AppModal>

      <AppModal open={addSRNoteModalOpen} cancel={() => setAddSRNoteModalOpen(false)}>
        <NoteForm onUpdate={_onUpdateSR} serviceRequestId={props.serviceRequest.id} />
      </AppModal>

      <div style={{ display: "none" }}><ServiceRequestDetailsPrint ref={printRef} serviceRequest={serviceRequest} bikeTasks={filteredBikeTasks.tasks} /></div>
    </>
  );
};

export default ServiceRequestDetailsComponent;
