import ApiService, { ENDPOINTS } from "utilities/api-service";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { Field, Form } from "react-final-form";
import React, { useEffect, useState } from "react";
import { SectionEventName, SectionEventTypeSelect } from "./SectionEventComponents";
import Snackbar, { SnackbarVariant } from "components/Snackbar";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { USAddressBuilder, AddressDirector } from "./AddressBuilder"

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import IBike from "stores/bikes/models/IBike";
import IContact from "stores/contact/models/IContact";
import ICustomer from "stores/customers/models/ICustomer";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import SectionContactInfo from "./SectionContactInfo";
import SectionCustomer from "./SectionCustomer";
import SectionTasks from "./SectionTasks";
import SelectInput from "components/common/FormInputs/SelectInput";
import TabPanel from "containers/RMSLocation/TabPanel";
import { TextField } from "final-form-material-ui";
import Typography from "@material-ui/core/Typography";
import arrayMutators from "final-form-arrays";
import { cloneDeep } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

export interface IServiceRequestFormProps {
  serviceLocationId: number;
}

interface IServiceRequestFormValues {
  customer: ICustomer;
  bikes?: IBike[];
  expected_start_time?: any;
  scheduled_time?: any;
  service_location_id?: number;
  status: string;
  tasks?: any[];
  events?: any[];
  contact?: IContact | any;
}

const useStyles = makeStyles(() => ({
  descriptionBox: {
    marginBottom: "16px"
  },
  paper: {
    marginBottom: "40px",
    padding: "16px"
  },
  subHead: {
    marginTop: "0px"
  },
  whenBox: {
    marginTop: "16px"
  },
  addressesExpansionRoot: {
    width: "100%"
  },
  addressesExpansionSummary: {
    display: "block"
  }
}));

const defaultDateTime = new Date();
defaultDateTime.setHours(12, 0, 0, 0);

const initialValues: IServiceRequestFormValues = {
  customer: { firstname: "", lastname: "", email: "", phone: "" } as ICustomer,
  expected_start_time: defaultDateTime,
  scheduled_time: defaultDateTime,
  status: "created"
};

const ServiceRequestForm = (props: IServiceRequestFormProps) => {
  const classes = useStyles(props);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [bikes, setBikes] = useState([]);
  const [customer, setCustomer] = useState({ id: null, firstName: "", lastName: "", email: "", phone: "" });
  const [selectedAddress, setSelectedAddress] = useState({ id: null, address1: "", address2: "", locality: "", region: "", postcode: "", country: "" });
  const [addressId, setAddressId] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [sameAddress, setSameAddress] = useState(false);
  const [sameAsCustomer, setSameAsCustomer] = useState(false);
  const [availableCustomers, setAvailableCustomers] = useState({});
  const [addressesExpanded, setAddressesExpanded] = useState(true);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (selectedAddress.id !== addressId) {
      setAddressId(selectedAddress.id);
      console.log(selectedAddress);
    }
  }, [addressId, selectedAddress]);

  const validationError = (text: any) => {
    setSnackbarMessage(text);
    setSnackbarOpen(true);
    setSnackbarVariant("error");
  };

  const ReactSelectAdapterDate = ({ input, ...rest }: any) => <DatePicker {...input} {...rest} clearable label="Date" onChange={(value: any) => input.onChange(value)} animateYearScrolling />;
  const ReactSelectAdapterTime = ({ input, ...rest }: any) => <TimePicker {...input} {...rest} clearable ampm={true} label="Time" onChange={(value: any) => input.onChange(value)} />;

  const updateAddress = async (address: any) => {
    const geocodedResponse = await geocodeByAddress(address.label);
    if (geocodedResponse === undefined || geocodedResponse.length === 0) {
      throw new Error('No Geocode Response')
    }
    const builder = new USAddressBuilder(geocodedResponse[0])
    const director = new AddressDirector(builder)
    const formatted = director.buildUSAddress()
    setSelectedAddress(formatted)
  };

  const onSubmit = async (formValues: IServiceRequestFormValues) => {
    const values = cloneDeep(formValues);
    // FIXME: this is a bit of hack (i think) to get dates to play fair with the db. Let's vet the "right" way to do this
    if (values.expected_start_time) {
      values.expected_start_time = moment(values.expected_start_time).toISOString();
    }

    if (values.scheduled_time) {
      values.scheduled_time = moment(values.scheduled_time).format("HH:mm");
    }

    // BEGIN: Field validations
    const validationErrors: string[] = [];

    if (!values.service_location_id) {
      validationErrors.push("Location is required.");
    }

    if (!values.contact) {
      values.contact = {
        alias: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        locality: "",
        region: "",
        postcode: "",
        country: ""
      };
    }

    if (sameAddress) {
      values.contact.address1 = values.customer.address1;
      values.contact.address2 = values.customer.address2;
      values.contact.locality = values.customer.locality;
      values.contact.region = values.customer.region;
      values.contact.postcode = values.customer.postcode;
      values.contact.country = values.customer.country;
    }

    if (!values.contact.address1) {
      validationErrors.push("Address is required.");
    }

    if (!values.contact.locality) {
      validationErrors.push("City is required.");
    }

    if (!values.contact.region) {
      validationErrors.push("State/Province is required.");
    }

    if (!values.contact.postcode) {
      validationErrors.push("Postal code is required.");
    }

    if (!values.contact.country) {
      validationErrors.push("Country is required.");
    }

    if (!values.expected_start_time) {
      validationErrors.push("Date is required.");
    }

    if (!values.scheduled_time) {
      validationErrors.push("Time is required.");
    }

    if (sameAsCustomer) {
      values.contact.firstname = values.customer.firstname;
      values.contact.lastname = values.customer.lastname;
      values.contact.email = values.customer.email;
      values.contact.phone = values.customer.phone;
    }

    if (!values.contact.firstname || !values.contact.lastname) {
      validationErrors.push("First and last name are required.");
    }

    if (!values.contact.email) {
      validationErrors.push("Email is required.");
    }

    if (!values.contact.phone) {
      validationErrors.push("Phone number is required.");
    }

    if (validationErrors.length > 0) {
      const Errors = () => {
        return validationErrors.map((err, i) => <div key={i}>{err}</div>);
      };

      validationError(Errors);
      return;
    }
    // END: Field validations

    if (values.tasks) {
      // tasks for existing bikes found in the lookup
      let taskCandidates = values.tasks.filter((b: any) => b.serial_number.value && b.task);
      let tasks = taskCandidates.map((b: any) => {
        return {
          bike_id: b.serial_number.value,
          rpb_service_task_id: b.task.value,
          notes: b.task_notes
        };
      });

      // here we will format a body to create a bike and attach the task
      const newBikeCandidates = values.tasks.filter((b: any) => !b.serial_number.value && b.serial_number.label);
      if (newBikeCandidates.length > 0) {
        const newBikesToPersists = newBikeCandidates.map((b: any) => ({ "#id": b.serial_number.label, serial_number: b.serial_number.label }));
        values.bikes = newBikesToPersists;
        newBikeCandidates.forEach((b: any) => {
          tasks.push({
            bike_id: `#ref{${b.serial_number}.value}`,
            rpb_service_task_id: b.task.value,
            notes: b.task_notes
          });
        });
      }

      // handle tasks that should not have a bike (event, demo, etc)
      values.tasks.forEach(b => {
        if (!b.serial_number.value && !b.bike_id && b.task_id) {
          tasks.push({
            bike_id: null,
            rpb_service_task_id: b.task.value,
            notes: b.task_notes
          });
          console.warn(b.customer.shopifyData);
        }
      });
      // final task array for both existing, and soon to be bikes
      values.tasks = tasks;
    }

    if (values.events) {
      values.tasks = values.events.map(b => {
        return {
          bike_id: null,
          rpb_service_task_id: b.task.value,
          notes: b.task_notes
        };
      });
    }
    console.log(values, "values for form");
    delete values.events;
    // sanitize Customer - do not pass these to the backend
    delete values.customer;

    try {
      const sr = await ApiService.post(ENDPOINTS.serviceRequests, values);
      setSnackbarMessage("Service request successfully created.");
      setSnackbarOpen(true);
      setSnackbarVariant("success");

      window.location.href = `/service-requests/${sr.data.id}`;
    } catch (error) {
      setSnackbarMessage(`Service request creation failed: ${error.message}`);
      setSnackbarOpen(true);
      setSnackbarVariant("error");
    }
  };

  const ReactSelectAdapterLocation = ({ input, ...rest }: any) => {
    if (locationName) {
      return (
        <Button variant="text" color="default" onClick={() => setLocationName(null)}>
          {locationName}
        </Button>
      );
    }

    return (
      <SelectInput
        {...input}
        {...rest}
        url={ENDPOINTS.locations}
        inputLabel="Assign a Location *"
        optionLabel="name"
        isMulti={false}
        onChange={(value: any, option: any) => {
          input.onChange(value);
          setLocationName(option.label);
        }}
      />
    );
  };

  const showAvailableAddresses = (values: IServiceRequestFormValues) => {
    const toggleAddressExpand = () => {
      const status = addressesExpanded ? false : true;
      setAddressesExpanded(status);
    };

    return (
      <ExpansionPanel className={classes.addressesExpansionRoot} expanded={addressesExpanded} onChange={toggleAddressExpand}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Box className={classes.addressesExpansionSummary}>
            <Typography variant="subtitle1">Customers</Typography>
            <Typography variant="caption">Use one of the following customer addresses.</Typography>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container={true} alignItems="flex-start" spacing={2}>
            {Object.entries(availableCustomers).map(obj => {
              const id = obj[1]["id"];
              const firstName = obj[1]["first_name"] ? obj[1]["first_name"] : "";
              const nameDivider = obj[1]["first_name"] && obj[1]["last_name"] ? " " : "";
              const lastName = obj[1]["last_name"] ? obj[1]["last_name"] : "";
              const fullName = firstName + nameDivider + lastName;
              const address1 = obj[1]["default_address"] && obj[1]["default_address"]["address1"] ? obj[1]["default_address"]["address1"] : "";
              const address2 = obj[1]["default_address"] && obj[1]["default_address"]["address2"] ? obj[1]["default_address"]["address2"] : "";
              const locality = obj[1]["default_address"] && obj[1]["default_address"]["city"] ? obj[1]["default_address"]["city"] : "";
              const region = obj[1]["default_address"] && obj[1]["default_address"]["province"] ? obj[1]["default_address"]["province"] : "";
              const postcode = obj[1]["default_address"] && obj[1]["default_address"]["zip"] ? obj[1]["default_address"]["zip"] : "";
              const country = obj[1]["default_address"] && obj[1]["default_address"]["country"] ? obj[1]["default_address"]["country"] : "";

              return (
                <Grid key={obj[0]} item={true}>
                  <Grid container={true} alignItems="flex-start" spacing={1}>
                    <Grid item={true}>
                      <Radio
                        checked={selectedAddress && selectedAddress.id === id}
                        onClick={e => {
                          if (selectedAddress && selectedAddress.id === id) {
                            const c = { id: "", address1: "", address2: "", locality: "", region: "", postcode: "", country: "" };
                            setSelectedAddress(c);
                            setAddressId(null);
                            // props.handleChange(false, c);
                          } else {
                            const a = { id: id, address1: address1, address2: address2, locality: locality, region: region, postcode: postcode, country: country };
                            setSelectedAddress(a);
                            if (a.id) {
                              setSameAddress(true);
                              values.customer.address1 = a.address1;
                              values.customer.address2 = a.address2;
                              values.customer.locality = a.locality;
                              values.customer.region = a.region;
                              values.customer.postcode = a.postcode;
                              values.customer.country = a.country;
                            } else {
                              setSameAddress(false);
                              values.customer.address1 = "";
                              values.customer.address2 = "";
                              values.customer.locality = "";
                              values.customer.region = "";
                              values.customer.postcode = "";
                              values.customer.country = "";
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item={true}>
                      <>
                        {fullName && (
                          <>
                            <Typography variant="caption">{fullName}</Typography>
                            <br />
                          </>
                        )}
                        {address1 && (
                          <>
                            <Typography variant="caption">{address1}</Typography>
                            <br />
                          </>
                        )}
                        {address2 && (
                          <>
                            <Typography variant="caption">{address2}</Typography>
                            <br />
                          </>
                        )}
                        {locality && (
                          <>
                            <Typography variant="caption">{locality}</Typography>
                          </>
                        )}
                        {locality && (region || postcode) && (
                          <>
                            <Typography variant="caption">", "</Typography>
                          </>
                        )}
                        {region && (
                          <>
                            <Typography variant="caption">{region}</Typography>
                          </>
                        )}
                        {region && postcode && (
                          <>
                            <Typography variant="caption">", "</Typography>
                          </>
                        )}
                        {postcode && (
                          <>
                            <Typography variant="caption">{postcode}</Typography>
                          </>
                        )}
                        {(locality || region || postcode) && <br />}
                        {country && (
                          <>
                            <Typography variant="caption">{country}</Typography>
                          </>
                        )}
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };
  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  return (
    <>
      <Typography variant="h2" gutterBottom={true}>
        Create Service Request
      </Typography>
      <Form
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators
        }}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, handleSubmit, pristine, submitting, values }) => (
          <form onSubmit={handleSubmit} noValidate={true}>
            {/* LOCATION */}
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Rad Location
              </Typography>
              <Grid container={true} alignItems="flex-start" spacing={2}>
                <Grid item={true} xs={12}>
                  <Field name="service_location_id" fullWidth={true} required={true} component={ReactSelectAdapterLocation} />
                </Grid>
              </Grid>
            </Paper>

            {/* BIKE/TASKS */}
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                What
              </Typography>

              <AppBar position="static">
                <Tabs value={tab} onChange={handleTabChange}>
                  <Tab label="Service" />
                  <Tab label="Event" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <>
                  <Box className={classes.descriptionBox}>
                    <Field name="notes" fullWidth={true} required={false} multiline={true} component={TextField} type="text" label="Description" />
                  </Box>

                  <SectionCustomer
                    locationName={locationName}
                    customer={customer}
                    onUpdateBikes={bikes => {
                      setBikes(bikes);
                    }}
                  />
                  <SectionTasks
                    updateAvailableCustomers={(shopifyData: any, updateType: "ADD" | "REMOVE") => {
                      if (updateType === "ADD") {
                        if (!availableCustomers[shopifyData.id]) {
                          const updatedAddresses = { ...availableCustomers };
                          updatedAddresses[shopifyData.id] = shopifyData;
                          setAvailableCustomers(updatedAddresses);
                        }
                      } else if (updateType === "REMOVE") {
                        const updatedAddresses = { ...availableCustomers };
                        delete updatedAddresses[shopifyData];
                        setAvailableCustomers(updatedAddresses);
                        if (selectedAddress && selectedAddress.id === shopifyData) {
                          const address = {
                            id: "",
                            address1: "",
                            address2: "",
                            locality: "",
                            region: "",
                            postcode: "",
                            country: ""
                          };
                          setSelectedAddress(address);
                          setAddressId(null);
                          setSameAddress(false);
                          values.customer.address1 = "";
                          values.customer.address2 = "";
                          values.customer.locality = "";
                          values.customer.region = "";
                          values.customer.postcode = "";
                          values.customer.country = "";
                        }
                        if (customer && customer.id === shopifyData) {
                          const contactInfo = {
                            id: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: ""
                          };
                          setCustomer(contactInfo);
                          setSameAsCustomer(false);
                          values.customer.firstname = "";
                          values.customer.lastname = "";
                          values.customer.email = "";
                          values.customer.phone = "";
                        }
                      }
                    }}
                    bikes={bikes ? bikes : []}
                  />
                </>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <>
                  <SectionEventTypeSelect onChangeEventType={(value: any, option: any) => {}} />
                  <SectionEventName />
                </>
              </TabPanel>
            </Paper>

            {/* WHERE */}
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Where
              </Typography>
              <Typography variant="body2">The location where the service will take place.</Typography>

              {Object.entries(availableCustomers).length > 0 && showAvailableAddresses(values)}

              <Grid container={true} alignItems="flex-start" spacing={2}>
                <Grid item={true} xs={12}>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      onChange: async (address: any) => {
                        updateAddress(address);
                      },
                      placeholder: "Search Addresses..."
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field name="contact.alias" fullWidth={true} required={false} component={TextField} type="text" label="Location Name" />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.address1}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.address1" : "contact.address1"}
                    fullWidth={true}
                    required={true}
                    component={TextField}
                    type="text"
                    label="Address"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.address2}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.address2" : "contact.address2"}
                    fullWidth={true}
                    required={false}
                    component={TextField}
                    type="text"
                    label="Address 2"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.locality}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.locality" : "contact.locality"}
                    fullWidth={true}
                    required={true}
                    component={TextField}
                    type="text"
                    label="City"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.region}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.region" : "contact.region"}
                    fullWidth={true}
                    required={true}
                    component={TextField}
                    type="text"
                    label="State/Province"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.postcode}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.postcode" : "contact.postcode"}
                    fullWidth={true}
                    required={true}
                    component={TextField}
                    type="text"
                    label="Postal Code"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field
                    defaultValue={selectedAddress.country}
                    name={selectedAddress && addressId && selectedAddress.id === addressId ? "customer.country" : "contact.country"}
                    fullWidth={true}
                    required={true}
                    component={TextField}
                    type="text"
                    label="Country"
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* WHEN */}
            <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                When
              </Typography>
              <Typography variant="body2">Suggested date and time. The service team will confirm within 24 hours.</Typography>
              <Grid className={classes.whenBox} container={true} alignItems="flex-start" spacing={2}>
                <Grid item={true} xs={12}>
                  <Field name="expected_start_time" fullWidth={true} required={true} component={ReactSelectAdapterDate} />
                </Grid>
                <Grid item={true} xs={12}>
                  <Field name="scheduled_time" fullWidth={true} required={true} component={ReactSelectAdapterTime} />
                </Grid>
              </Grid>
            </Paper>

            {/* WHO */}
            <Paper className={classes.paper}>
              <SectionContactInfo
                handleChange={(sameName, _customer) => {
                  setSameAsCustomer(sameName);
                  setCustomer(_customer);
                  if (_customer.id) {
                    values.customer.firstname = _customer.firstName;
                    values.customer.lastname = _customer.lastName;
                    values.customer.email = _customer.email;
                    values.customer.phone = _customer.phone;
                  } else {
                    values.customer.firstname = "";
                    values.customer.lastname = "";
                    values.customer.email = "";
                    values.customer.phone = "";
                  }
                }}
                sameName={sameAsCustomer}
                availableCustomers={availableCustomers}
                customerId={customer.id}
              />
            </Paper>

            <Paper className={classes.paper}>
              <Grid container={true} alignItems="flex-start" spacing={2}>
                <Grid className={classes.subHead} item={true}>
                  <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        )}
      />
      <Snackbar isOpen={snackbarOpen} message={snackbarMessage} onClose={() => setSnackbarOpen(false)} variant={snackbarVariant as SnackbarVariant} />
    </>
  );
};

export default ServiceRequestForm;
