import * as React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";

import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";

export interface IFooterProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    feedbackLink: {
      textTransform: "uppercase",
      textAlign: "right"
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      marginTop: 25
    }
  })
);

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      <Link color="inherit" href="https://radpowerbikes.com/">
        Rad Power Bikes
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

export function Footer(props: IFooterProps) {
  const classes = useStyles(props);
  const buildInfo = `${process.env.REACT_APP_BRANCH} (#${process.env.REACT_APP_BUILDNUM})`;
  return (
    <Grid container={true} className={classes.footer}>
      <Grid xs={6} md={4} item={true}>
        <div>
          <Copyright />
        </div>
      </Grid>
      <Grid xs={6} md={4} item={true}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="caption" color="textSecondary" style={{ fontSize: 10 }}>
            {buildInfo})
          </Typography>
        </div>
      </Grid>
      <Grid xs={12} md={4} item={true}>
        <Tooltip title="Have ideas about how this app can be better? Let the dev team know!">
          <Typography className={classes.feedbackLink} variant="body2" color="textSecondary">
            <Link color="inherit" target="blank" href={`mailto:technology@radpowerbikes.com?subject=Mobile%20Service%20Feedback - (${buildInfo})&body=Feedback%20regarding%20build:%20${buildInfo}`}>
              Feedback
            </Link>
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
