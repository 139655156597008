import ApiService, { ENDPOINTS } from "utilities/api-service";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import IContact from "stores/contact/models/IContact";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import React from "react";
import Snackbar from "components/Snackbar";
import { TextField } from "final-form-material-ui";

interface IContactInfoFormProps {
  serviceRequest: IRMSServiceRequest;
  closeModal?: (response: any) => void;
}

interface IContactInfoFormState {
  snackbarMessage: any;
  snackbarOpen: boolean;
  snackbarVariant: "error" | "info" | "success" | "warning";
}

class SRContactInfoForm extends React.Component<IContactInfoFormProps, IContactInfoFormState> {
  state: IContactInfoFormState = {
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarVariant: "info"
  };

  validationError = (text: any) => {
    this.setState({
      snackbarMessage: text as string,
      snackbarOpen: true,
      snackbarVariant: "error"
    });
  };

  onSubmit = (values: IContact) => {
    // BEGIN: Field validations
    const validationErrors: string[] = [];

    if (!values.address1) {
      validationErrors.push("Address is required.");
    }

    if (!values.locality) {
      validationErrors.push("City is required.");
    }

    if (!values.region) {
      validationErrors.push("State/Province is required.");
    }

    if (!values.postcode) {
      validationErrors.push("Postal code is required.");
    }

    if (!values.country) {
      validationErrors.push("Country is required.");
    }

    if (!values.firstname || !values.lastname) {
      validationErrors.push("First and last name are required.");
    }

    if (!values.email) {
      validationErrors.push("Email is required.");
    }

    if (!values.phone) {
      validationErrors.push("Phone number is required.");
    }

    if (validationErrors.length > 0) {
      const errorList = validationErrors.map((err, i) => <div key={i}>{err}</div>);

      Promise.all(errorList).then(() => {
        this.setState({
          snackbarMessage: errorList,
          snackbarOpen: true,
          snackbarVariant: "error"
        });
      });
      // END: Field validations
    } else {
      if (this.props.serviceRequest.contact.id) {
        values.id = this.props.serviceRequest.contact.id;
      }

      ApiService.patch(ENDPOINTS.serviceRequestsId(this.props.serviceRequest.id.toString()), {
        id: this.props.serviceRequest.id,
        contact: values
      })
        .then(response => {
          this.setState(
            {
              snackbarMessage: "Contact info update successful.",
              snackbarOpen: true,
              snackbarVariant: "success"
            },
            () => {
              if (this.props.closeModal) {
                this.props.closeModal(response);
              }
            }
          );
        })
        .catch(error => {
          this.setState({
            snackbarMessage: `Contact info update failed: ${error.message}`,
            snackbarOpen: true,
            snackbarVariant: "error"
          });
        });
    }
  };

  handleSnackBarClose = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  render() {
    return (
      <>
        <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
          <Typography variant="h4" align="center" component="h1" gutterBottom={true}>
            Contact Info
          </Typography>
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                <Paper style={{ padding: 16 }}>
                  <Grid container={true} alignItems="flex-start" spacing={2}>
                    <Grid item={true} xs={12}>
                      <Grid item={true} xs={12}>
                        <Field
                          name="alias"
                          fullWidth={true}
                          required={false}
                          component={TextField}
                          type="text"
                          label="Location Name"
                          initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.alias : ""}
                          parse={value => (value === "" ? "" : value)}
                        />
                        <Field
                          name="firstname"
                          fullWidth={true}
                          required={true}
                          component={TextField}
                          type="text"
                          label="First Name"
                          initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.firstname : ""}
                          parse={value => (value === "" ? "" : value)}
                        />
                        <Field
                          name="lastname"
                          fullWidth={true}
                          required={true}
                          component={TextField}
                          type="text"
                          label="Last Name"
                          initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.lastname : ""}
                          parse={value => (value === "" ? "" : value)}
                        />
                        <Field
                          name="email"
                          fullWidth={true}
                          required={true}
                          component={TextField}
                          type="email"
                          label="Email"
                          initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.email : ""}
                          parse={value => (value === "" ? "" : value)}
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Field
                          name="phone"
                          fullWidth={true}
                          required={true}
                          component={TextField}
                          type="phone"
                          label="Phone"
                          initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.phone : ""}
                        />
                      </Grid>
                      <Field
                        fullWidth={true}
                        required={true}
                        name="address1"
                        component={TextField}
                        type="text"
                        label="Address"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.address1 : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        fullWidth={true}
                        required={false}
                        name="address2"
                        component={TextField}
                        type="text"
                        label="Address 2"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.address2 : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        fullWidth={true}
                        required={true}
                        name="locality"
                        component={TextField}
                        type="text"
                        label="City"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.locality : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        fullWidth={true}
                        required={true}
                        name="region"
                        component={TextField}
                        type="text"
                        label="State"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.region : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        fullWidth={true}
                        required={true}
                        name="postcode"
                        component={TextField}
                        type="text"
                        label="Postal Code"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.postcode : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        fullWidth={true}
                        required={true}
                        name="country"
                        component={TextField}
                        type="text"
                        label="Country"
                        initialValue={this.props.serviceRequest.contact ? this.props.serviceRequest.contact.country : ""}
                        parse={value => (value === "" ? "" : value)}
                      />
                    </Grid>
                    <Grid item={true} style={{ marginTop: 16 }}>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          />
        </div>
        <Snackbar isOpen={this.state.snackbarOpen} message={this.state.snackbarMessage} onClose={this.handleSnackBarClose} variant={this.state.snackbarVariant} />
      </>
    );
  }
}

export default SRContactInfoForm;
