import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Room";
import { withStyles } from "@material-ui/styles";
import { CalendarProps, momentLocalizer } from "react-big-calendar";
import { Dictionary } from "lodash";
import { flatten } from "lodash-es";
import moment from "moment";

import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import withConnection, { IConnectedRoutedComponentProps } from "../HOC/ConnectedRouted";
import AppCalendar from "components/AppCalendar";
import { COLORS } from "theme/theme";
import DateFormatter from "utilities/date-formatter";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import RMSLocationAction from "stores/rms-locations/RMSLocationAction";
import ServiceRequestList from "components/ServiceRequest/ServiceRequestList/ServiceRequestList";
import ServiceRequestPaginator from "components/ServiceRequest/ServiceRequestList/ServiceRequestPaginator";
import TabPanel from './TabPanel';
import ServiceRequestLegend from "components/ServiceRequest/ServiceRequestLegend";

const TABS = {
  CALENDAR: "Calendar",
  TABLE: "Table"
};

interface IRMSLocationState {
  datePeek: boolean;
  datePeekDate?: string;
  datePeekServiceRequests?: IRMSServiceRequest[];
  expanded: string;
  openModal: boolean;
  showLegend: boolean;
  selectedTab: string;
  titleDate?: string;
  priorityServicesExpanded: boolean;
  currentServicesExpanded: boolean;
}

interface IStoreProps {
  expectedStartTime: number;
  locations: IRMSLocation[];
  orderedServiceRequests: Dictionary<IRMSServiceRequest[]>;
  priorityServiceRequests: IRMSServiceRequest[];
  selectedLocation: IRMSLocation;
  serviceRequests: IRMSServiceRequest[];
  serviceRequestsInContext: IRMSServiceRequest[];
  unapprovedServiceRequests: IRMSServiceRequest[];
}

interface IStateToProps {
  selectedLocation: IRMSLocation;
  serviceRequests: IRMSServiceRequest[];
}

const mapStateToProps = (state: IStore): IStoreProps => ({
  expectedStartTime: state.location.expectedStartTime,
  locations: state.location.locations,
  orderedServiceRequests: state.serviceRequest.orderedServiceRequests,
  priorityServiceRequests: state.serviceRequest.priorityServiceRequests,
  selectedLocation: state.location.selectedLocation,
  serviceRequests: state.serviceRequest.serviceRequests,
  serviceRequestsInContext: state.location.serviceRequestsInContext,
  unapprovedServiceRequests: state.serviceRequest.unapprovedServiceRequests
});

const priorityStyle = { 
  backgroundColor: COLORS.SECONDARY.COLOR3, borderWidth: 5,
  borderColor: "#f00",
  borderStyle: "dashed",
  margin: "16px 0",
  '&:expanded': { margin: 0 }
};

// const unapprovedStyle = {
//   backgroundColor: COLORS.SECONDARY.COLOR2,
//   borderWidth: 5,
//   borderColor: "rgb(232, 116, 6)",
//   borderStyle: "dashed",
//   marginBottom: 20
// };

// const handleChange = (panel: string, comp: RMSLocation) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
//   const curPanel = comp.state.expanded;
//   const expanded = curPanel === panel ? null : panel;
//   comp.setState({ datePeek: expanded === "panel2", expanded });
// };

const styles = {
  expansionPanel: {
    padding: "10px 0"
  },
  expansionPanelSummary: {
    margin: 0
  },
  toolBar: {
    marginTop: "16px",
    display: "flex",
    justifyContent: "space-between"
  }
};

const summaryStyles = {
  expanded: {},
  content: {
    "&$expanded": {
      margin: "0"
    }
  }
};

const StyledExpansionPanelSummary = withStyles(summaryStyles)(
  ExpansionPanelSummary
);

class RMSLocation extends React.Component<IStateToProps & IConnectedRoutedComponentProps, IRMSLocationState> {
  public state: IRMSLocationState = {
    showLegend: false,
    datePeek: false,
    openModal: false,
    expanded: "panel3",
    selectedTab: TABS.CALENDAR,
    priorityServicesExpanded: true,
    currentServicesExpanded: true,
  };

  public handleData = this._handleData.bind(this);
  public handleCloseDatePeek = this._handleCloseDatePeek.bind(this);
  public handleCloseLegend = this._handleCloseLegend.bind(this);
  public handleTabChange = this._handleTabChange.bind(this);

  public fetchLocationData(id: string) {
    this.props.dispatch(RMSLocationAction.getLocation(id));
  }

  public componentDidMount() {
    const id = this.props.routerProps.match.params["id"];
    this.fetchLocationData(id);
  }

  public componentDidUpdate(prevProps: IConnectedRoutedComponentProps) {
    if (this.props.routerProps.match.params) {
      const nextId = this.props.routerProps.match.params["id"];
      if (nextId !== prevProps.routerProps.match.params["id"]) {
        this.fetchLocationData(nextId);
      }
    }
    const est = prevProps.data["expectedStartTime"];
    const nEst = this.props.data["expectedStartTime"];
    if (est) {
      if (nEst !== est && this.state.datePeek) {
        prevProps.routerProps.history.push(`?date=${nEst}`);
        const formated = moment.unix(parseInt(nEst, 10)).toString();
        const paramDate = DateFormatter.startOfDayFormatted(formated);
        const orderedServiceRequests = prevProps.data["orderedServiceRequests"];
        const found = orderedServiceRequests[paramDate];
        this.setState({ datePeekDate: paramDate, datePeekServiceRequests: found });
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(RMSLocationAction.selectedLocation(null));
  }

  togglePriorityServices = () => {
    const status = this.state.priorityServicesExpanded ? false : true;
    this.setState({
      priorityServicesExpanded: status
    })
  }

  toggleCurrentServices = () => {
    const status = this.state.currentServicesExpanded ? false : true;
    this.setState({
      currentServicesExpanded: status
    })
  }

  public render() {
    // FIXME: - find a better way to select state from the HOC prop
    const selectedLocation = this.props.data["selectedLocation"];

    if (!selectedLocation) {
      return <Loading isLoading={true} />;
    }

    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Typography variant="h2">Location {selectedLocation ? `(${selectedLocation.name})` : ''}</Typography>
          <Box style={styles.toolBar}>
            <Button variant="outlined" onClick={() => this.setState({ showLegend: true })}>
              <HelpIcon color="primary" /> Legend
            </Button>
            {selectedLocation && (
              <ButtonGroup>
                <Button component={Link} to={`/locations/${selectedLocation.id}/settings`}>
                  <SettingsIcon /> Map
                </Button>
                <Button component={Link} to={`/locations/${selectedLocation.id}/postal-codes`}>
                  Edit Postal Codes
                </Button>
              </ButtonGroup>
            )}
          </Box>

          {this._showPrioritySRs()}

          {this._tabs()}
        </Grid>
        <>
          <Dialog onClose={this.handleCloseLegend} open={this.state.showLegend}>
            <DialogTitle>Service Requests Legend</DialogTitle>
            <DialogContent><ServiceRequestLegend /></DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseLegend} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog maxWidth="lg" fullWidth={true} onClose={this.handleCloseDatePeek} aria-labelledby="simple-dialog-title" open={!this.state.datePeekServiceRequests ? false : true}>
            <DialogTitle id="simple-dialog-title">Service Requests for {this.state.datePeekDate}</DialogTitle>
            <DialogContent>
              {this.state.datePeekServiceRequests ? <ServiceRequestList title=" " serviceRequests={this.state.datePeekServiceRequests} /> : <div>'Nothing to see here...'</div>}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDatePeek} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </Grid>
    );
  }

  private _showPrioritySRs() {
    const priorityServiceRequests = this.props.data["priorityServiceRequests"];
    if (!priorityServiceRequests || priorityServiceRequests.length <= 0) {
      return null;
    }
    return (
      <ExpansionPanel
        expanded={this.state.priorityServicesExpanded}
        style={priorityStyle}
        onChange={this.togglePriorityServices}
      >
        <StyledExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          style={styles.expansionPanelSummary}
        >
          <>
            <Typography variant="h4">Priority Service Requests {priorityServiceRequests ? `(${priorityServiceRequests.length})` : null}</Typography>
            <Typography variant="caption">These requests are open, and do NOT have a future date planned!</Typography>
          </>
        </StyledExpansionPanelSummary>
        <ExpansionPanelDetails style={styles.expansionPanel}>
          <Container>
            <ServiceRequestList serviceRequests={priorityServiceRequests} />
          </Container>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  private _tabs() {
    const { selectedTab } = this.state;
    return (
      <>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={this.handleTabChange}>
            <Tab value={TABS.CALENDAR} label={TABS.CALENDAR} />
            <Tab value={TABS.TABLE} label={TABS.TABLE} />            
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={TABS.CALENDAR}>
          {this._calendar()}
        </TabPanel>
        <TabPanel value={selectedTab} index={TABS.TABLE}>
          {this._showTodaysSRs()}
        </TabPanel>
      </>
    );
  }

  // private _showUnapprovedSRs() {
  //   const unapprovedServiceRequests = this.props.data["unapprovedServiceRequests"];
  //   if (!unapprovedServiceRequests || unapprovedServiceRequests.length <= 0) {
  //     return null;
  //   }
  //   const { expanded } = this.state;
  //   return (
  //     <ExpansionPanel style={unapprovedStyle} expanded={expanded === "panel2"} onChange={handleChange("panel2", this)}>
  //       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
  //         <>
  //           <Typography variant="h4">Pending Service Requests {unapprovedServiceRequests ? `(${unapprovedServiceRequests.length})` : null}</Typography>
  //           <Typography variant="caption">These requests are slated for a date, but are not yet confirmed.</Typography>
  //         </>
  //       </ExpansionPanelSummary>
  //       <ExpansionPanelDetails style={styles.expansionPanel}>
  //         <Container>
  //           <ServiceRequestList serviceRequests={unapprovedServiceRequests} />
  //         </Container>
  //       </ExpansionPanelDetails>
  //     </ExpansionPanel>
  //   );
  // }

  private _calendar() {
    const globalizeLocalizer = momentLocalizer(moment);
    const calProps: CalendarProps = {
      localizer: globalizeLocalizer
    };
    return (
      <Box>
        <AppCalendar serviceRequests={this.props.data["serviceRequests"]} calendarProps={calProps} />
      </Box>
    );
  }

  private _showTodaysSRs() {
    const selectedLocation = this.props.data["selectedLocation"];
    const serviceRequestsInContext = this.props.data["serviceRequestsInContext"];
    const orderedServiceRequests = this.props.data["orderedServiceRequests"];
    const params = new URLSearchParams(this.props.routerProps.location.search);

    let date = params.get("date");
    if (!date) {
      const today = new Date().getTime() / 1000
      date = today.toString()
    }

    let naSorted = [];
    let totalGravity = 0;
    if (serviceRequestsInContext) {
      naSorted = serviceRequestsInContext.sort((a: IRMSServiceRequest, b: IRMSServiceRequest) => {
        const aTime = moment(a.scheduled_time, "HH:mm:ss");
        const bTime = moment(b.scheduled_time, "HH:mm:ss");
        return aTime.isBefore(bTime) ? -1 : 1;
      });
      const tasks = flatten(naSorted.map((sr: IRMSServiceRequest) => sr.tasks));
      totalGravity = tasks.map((task: any) => task.task.gravity).reduce((a: number, b: number) => a + b, 0);
    }

    return (
      <ExpansionPanel 
        expanded={this.state.currentServicesExpanded}
        onChange={this.toggleCurrentServices}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          style={styles.expansionPanelSummary}
          aria-controls="panel3bh-content" id="panel3bh-header"
        >
          {selectedLocation ? (
            <>
              <Typography variant="h3">
                {this.state.titleDate} - (Gravity: {totalGravity})
              </Typography>
              <Typography variant="h5">
                {selectedLocation.name} ({selectedLocation.id})
              </Typography>
              <Typography variant="caption">These service requests are ready to be worked on!</Typography>
            </>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={styles.expansionPanel}>
          <Container>
            <ServiceRequestPaginator date={date} callback={this.handleData} data={orderedServiceRequests} />
            <ServiceRequestList serviceRequests={naSorted} showMap={true} />
          </Container>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  // HANDLERS
  private _handleTabChange(event: React.ChangeEvent<{}>, tab: string) {    
    this.setState({ selectedTab: tab });
  }
  private _handleData(page: number, date: string) {
    const orderedServiceRequests = this.props.data["orderedServiceRequests"];
    const found = orderedServiceRequests[date];
    this.props.dispatch(RMSLocationAction.setServiceRequestsInContext(found));
    this.props.dispatch(RMSLocationAction.setExpectedStartTime(DateFormatter.timestamp(date)));
    this.props.routerProps.history.push(`?date=${DateFormatter.timestamp(date)}`);
    this.setState({ titleDate: date });
  }

  private _handleCloseDatePeek() {
    this.setState({ datePeekServiceRequests: null, datePeekDate: null });
  }

  private _handleCloseLegend() {
    this.setState({ showLegend: false });
  }
}

const bikeDetail = withConnection(RMSLocation, mapStateToProps);
export default withStyles(styles)(bikeDetail);
