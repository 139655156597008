import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { COLORS } from "theme/theme";
import CustomerDetail from "components/Customer/CustomerDetail";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import IBike from "stores/bikes/models/IBike";
import { Link } from "react-router-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

export interface IBikeDetail {
  bike: IBike
}

const useStyles = makeStyles(() => ({
  bikeButton: {
    marginLeft: "-8px"
  },
  bikeData: {
    marginLeft: "24px",
  },
  bikeDataText: {
    display: "block"
  },
  noCustomer: {
    display: "block",
    color: COLORS.ERROR
  }
}));

const BikeDetail = (props: IBikeDetail) => {
  const classes = useStyles(props);

  return (
    <>
      <Button className={classes.bikeButton}  to={`/bikes/${props.bike.id}`} component={Link}>
        <Typography variant="h6">
          <DirectionsBikeIcon /> {props.bike.serial_number}
        </Typography>
      </Button>
      <Box className={classes.bikeData}>
        <Typography variant={"caption"} className={classes.bikeDataText}>
          Model Data: {props.bike.model} - {props.bike.manufacturer_description} (version: {props.bike.version || "unknown"}, factory: {props.bike.factory} )
        </Typography>
        <Typography variant={"caption"} className={classes.bikeDataText}>
          Mileage: {props.bike.mileage ? props.bike.mileage : "Unknown"}
        </Typography>
        <Typography variant={"caption"} className={classes.bikeDataText}>
          {props.bike.month_manufactured && props.bike.year_model && <div>Manufacture Date: {props.bike.month_manufactured} {props.bike.year_model}</div>}
          {props.bike.manufacturer_model && <div>Manufacturer Model: {props.bike.manufacturer_model}</div>}
          {props.bike.manufacturer_sku && <div>Manufacturer SKU: {props.bike.manufacturer_sku}</div>}
          {props.bike.serial_number_motor && <div>Motor SN: {props.bike.serial_number_motor}</div>}
          {props.bike.serial_number_battery && <div>Battery SN: {props.bike.serial_number_battery}</div>}
        </Typography>
        {props.bike.customer ? (
          <CustomerDetail customer={props.bike.customer}/>
        ) : (
          <Typography variant="caption" className={classes.noCustomer}>No customer is currently assigned to this bike!</Typography>
        )}
      </Box>
    </>
  )
};

export default BikeDetail;
