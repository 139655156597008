export const distanceMetricToImperial = (miles: number, round: boolean = false) => {
  const v = miles * 0.621371;
  if (round) {
    return Math.round(v);
  } else {
    return v;
  }
};

export const distanceImperialToMetric = (kms: number, round: boolean = false) => {
  const v = kms * 1.60934;
  if (round) {
    return Math.round(v);
  } else {
    return v;
  }
};
