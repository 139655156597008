import BikeDetail from "./BikeDetail";
import IBike from "stores/bikes/models/IBike";
import React from "react";

export interface IBikeList {
  bikes: IBike[];
}
const BikesList = (props: IBikeList) => {
  return (
    <div>
      {props.bikes.map(bike => (
        <div key={bike.id}>
          <BikeDetail bike={bike} />
        </div>
      ))}
    </div>
  );
};
export default BikesList;
