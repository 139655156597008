import ApplicationError from "stores/errors/models/ApplicationError";
import { Dictionary } from "lodash";
import IAction from "../IAction";
import IAudit from "stores/audits/models/IAudit";
import IRMSServiceRequest from "./models/IRMSServiceRequest";
import { SnackbarVariant } from "components/Snackbar";
export interface IStartEndDates {
  startTimeStamp: number;
  endTimeStamp: number;
  perPage?: number;
}
export default class RMSServiceRequestAction {
  public static readonly ERROR: string = "RMSServiceRequestAction.ERROR";
  public static readonly GET_SERVICE_REQUEST_SUCCESS: string = "RMSServiceRequestAction.GET_SERVICE_REQUEST_SUCCESS";
  public static readonly GET_SERVICE_REQUEST_AUDITS_SUCCESS: string = "RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS_SUCCESS";
  public static readonly GET_SERVICE_REQUEST: string = "RMSServiceRequestAction.GET_SERVICE_REQUEST";
  public static readonly GET_SERVICE_REQUEST_AUDITS: string = "RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS";
  public static readonly GET_SERVICE_REQUESTS: string = "RMSServiceRequestAction.GET_SERVICE_REQUESTS";
  public static readonly SET_PRIORITY_SERVICE_REQUESTS: string = "RMSServiceRequestAction.SET_PRIORITY_SERVICE_REQUESTS";
  public static readonly SET_SERVICE_REQUESTS_ORDERED: string = "RMSServiceRequestAction.SET_SERVICE_REQUESTS_ORDERED";
  public static readonly SET_SERVICE_REQUESTS: string = "RMSServiceRequestAction.SET_SERVICE_REQUESTS";
  public static readonly SET_SERVICE_REQUESTS_FOR_CALENDAR: string = "RMSServiceRequestAction.SET_SERVICE_REQUESTS_FOR_CALENDAR";
  public static readonly SET_UNAPPROVED_SERVICE_REQUESTS: string = "RMSServiceRequestAction.SET_UNAPPROVED_SERVICE_REQUESTS";
  public static readonly TOGGLE_APPROVE: string = "RMSServiceRequestAction.TOGGLE_APPROVE";
  public static readonly UPDATE_SUCCESS: string = "RMSServiceRequestAction.UPDATE_SUCCESS";
  public static readonly UPDATE: string = "RMSServiceRequestAction.UPDATE";
  public static setServiceRequests(srs: IRMSServiceRequest[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: srs,
      type: RMSServiceRequestAction.SET_SERVICE_REQUESTS
    };
  }
  public static setPriorityServiceRequests(srs: IRMSServiceRequest[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: srs,
      type: RMSServiceRequestAction.SET_PRIORITY_SERVICE_REQUESTS
    };
  }
  public static setUnapprovedServiceRequests(srs: IRMSServiceRequest[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: srs,
      type: RMSServiceRequestAction.SET_UNAPPROVED_SERVICE_REQUESTS
    };
  }
  public static setServiceRequestsOrdered(srs: Dictionary<IRMSServiceRequest[]>): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: srs,
      type: RMSServiceRequestAction.SET_SERVICE_REQUESTS_ORDERED
    };
  }
  public static setServiceRequestsForCalendar(serviceRequests: IRMSServiceRequest[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequests,
      type: RMSServiceRequestAction.SET_SERVICE_REQUESTS_FOR_CALENDAR
    };
  }
  public static getServiceRequest(serviceRequestId: string): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequestId,
      type: RMSServiceRequestAction.GET_SERVICE_REQUEST
    };
  }

  public static getServiceRequestAudits(serviceRequestId: string): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequestId,
      type: RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS
    };
  }
  
  public static getServiceRequests(obj:IStartEndDates): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: obj,
      type: RMSServiceRequestAction.GET_SERVICE_REQUESTS
    };
  }
  public static getServiceRequestSuccess(serviceRequest: IRMSServiceRequest): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequest,
      type: RMSServiceRequestAction.GET_SERVICE_REQUEST_SUCCESS
    };
  }

  public static getServiceRequestAuditsSuccess(serviceRequest: IAudit[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequest,
      type: RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS_SUCCESS
    };
  }

  

  public static updateServiceRequest(data: Partial<IRMSServiceRequest>): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: data,
      type: RMSServiceRequestAction.UPDATE
    };
  }

  public static toogleApproveServiceRequest(data: Partial<IRMSServiceRequest>): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: data,
      type: RMSServiceRequestAction.TOGGLE_APPROVE
    };
  }

  public static updateServiceRequestStatus(status: SnackbarVariant): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: status,
      type: RMSServiceRequestAction.UPDATE_SUCCESS
    };
  }

  public static error(error: ApplicationError): IAction<string> {
    return {
      payload: error,
      type: RMSServiceRequestAction.ERROR
    };
  }
}
