import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import IRMSServiceRequest, { RPBServiceRequestStatus, styleForStatus } from "stores/rms-service-requests/models/IRMSServiceRequest";

const ServiceRequestLegend = () => {
  const statusus: any[] = [
    { status: RPBServiceRequestStatus.CANCELED },
    { status: RPBServiceRequestStatus.COMPLETE },
    { status: RPBServiceRequestStatus.CREATED },
    { status: RPBServiceRequestStatus.DEFERRED },
    { status: RPBServiceRequestStatus.PAST_DUE },
    { status: RPBServiceRequestStatus.READY },
  ];

  return (
    <Box>
      <Table>
        <TableBody>
          {statusus.map((s, idx) => {
            const sr = s as IRMSServiceRequest;
            return (
              <TableRow className={`service-request ${sr.status}`} key={idx}>
                <TableCell style={styleForStatus(s as IRMSServiceRequest)}>
                  <span>{sr.status}</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ServiceRequestLegend;
