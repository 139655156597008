import { Chip, makeStyles } from "@material-ui/core";

import { COLORS } from '../../theme/theme';
import { ICommonDataTypeProps } from "../../stores/common/models/CommonData";
import IServiceRequestTask from "../../stores/tasks/models/ITask";
import React from "react";

interface IMyChipProps {
  label:string;
  task: IServiceRequestTask;
  taskStatusTypes?: ICommonDataTypeProps;
}
const useStyles = makeStyles({
  // style rule
  complete: {border: `1px solid ${COLORS.SECONDARY.COLOR3}`, backgroundColor: COLORS.SECONDARY.COLOR3 },
  incomplete: {
    border: `1px solid ${COLORS.PRIMARY.BRAND}`
  }
});
const MyChip = (props: IMyChipProps) => {
  const classes = useStyles(props);
  const { task } = props;

  return (
    <Chip
      className={
        task.status === props.taskStatusTypes.COMPLETE
          ? classes.complete
          : classes.incomplete
      }
      variant="outlined"
      size="small"
      label={props.label}
    />
  );
};
export default MyChip;
