import ApiService, { ENDPOINTS } from '../../utilities/api-service';
import { call, put } from "redux-saga/effects";
import { get, groupBy } from "lodash-es";

import DateFormatter from 'utilities/date-formatter';
import IAction from "../IAction";
import IRMSLocation from "./models/IRMSLocation";
import RMSLocationAction from "./RMSLocationAction";
import RMSServiceRequestAction from '../rms-service-requests/RMSServiceRequestAction';
import { RPBServiceRequestStatus } from 'stores/rms-service-requests/models/IRMSServiceRequest';

export default class RMSLocationSaga {
  public static *getLocation(action: IAction<void>) {
    // reset current service requests in redux
    yield put(RMSServiceRequestAction.setServiceRequests(null))
    yield put(RMSServiceRequestAction.setServiceRequestsOrdered(null))
    try {
      const locationsResponse = yield call(ApiService.get, ENDPOINTS.locationsId(action.payload));
      const location: IRMSLocation = get(locationsResponse, "data");
      yield put(RMSLocationAction.selectedLocation(location));
      if(location.serviceRequests){
        yield put(RMSServiceRequestAction.setServiceRequests(location.serviceRequests))
        
        // find priority service requests with status past-due        
        const prioritySrs = location.serviceRequests.filter(b=> b.status === RPBServiceRequestStatus.PAST_DUE)
        // sorted from oldest to newest
        const prioSorted = prioritySrs.sort((a,b)=> (a.expected_start_time < b.expected_start_time ? 1 : 0))
        yield put(RMSServiceRequestAction.setPriorityServiceRequests(prioSorted))

        
        // organized for pagination
        const nonPrioritySrs = location.serviceRequests.filter(b=> b.expected_start_time !== null)
        const groups  = groupBy(nonPrioritySrs,(sr)=>{
          return DateFormatter.startOfDayFormatted(sr.expected_start_time)
        });
        
        // unapproved
        const notApproved = location.serviceRequests.filter(b=> (!b.is_approved && b.status !== "completed" && b.status !== "canceled" && b.expected_start_time !== null))
        const naSorted = notApproved.sort((a,b)=> {
          return (new Date(a.expected_start_time) > new Date(b.expected_start_time)) ? 1 : -1
        })
        yield put(RMSServiceRequestAction.setUnapprovedServiceRequests(naSorted))
        
        yield put(RMSServiceRequestAction.setServiceRequestsOrdered(groups))
        
      }
      
    } catch (error) {
      yield put(RMSLocationAction.error(error));
    }
  }
  
  public static *getLocations(action: IAction<void>) {
    try {
      // get locations data
      const locationsResponse = yield call(ApiService.get, ENDPOINTS.locations);
      const locations: IRMSLocation[] = get(locationsResponse, "data.results");
      yield put(RMSLocationAction.addLocations(locations));
      
    } catch (error) {
      yield put(RMSLocationAction.error(error));
    }
  }
  
}
