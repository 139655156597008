import { Reducer, ReducersMapObject, combineReducers } from "redux";

import BikeReducer from "./bikes/BikeReducer";
import CommonDataReducer from "./common/CommonReducer";
import CustomerReducer from "./customers/CustomerReducer";
import IStore from "./IStore";
import OrderReducer from "./orders/OrderReducer";
import RMSLocationReducer from "./rms-locations/RMSLocationReducer";
import RMSServiceRequestReducer from "./rms-service-requests/RMSServiceRequestReducer";
import SearchReducer from "./search/SearchReducer";
import UserReducer from "./user/UserReducer";

const reducerMap: ReducersMapObject = {
  bike: BikeReducer.reducer,
  commonData: CommonDataReducer.reducer,
  customer: CustomerReducer.reducer,  
  location: RMSLocationReducer.reducer,
  order: OrderReducer.reducer,
  serviceRequest: RMSServiceRequestReducer.reducer,
  user: UserReducer.reducer,
  search:SearchReducer.reducer
};

export default combineReducers(reducerMap) as Reducer<IStore>;
