import { applyMiddleware, createStore } from 'redux';

import Reducer from './stores/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './stores/rootSaga';

const sagaMiddleware = createSagaMiddleware();


// create a redux store with our reducer above and middleware
const store = createStore(
  Reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;