import ApiService, { ENDPOINTS } from 'utilities/api-service';
import { call, put } from 'redux-saga/effects';

import BikeAction from './BikeAction';
import IAction from '../IAction';
import IBike from './models/IBike';
import { ITimelineObject } from '../timeline/models/TimelineObject';
import { get } from 'lodash-es';

export default class BikeSaga {
  public static *getBike(action: IAction<void>) {    
    try {
      const response = yield call(
        ApiService.get,
        ENDPOINTS.bikesId(action.payload)
      );
      const bike: IBike = get(response, "data");
      yield put(
        BikeAction.getBikeSuccess(bike)
      );      
    } catch (error) {
      yield put(BikeAction.error(error));
    }
  }

  public static *search(action: IAction<void>) {    
    try {
      const response = yield call(
        ApiService.get,
        ENDPOINTS.bikeSearch(action.payload)
      );
      const bikes: IBike[] = get(response, "data.results");      
      yield put(
        BikeAction.searchResults(bikes)
      );      
    } catch (error) {
      yield put(BikeAction.error(error));
    }
  }

  public static *getBikeTimeline(action: IAction<void>) {
    try {      
      
      yield call(BikeSaga.getBike,action)                  
      const response = yield call(
        ApiService.get,
        ENDPOINTS.bikesIdTimeline(action.payload)
      );
      const timelineData: any[] = get(response, "data");
      const timeline: ITimelineObject[] = timelineData.map(obj=>({data:obj,objectType:obj.objectType}))
      yield put(
        BikeAction.getTimelineSuccess({data:timeline})
      );
    } catch (error) {
      console.log(error);
      
      yield put(BikeAction.error(error));
    }
  }
}
