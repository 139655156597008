import IAction from "../IAction";
import IBike from "stores/bikes/models/IBike";
import ICustomer from '../customers/models/ICustomer';
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import SearchAction from "./SearchActions";

export interface ISearchResults {
  bikes?:IBike[],
  service_requests?:IRMSServiceRequest[];
  customers?:ICustomer[]
}
export interface ISearchState {
  error: Error;
  results: ISearchResults;
  criteria: any;
  searching: boolean;
}

const initState = (): ISearchState => ({
  error: null,
  results: null,
  criteria: null,
  searching: false
});
export default class SearchReducer {
  public static reducer(state: ISearchState = initState(), action: IAction<any>): ISearchState {
    switch (action.type) {
      case SearchAction.IS_SEARCHING:
        return { ...state, error: null,  searching: action.payload };
      case SearchAction.SEARCH:
        return { ...state, error: null, criteria: action.payload, searching: true };
      case SearchAction.SEARCH_RESULTS:
        return { ...state, error: null, searching: false, results: action.payload };
      case SearchAction.ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  }
}
