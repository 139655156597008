import { Field } from "react-final-form";
import { IRPBTask } from "stores/tasks/models/ITask";
import IStore from "stores/IStore";
import React from "react";
import Select from 'react-select';
import { TextField } from "final-form-material-ui";
import { useSelector } from "react-redux";

export interface ISelectEventTypeProps {
  onChangeEventType: (value: any, option: any) => void;
}
export const SectionEventName = (props: any) => {
  return <Field label="Event Description"  name="events[0].task_notes" fullWidth={true} required={false} component={TextField} />;
};

export const SectionEventTypeSelect = (props: ISelectEventTypeProps) => {
  const allEvents = useSelector((state: IStore) => state.commonData.events);
  const events = allEvents.map((ev: IRPBTask) => ({ label: ev.name, value: ev.id,category:ev.category }));
  const ReactSelectEventType = ({ input, fields, dataPoint, ...rest }: any) => (
    <Select
      {...input}
      {...rest}
      inputLabel="Select an Event Type"
      isClearable={true}
      optionLabel="Event"
      options={events}
      onChange={(value: any, trigger: any) => {
        input.onChange(value);
      }}
    />
  );

  return <Field placeholder="Event type"  component={ReactSelectEventType} fullWidth={true} required={false} name="events[0].task" />;
};
