import { Button, TableCell, Typography } from "@material-ui/core";

import DateFormatter from "utilities/date-formatter";
import { Dispatch } from "redux";
import IAction from "stores/IAction";
import { IDispatchToProps } from "interfaces";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import { Link } from "react-router-dom";
import RMSLocationAction from "stores/rms-locations/RMSLocationAction";
import React from "react";
import SearchAction from "stores/search/SearchActions";
import ServiceRequestHelper from "../../../utilities/service-request-helper";
import ServiceTaskItem from "components/Task/ServiceTaskItem";
import { connect } from "react-redux";

interface IServiceRequestItemProps {
  serviceRequest: IRMSServiceRequest;
}



interface IServiceRequestItemState {
  loading: boolean;
}

class ServiceRequestItem extends React.Component<IServiceRequestItemProps & IDispatchToProps, IServiceRequestItemState> {
  public state: IServiceRequestItemState = {
    loading: true
  };

  public render() {
    if(!this.props.serviceRequest){
      return null;
    }
    // const hasButton = (this.props.serviceRequest.status !== 'canceled' && this.props.serviceRequest.status !== 'completed')
    return (
      <>
        <TableCell>
          <Link onClick={()=> this.props.dispatch(SearchAction.searchResults(null)) } to={`/service-requests/${this.props.serviceRequest.id}`}>id: {this.props.serviceRequest.id} </Link>
        </TableCell>
        <TableCell>
          {(this.props.serviceRequest.contact && this.props.serviceRequest.contact.email) &&
            <Typography variant="body1" >
              {this.props.serviceRequest.contact.firstname} {this.props.serviceRequest.contact.lastname}
            </Typography>
          }
        </TableCell>
        <TableCell>
          <Button onClick={()=>{
            this.props.dispatch(RMSLocationAction.setExpectedStartTime(DateFormatter.timestamp(this.props.serviceRequest.expected_start_time)))
          }}>
            {DateFormatter.defaultDateFormat(this.props.serviceRequest.expected_start_time)} @ 
            {DateFormatter.timeFormat(this.props.serviceRequest.scheduled_time)}
          </Button>
        </TableCell>
        <TableCell>
          {this.props.serviceRequest.tasks ? this.props.serviceRequest.tasks.map((t) => (
            <ServiceTaskItem bike={ServiceRequestHelper.bikeForTask(this.props.serviceRequest, t)} key={t.id} edit={false} serviceRequest={this.props.serviceRequest} task={t} />
          )) : null}
        </TableCell>
        <TableCell>
          <span>{this.props.serviceRequest.status}</span>
        </TableCell>
        {/* <TableCell>
          {(hasButton && !this.props.serviceRequest.is_approved) ? <Button
            onClick={() => {
              this.props.dispatch(RMSServiceRequestAction.toogleApproveServiceRequest(this.props.serviceRequest));
            }}>
            Approve
          </Button> : null}
        </TableCell> */}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch<IAction<any>>): IDispatchToProps => ({
  dispatch
});
export default connect(
  null,
  mapDispatchToProps
)(ServiceRequestItem);
