import * as React from "react";

import { Link, Typography } from "@material-ui/core";

// import { Link as DomLink } from "react-router-dom";
import ICustomer from "stores/customers/models/ICustomer";
import IconFlag from "components/Customer/IconFlag";
import Loading from "../Loading/Loading";

export interface ICustomerTimelineObjectProps {
  data: ICustomer;
}

const CustomerTimelineObject =(props: ICustomerTimelineObjectProps) =>{
  const { data } = props;
  if (!data) {
    return <Loading isLoading={true} />;
  }
  return (
    <div>
      <div>
        <Typography variant={"h3"}>{data.firstname} {data.lastname}</Typography>
        <Typography variant="body1">
          <span style={{display:"flex"}}>
            <span style={{marginRight:10}}>Shopify Store:</span>
            <span>{<IconFlag store={data.shopify_store} />}</span>
          </span>
        </Typography>
        <div>{data.email}</div>
        <div>{data.phone}</div>
        <div>
          <Link target="_blank" href={`https://${data.shopify_store}/admin/customers/${data.id}`}>
            Shopify Profile
          </Link>
        </div>
      </div>
    </div>
  );
}
export default CustomerTimelineObject
