import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import DateFormatter from "utilities/date-formatter";
import { Dictionary } from "lodash";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import React from "react";
import moment from "moment";

export interface IServiceRequestPaginatorProps {
  data: Dictionary<IRMSServiceRequest[]>;
  date?: string;
  callback: (page: number, date: string) => void;
}

export interface IServiceRequestPaginatorState {
  currentDate: string;
  currentPage: number;
}

class ServiceRequestPaginator extends React.Component<IServiceRequestPaginatorProps, IServiceRequestPaginatorState> {
  public state: IServiceRequestPaginatorState = {
    currentDate: DateFormatter.startOfDayFormatted(),
    currentPage: 0
  };
  private previousDate = this._previousDate.bind(this);
  private today = this._today.bind(this);
  private nextDate = this._nextDate.bind(this);
  public componentDidMount() {
    if (this.props.date) {
      const formated = moment.unix(parseInt(this.props.date, 10)).toString();
      const paramDate = DateFormatter.startOfDayFormatted(formated);
      this.setState({ currentDate: paramDate }, () => {
        this.changeForDate();
      });
    }
  }
  public componentDidUpdate(prevProps: IServiceRequestPaginatorProps) {
    if (!prevProps.data && this.props.data) {
      this.changeForDate();
    }
  }
  public render() {
    return (
      <Box>
        <ButtonGroup>
          <Button onClick={this.previousDate}>prev</Button>
          <Button onClick={this.today}>TODAY</Button>
          <Button onClick={this.nextDate}>next</Button>
        </ButtonGroup>
      </Box>
    );
  }
  private changeForDate() {
    if (!this.props.data) {
      return;
    }
    const idx = Object.keys(this.props.data).indexOf(this.state.currentDate);
    this.setState({ currentPage: idx });
    this.props.callback(idx, this.state.currentDate);
  }
  private _handleDateChange(increment: number) {
    const currentPage = this.state.currentPage + increment;
    const date = moment(this.state.currentDate).add(increment, "day");
    const currentDate = DateFormatter.startOfDayFormatted(date.toString());
    this.setState({ currentPage, currentDate });
    this.props.callback(currentPage, currentDate);
  }
  private _today() {
    this.setState({ currentPage: 0, currentDate: DateFormatter.startOfDayFormatted() }, () => {
      this._handleDateChange(0);
    });
  }

  private _previousDate() {
    this._handleDateChange(-1);
  }
  private _nextDate() {
    this._handleDateChange(1);
  }
}

export default ServiceRequestPaginator;
