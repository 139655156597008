import ApiService, { ENDPOINTS } from "utilities/api-service";
import { Box, Checkbox, FormLabel, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { distanceImperialToMetric, distanceMetricToImperial } from "utilities/mileage-conversion";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { cloneDeep } from "lodash";
import { makeStyles } from "@material-ui/styles";

interface IEditMileageProps {
  bike: any;
  onUpdate: (error?: any) => unknown;
  updateType: "edit";
}

const useStyles = makeStyles(() => ({
  submitButton: {
    marginTop: "16px"
  }
}));

const EditMileage = (props: IEditMileageProps) => {

  const classes = useStyles(props);
  const [mileage, setMileage] = useState(props.bike.mileage);
  const [isMetric, setIsMetric] = useState(false);
  
  const mileageInputField = () => {
    // handle metric view    
    let mileageForView = mileage ;
    if (mileageForView) {
      if (!isMetric) {
        mileageForView = distanceMetricToImperial(mileage,true);
      }
    }
    
    return (
      <TextField
      key={new Date().getTime()}
        type="number"
        defaultValue={mileageForView}
        id="standard-basic"
        label="Mileage"
        placeholder="2562"
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const num = parseInt(e.currentTarget.value)
          if(isMetric){
            setMileage(num);
          }else{
            const kms = distanceImperialToMetric(num)
            console.log(kms);
            
            setMileage(kms);
          }
          
        }}
      />
    );
  };
  const handleUpdate = (data: any) => {
    const bikeData = cloneDeep(props.bike);    
    bikeData.mileage = mileage;
    ApiService.patch(ENDPOINTS.bikesId(props.bike.id.toString()), bikeData)
      .then(() => {
        props.onUpdate();
      })
      .catch(error => {
        console.warn("UPDATE BIKE CUSTOMER: ", error);
        props.onUpdate(error);
      });
  };

  return (
    <>
      <Typography variant="body1">Enter the current mileage.</Typography>
      <Box style={{ flexDirection: "row", alignItems: "flex-end", display: "flex" }}>
        {mileageInputField()}
        <Typography variant="body1">{isMetric ? "kms" : "miles"}</Typography>
      </Box>
      <Box>
        <FormLabel>Metric?</FormLabel>
        <Checkbox
          name="metric"
          checked={isMetric}
          onChange={(e: React.ChangeEvent) => {
            setIsMetric(!isMetric);

          }}
        />
      </Box>
      <Button className={classes.submitButton} variant="contained" color="primary" onClick={() => handleUpdate({ mileage })}>
        Submit
      </Button>
    </>
  );
};

export default EditMileage;
