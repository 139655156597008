const  AUTHDATA = "authData";
const getAuthToken = () => {
  return JSON.parse(localStorage.getItem(AUTHDATA));
};

const setAuthToken = (token: any) => {
  localStorage.setItem(AUTHDATA, JSON.stringify(token));
};

const removeAuthToken = () => {
  localStorage.removeItem(AUTHDATA);
};
export default {
    getAuthToken,
    removeAuthToken,
    setAuthToken,
}