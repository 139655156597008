import Axios from "axios";
import { sign } from "jsonwebtoken";

const HOST = process.env.REACT_APP_POSTAL_CODE_API_ENDPOINT;
export const ENDPOINTS = {
    postalCodes: `${HOST}/postalcodes`,
    hubLocationPostalCodes: (hubLocationId: string, onlineDate: string) => `${HOST}/postalcodes?hubLocationId=${hubLocationId}&onlineDate=${onlineDate}`,
    postalCode: (code: string) => `${HOST}/postalcodes/${code}`,
};

const axiosConfig = (options: any) => {
    const method = options.method || "GET";
    const config = {
        data: options.data || null,
        headers: {
            "Content-Type": "application/json"
        },
        method: method,
        url: options.url
    };
    if (method !== "GET") {
        config.headers['Authorization'] = `Bearer ${sign({
            source: 'Rad Power Bikes - Portal'
        }, process.env.REACT_APP_POSTAL_CODE_API_JWT_SECRET)}`
    }
    return config;
};

export default class ApiService {
    public static async get(url: string): Promise<any> {
        const config = axiosConfig({
            method: "GET",
            url
        });
        const response = await Axios(config);
        return response;
    }

    public static async post(url: string, data?: any): Promise<any> {
        const config = axiosConfig({
            data,
            method: "POST",
            url,
        });
        const response = await Axios(config);
        return response;
    }

    public static async put(url: string, data?: any): Promise<any> {
        const config = axiosConfig({
            data,
            method: "PUT",
            url,
        });
        const response = await Axios(config);
        return response;
    }

    public static async delete(url: string): Promise<any> {
        const config = axiosConfig({
            method: "DELETE",
            url,
        });
        const response = await Axios(config);
        return response;
    }
}
