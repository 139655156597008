import React from "react";
import { cloneDeep } from "lodash";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import CustomerSearch from "containers/Bike/CustomerSearch";
import ApiService, { ENDPOINTS } from "utilities/api-service";

interface IEditCustomerProps {
  bike: any
  onUpdate: (error?: any) => unknown
  updateType: "edit" | "delete"
};

const useStyles = makeStyles(() => ({
  submitButton: {
    marginTop: "16px"
  }
}));

const EditCustomer = (props: IEditCustomerProps) => {
  const classes = useStyles(props);

  const handleUpdate = (data: any) => {
    const bikeData = cloneDeep(props.bike);

    if (data) {
      if ("shopify_customer_id" in data) { bikeData.shopify_customer_id = data.shopify_customer_id }
      if ("shopify_store" in data) { bikeData.shopify_store = data.shopify_store }
    }

    const note = () => {
      const oldShopifyCustomerId = props.bike.shopify_customer_id ? props.bike.shopify_customer_id : 'null';
      const newShopifyCustomerId = data.shopify_customer_id ? data.shopify_customer_id : 'null';
      const shopifyCustomerIdNote = `Customer Shopify ID updated from ${oldShopifyCustomerId} to ${newShopifyCustomerId}`;

      let shopifyCustomerStore = '';
      if (props.bike.shopify_store !== data.shopify_store) {
        shopifyCustomerStore = `\nCustomer Shopify store updated from ${props.bike.shopify_store} to ${data.shopify_store}`
      }

      const updateDate = new Date();
      const theNote = `${shopifyCustomerIdNote}${shopifyCustomerStore && shopifyCustomerStore}\n${updateDate}`;

      return theNote;
    }
    
    ApiService.patch(ENDPOINTS.bikesId(props.bike.id.toString()), bikeData)
      .then(() => {
        props.onUpdate();
        ApiService.post(
          ENDPOINTS.bikesIdNotes(props.bike.id.toString()),
          {note: note()}
        );
      })
      .catch(error => {
        console.warn("UPDATE BIKE CUSTOMER: ", error);
        props.onUpdate(error);
      });
  }

  if (props.updateType === "edit") {
    return (
      <CustomerSearch onUpdateData={data => handleUpdate(data)} />
    )
  } else if (props.updateType === "delete") {
    return (
      <>
        <Typography variant="body1">Are you sure you want to remove this customer from this bike?</Typography>
        <Button className={classes.submitButton} variant="contained" color="primary" onClick={() => handleUpdate({shopify_customer_id: null, shopify_store: null})}>
          Submit
        </Button>
      </>
    )
  } else {
    return null
  }
}

export default EditCustomer;
