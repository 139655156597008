import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'

import React from 'react'

interface IMapContainerProps {
  coordinates: ICoordinates[]
}

interface IMapContainerState {
  coordinates: ICoordinates[]
  isMounted: boolean
}

interface ICoordinates {
  lat: any,
  lng: any
}

class MapContainer extends React.Component<IMapContainerProps, IMapContainerState> {
  state: IMapContainerState = {
    coordinates: this.props.coordinates,
    isMounted: false
  }
  private theMap = React.createRef<GoogleMap>()

  componentDidMount() {
    if (this.props.coordinates.length > 0) {
      this.setState({ coordinates: this.props.coordinates, isMounted: true })
    }
  }

  componentDidUpdate(prevProps: IMapContainerProps) {
    if (this.props.coordinates !== prevProps.coordinates) {
      this.setState({ coordinates: this.props.coordinates, isMounted: false }, () => {
        this.onBoundsChanged()
      })
    }
  }

  onBoundsChanged = () => {
    this.setState({ isMounted: true})
  }
  
  render() {
    const { coordinates, isMounted } = this.state
    return (
      <>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_CLOUD_PLATFORM_API_KEY}
        >
          {isMounted &&
          <GoogleMap
            ref={this.theMap}
            mapContainerStyle={{
              height: "400px",
              width: "100%"
            }}            
            center={coordinates[0]}
            zoom={13}
            onLoad={map => {
              const bounds = new window.google.maps.LatLngBounds();
              coordinates.forEach((marker) => {
                if(marker){
                  bounds.extend(new window.google.maps.LatLng( marker.lat, marker.lng ))
                }
              })
              if (coordinates.length === 1) {
                map.setZoom(13)
                map.setCenter(coordinates[0])
              } else {
                map.fitBounds(bounds)
              }
            }}
          >
            <MarkerClusterer
              options={{imagePath:"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"}}
            >
              {
                (clusterer) => coordinates.map((location, i) => (
                  <Marker
                    key={i}
                    animation={google.maps.Animation.DROP}
                    position={location}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap>}
        </LoadScript>
      </>
    )
  }
}

export default MapContainer
