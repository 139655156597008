import React, { Component } from "react";
import withConnection, { IConnectedRoutedComponentProps, IStoreProps } from "../HOC/ConnectedRouted";

import CustomerAction from "../../stores/customers/CustomerAction";
import CustomerTimelineObject from "components/Timeline/CustomerTimelineObject";
import ICustomer from "stores/customers/models/ICustomer";
import IStore from "stores/IStore";
import { ITimeline } from "stores/timeline/models/Timeline";
import Loading from "components/Loading/Loading";
import Timeline from "components/Timeline/Timeline";
import queryString from 'query-string'

interface IBikeDetails {
  stateToProps: {
    selectedCustomer: ICustomer;
    timeline: ITimeline;
  };
}
const mapStateToProps = (state: IStore): IStoreProps => ({
  selectedCustomer: state.customer.selectedCustomer,
  timeline: state.customer.timeline
});

class CustomerDetails extends Component<
  IBikeDetails & IConnectedRoutedComponentProps
> {
  public useQuery= ()=> {
    const params = queryString.parse(this.props.routerProps.location.search);
    return params
  }
  public componentDidMount() {
    const customerId = this.props.routerProps.match.params["id"];
    
    const store = (this.useQuery().store || 'US')as string
    if (customerId) {
      this.props.dispatch(CustomerAction.getCustomer(customerId,store))
    }
  }
  public render() {
    // FIXME: - find a better way to select state from the HOC prop
    const customer: ICustomer = this.props.data["selectedCustomer"];
    const timeline: ITimeline = this.props.data["timeline"];
    if (customer) {
      return (
        <div>
          <CustomerTimelineObject data={customer} />
          {timeline ? (
            this.customerTimeline(timeline,customer)
          ) : (
            <Loading message={"Loading Timeline..."} isLoading={true} />
          )}
        </div>
      );
    }
    return <div>
      <Loading isLoading={true}/>
    </div>;
  }
  private customerTimeline(timeline: ITimeline,customer:ICustomer) {
    return (
      <div>
        <Timeline customer={customer} timeline={timeline} />
      </div>
    );
  }
}

const customerDetail = withConnection(CustomerDetails, mapStateToProps);
export default customerDetail;
