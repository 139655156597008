import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";

interface IAppModalProps {
  dialogTitle?: string;
  open: boolean;
  components?:any;
  cancel: () => void;  
  save?: () => void;
  submit?: () => void;
  submitText?: string;
  extraStyles?: React.CSSProperties;
  maxWidth?: false | "lg" | "xs" | "sm" | "md" | "xl"
  onEnter?: () => unknown
}

const useStyles = makeStyles(() => ({
  modalContent: {
    minHeight: 150,
    backgroundColor: '#f6f6f6'
  }
}));

const AppModal = (props: React.PropsWithChildren<IAppModalProps>) => {
  const classes = useStyles(props);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={props.maxWidth}
      open={props.open}
      keepMounted={true}
      onEnter={props.onEnter}
      onClose={props.cancel}
    >
      {props.dialogTitle && (
        <DialogTitle id="alert-dialog-slide-title">
          {props.dialogTitle}
        </DialogTitle>
      )}

      <DialogContent 
        style={props.extraStyles}
        className={classes.modalContent}
      >
        <div>{props.children}</div>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.cancel} color="primary">
          Cancel
        </Button>

        {props.save && (
          <Button onClick={props.save} color="primary">
            Assign
          </Button>
        )}

        {props.submit &&
          <Button onClick={props.submit} color="primary">
            { props.submitText ? props.submitText : "Submit" }
          </Button>
        }

        {props.components && props.components}
      </DialogActions>
    </Dialog>
  );
};

export default AppModal;

AppModal.defaultProps = {
  maxWidth: "md"
};
