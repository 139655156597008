import moment from 'moment';

export default class DateFormatter{
    public static defaultDateFormat(date:string):string{
        if(!date){
            return "Unassigned Date."
        }
        return moment(date).format('MMM DD, YYYY')
    }

    public static detailedDateFormat(date:string):string{
        return moment(date).format('MMM DD, hh:mm -  YYYY')
    }

    public static timeFormat(time:string):string{
        return moment(time, "HH:mm:ss").format("hh:mm A")
    }

    // placeholder for setting a date to write to the db
    public static dbFormatedDateForInsert(date:string):string{
        return moment(date).toISOString()
    }

    public static muiDateFormat(date:string):string{
        return moment(date).format('YYYY-MM-DD')
    }

    public static muiTimeFormat(date:string):string{
        return moment(date).format('hh:mm A')
    }

    public static startOfDay(dateString?:string):Date{
        const dte = dateString || new Date().toString()
        return moment(dte).startOf('day').toDate()
    }

    public static startOfDayFormatted(dateString?:string):string{
        const dte = dateString || new Date().toString()
        const start = this.startOfDay(dte).toString()
        return this.defaultDateFormat(start).toString()
    }
    
    public static timestamp(dateString?:string):number{
        const dte = dateString || new Date().toString()
        return moment(dte).unix()
    }

    public static timestampToDate(timestamp:number):string{        
        const stringed =  moment(timestamp).toDate().toString()
        return this.defaultDateFormat(stringed)
    }
}