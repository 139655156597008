import React from "react";
import { Dispatch } from "redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import AppWrapper from "components/AppWrapper/AppWrapper";
import BuiltAndDelivered from "components/BuiltAndDelivered";
import { Footer } from "components/Footer/Footer";
import Loading from "components/Loading/Loading";
import PostalCodes from "components/PostalCodes";
import Search from "components/Search";
import ServiceRequestForm from "components/ServiceRequest/ServiceRequestForm";
import ServiceRequests from "components/ServiceRequest";
import ServiceRequestChanges from "components/ServiceRequest/ServiceRequestTimeline";
import WarrantCodeGenerator from "containers/WarrantyCodeGenerator/WarrantyCodeGeneratory";
import BikeDetail from "containers/Bike/BikeDetails";
import CustomerCreate from "containers/Customer/Create";
import CustomerDetail from "containers/Customer/CustomerDetail";
import MasterCalendar from "containers/MasterCalendar/MasterCalendar";
import ServiceRequestDetails from "containers/ServiceRequest";
import SignIn from "containers/app/SignIn";
import NoteDetails from "containers/Notes/NoteDetails";
import RMSLocation from "containers/RMSLocation/RMSLocation";
import RMSLocationSettings from "containers/RMSLocation/RMSLocationSettings";
import RMSLocationPostalCodes from "containers/RMSLocation/RMSLocationPostalCodes";
import { IDispatchToProps } from "interfaces";
import IAction from "stores/IAction";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";
import IUser from "stores/user/models/IUser";
import UserAction from "stores/user/UserAction";
import theme from "theme/theme";

interface IState {}
interface IProps {}
interface IStateToProps {
  loadingUser: boolean;
  locations: IRMSLocation[];
  selectedLocation: IRMSLocation;
  user: IUser;
}

const mapStateToProps = (state: IStore): IStateToProps => ({
  loadingUser: state.user.loadingUser,
  locations: state.location.locations,
  selectedLocation: state.location.selectedLocation,
  user: state.user.userData
});

const mapDispatchToProps = (dispatch: Dispatch<IAction<any>>): IDispatchToProps => ({
  dispatch
});

interface IPrivateRoute {
  authenticated: boolean;
  component: any;
  exact: boolean;
  loading: boolean;
  path: string;
}
const PrivateRoute = ({ ...props }: IPrivateRoute) => {
  if (props.loading) {
    return null;
  }
  // return <Route exact={props.exact} path={props.path} component={props.component} />;
  return props.authenticated ? <Route exact={props.exact} path={props.path} component={props.component} /> : <Redirect to="/" />;
};

class App extends React.Component<IStateToProps & IDispatchToProps & IProps, IState> {
  public componentDidMount() {
    if (!this.props.user) {
      this.props.dispatch(UserAction.me());
    }
  }
  public render(): JSX.Element {
    const isAuthenticated = this.props.user ? true : false;    
    if (this.props.loadingUser) {
      return <Loading isLoading={this.props.loadingUser} />;
    }
    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppWrapper>
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "sm"
              }}
              maxWidth="lg">
              <Router>
                <Route exact={true} path={"/"} render={() => <SignIn user={this.props.user} />} />
                <Route exact={true} path={"/postal-codes"} component={PostalCodes} />
                <Switch>
                  <PrivateRoute exact={true} path={`/bikes/:id`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={BikeDetail} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path="/built-and-delivered" loading={this.props.loadingUser} authenticated={isAuthenticated} component={BuiltAndDelivered} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={"/customers/create"} loading={this.props.loadingUser} authenticated={isAuthenticated} component={CustomerCreate} />
                  <PrivateRoute exact={true} path={`/customers/:id`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={CustomerDetail} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path="/service-requests" loading={this.props.loadingUser} authenticated={isAuthenticated} component={ServiceRequests} />
                  <PrivateRoute exact={true} path="/service-requests/create" loading={this.props.loadingUser} authenticated={isAuthenticated} component={ServiceRequestForm} />
                  <PrivateRoute exact={true} path="/service-requests/:id/changes" loading={this.props.loadingUser} authenticated={isAuthenticated} component={ServiceRequestChanges} />
                  <PrivateRoute exact={true} path="/service-requests/:id" loading={this.props.loadingUser} authenticated={isAuthenticated} component={ServiceRequestDetails} />                  
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={`/calendar`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={MasterCalendar} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={`/warranty-code-generator`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={WarrantCodeGenerator} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={`/notes/:id`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={NoteDetails} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={`/locations/:id/settings`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={RMSLocationSettings} />
                  <PrivateRoute exact={true} path={`/locations/:id/postal-codes`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={RMSLocationPostalCodes} />
                  <PrivateRoute exact={false} path={`/locations/:id`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={RMSLocation} />
                </Switch>
                <Switch>
                  <PrivateRoute exact={true} path={`/search`} loading={this.props.loadingUser} authenticated={isAuthenticated} component={Search} />
                </Switch>
              </Router>
              <Footer />
            </Container>
          </AppWrapper>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
