import ApiService, { ENDPOINTS } from "../../utilities/api-service";
import RMSServiceRequestAction, { IStartEndDates } from "./RMSServiceRequestAction";
import { call, put } from "redux-saga/effects";

import { AxiosError } from "axios";
import IAction from "../IAction";
import IAudit from "stores/audits/models/IAudit";
import IRMSServiceRequest from "./models/IRMSServiceRequest";
import { IRPBError } from "stores/errors/models/ApplicationError";
import IStore from "stores/IStore";
import RMSLocationAction from '../rms-locations/RMSLocationAction';
import { get } from "lodash-es";
import moment from "moment";
import store from "store";

export default class RMSServiceRequestSaga {
  public static *getServiceRequest(action: IAction<void>) {
    try {
      const response = yield call(
        ApiService.get,
        ENDPOINTS.serviceRequestsId(action.payload)
      );
      const serviceRequest: IRMSServiceRequest = get(response, "data");
      yield put(
        RMSServiceRequestAction.getServiceRequestSuccess(serviceRequest)
      );
    } catch (error) {
      yield put(RMSServiceRequestAction.error(error));
    }
  }

  public static *getServiceRequestAudits(action: IAction<void>) {
    try {
      const response = yield call(
        ApiService.get,
        ENDPOINTS.serviceRequestsIdAudits(action.payload)
      );
      const serviceRequestAudits: IAudit[] = get(response, "data");
      yield put(
        RMSServiceRequestAction.getServiceRequestAuditsSuccess(serviceRequestAudits)
      );
    } catch (error) {
      yield put(RMSServiceRequestAction.error(error));
    }
  }

  public static *getServiceRequests(action: IAction<void>) {
    const date = action.payload as IStartEndDates;
    const perPage = action.payload.perPage ? action.payload.perPage : 30;
    try {
      const response = yield call(
        ApiService.get,
        `${ENDPOINTS.serviceRequests}?start=${date.startTimeStamp}&end=${date.endTimeStamp}&per_page=${perPage}`
      );
      const serviceRequests: IRMSServiceRequest[] = get(response, "data.results");
      yield put(
        RMSServiceRequestAction.setServiceRequestsForCalendar(serviceRequests)
      );
    } catch (error) {
      yield put(RMSServiceRequestAction.error(error));
    }
  }

  public static *updateServiceRequest(action: IAction<void>) {
    
    const serviceRequest: IRMSServiceRequest = action.payload
    try {
      yield put(RMSServiceRequestAction.error(null));  
      const response = yield call(
        ApiService.patch,
        ENDPOINTS.serviceRequestsId(serviceRequest.id.toString()),action.payload
      );
      const serviceRequestUpdated: IRMSServiceRequest = get(response, "data");      
      yield put(
        RMSServiceRequestAction.getServiceRequestSuccess(serviceRequestUpdated)
      );
      yield put(RMSServiceRequestAction.updateServiceRequestStatus("success"))
    } catch (error) {
      const aError = error as AxiosError
      const appError:IRPBError = {
        errorMessage: aError.response.data.error,
        errorObject: aError.response.data,
      }
      
      yield put(RMSServiceRequestAction.error({error:appError}));
      yield put(RMSServiceRequestAction.updateServiceRequestStatus("error"))
      yield put(RMSServiceRequestAction.getServiceRequest(`${serviceRequest.id}`))      
    }
  }

  public static *toggleApproveServiceRequest(action: IAction<void>){
    try {
      const storeState: IStore = store.getState();
      const serviceRequest: IRMSServiceRequest = action.payload;      
      // delete serviceRequest.is_approved
      // serviceRequest.approved_by_user_id = parseInt(storeState.user.userData.id,10)
      // serviceRequest.approved_at = moment().toISOString()
      const response = yield call(
        ApiService.patch,
        ENDPOINTS.serviceRequestsId(serviceRequest.id.toString()),
        {
          id:serviceRequest.id,
          approved_by_user_id:parseInt(storeState.user.userData.id,10),
          approved_at : moment().toISOString()
        }
      );
      const serviceRequestUpdated: IRMSServiceRequest = get(response, "data");      
      yield put(
        RMSServiceRequestAction.getServiceRequestSuccess(serviceRequestUpdated)
      );
      yield put(RMSServiceRequestAction.updateServiceRequestStatus("success"))
      yield put(RMSLocationAction.getLocation(serviceRequest.service_location_id.toString()))
    } catch (error) {
      yield put(RMSServiceRequestAction.error(error));
      yield put(RMSServiceRequestAction.updateServiceRequestStatus("error"))
    }
  }
}
