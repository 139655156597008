import CustomerDetail from './CustomerDetail';
import ICustomer from "../../stores/customers/models/ICustomer";
import React from "react";

export interface ICustomersList {
  customers: ICustomer[];
}
const CustomersList = (props: ICustomersList) => {
  console.log(props.customers);

  return (
    <div>
      {props.customers.map(customer => (
        <div key={customer.id}>
          <CustomerDetail customer={customer}/>
        </div>
      ))}
    </div>
  );
};
export default CustomersList;
