import * as React from "react";

import { Button, Card, CardContent, CardHeader, Typography } from "@material-ui/core";

import IZendesk from "stores/zendesks/models/IZendesk";
import Linkify from 'react-linkify';
import Loading from "../Loading/Loading";

export interface IZendeskTimelineObjectProps {
  data:IZendesk
}

const ZendeskTimelineObject =(props: IZendeskTimelineObjectProps) => {
  const { data } = props;

  if (!data) {
    return <Loading isLoading={true} />;
  }
  if(!data.description && !data.subject){
    return null
  }
  // this url can show json representation if changed to `https://radpowerbikes.zendesk.com/api/v2/tickets`
  const zendDeskBaseUrl = "https://radpowerbikes.zendesk.com/agent/tickets"
  return (
    <>
      <Typography variant="caption" component="h2">
        <Button target="_blank" href={`${zendDeskBaseUrl}/${data.id}`}>
          ZenDesk {data.id}
        </Button>
      </Typography>
      <Card>
        <CardHeader subheader={data.subject} />
        <CardContent>
          <Linkify>
            {data.description}
          </Linkify>
        </CardContent>
      </Card>
    </>
  );
}

export default ZendeskTimelineObject
