import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Radio from '@material-ui/core/Radio';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

export interface ISectionContactInfoProp {
  availableCustomers: any;
  handleChange: (flag: boolean, contactInfo: IContactInfo) => void;
  sameName: boolean;
  customerId: string;
}

interface IContactInfo {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  summary: {
    display: "block",
  }
}));

const SectionContactInfo = (props: ISectionContactInfoProp) => {
  const classes = useStyles(props);
  const [customerInfoExpanded, setCustomerInfoExpanded] = useState(true);
  const [custId, setCustId] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState({ id: "", firstName: "", lastName: "", email: "", phone: "" });

  useEffect(() => {
    if (props.customerId !== custId) {
      setCustId(props.customerId)
    }
    if (!props.customerId) {
      setSelectedCustomer({ id: "", firstName: "", lastName: "", email: "", phone: "" });
    }
  }, [props.customerId, custId]);

  const ShowAvailableCustomers = () => {
    const toggleustomerInfoExpande = () => {
      const status = customerInfoExpanded ? false : true;
      setCustomerInfoExpanded(status);
    }

    return (
      <ExpansionPanel className={classes.root} expanded={customerInfoExpanded} onChange={toggleustomerInfoExpande}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Box className={classes.summary}>
            <Typography variant="subtitle1">Customers</Typography>
            <Typography variant="caption">Use one of the following customers as the contact for this request.</Typography>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container={true} alignItems="flex-start" spacing={2}>
            {
              Object.entries(props.availableCustomers).map(obj => {
                const id = obj[1]["id"];
                const firstName = obj[1]["first_name"] ? obj[1]["first_name"] : "";
                const nameDivider = obj[1]["first_name"] && obj[1]["last_name"] ? " " : "";
                const lastName = obj[1]["last_name"] ? obj[1]["last_name"] : "";
                const fullName = firstName + nameDivider + lastName;
                const email = obj[1]["email"] ? obj[1]["email"] : "";
                const phone = obj[1]["phone"] ? obj[1]["phone"] : "";

                return (
                  <Grid key={obj[0]} item={true}>
                    <Grid container={true} alignItems="flex-start" spacing={1}>
                      <Grid item={true}>
                        <Radio
                          checked={selectedCustomer && selectedCustomer.id === id}
                          onClick={(e) => {
                            if (selectedCustomer && selectedCustomer.id === id) {
                              const c = { id: "", firstName: "", lastName: "", email: "", phone: "" }
                              setSelectedCustomer(c);
                              props.handleChange(false, c);
                            } else {
                              const c = { id: id, firstName: firstName, lastName: lastName, email: email, phone: phone }
                              setSelectedCustomer(c);
                              props.handleChange(true, c);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item={true}>
                        {fullName && <div><Typography variant="caption">{fullName}</Typography></div>}
                        {email && <div><Typography variant="caption">{email}</Typography></div>}
                        {phone && <div><Typography variant="caption">{phone}</Typography></div>}
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Who
      </Typography>
      <Typography variant="body2">
        The primary contact for the request.
      </Typography>

      {Object.entries(props.availableCustomers).length > 0 && <ShowAvailableCustomers />}

      <Grid container={true} alignItems="flex-start" spacing={2}>
        <Grid item={true} xs={6}>
          <Field name={custId && custId === props.customerId ? "customer.firstname" : "contact.firstname"} fullWidth={true} required={true} component={TextField} type="text" label="First name" />
        </Grid>
        <Grid item={true} xs={6}>
          <Field name={custId && custId === props.customerId ? "customer.lastname" : "contact.lastname"} fullWidth={true} required={true} component={TextField} type="text" label="Last Name" />
        </Grid>
        <Grid item={true} xs={6}>
          <Field name={custId && custId === props.customerId ? "customer.email" : "contact.email"} fullWidth={true} required={true} component={TextField} type="email" label="Email" />
        </Grid>
        <Grid item={true} xs={6}>
          <Field name={custId && custId === props.customerId ? "customer.phone" : "contact.phone"} fullWidth={true} required={true} component={TextField} type="phone" label="Phone" />
        </Grid>
      </Grid>
    </>
  );
};

export default SectionContactInfo;
