import ApiService, { ENDPOINTS } from "utilities/api-service";
import { call, put } from "redux-saga/effects";

import IAction from "../IAction";
import { IOrder } from "./models/IOrder";
import OrderAction from "./OrderAction";
import { get } from "lodash-es";

export default class OrderSaga {
  public static *getOrder(action: IAction<void>) {
    try {
      const orderResponse = yield call(ApiService.get,ENDPOINTS.orderId(action.payload))
      const order:IOrder = get(orderResponse,'data')
      yield put(OrderAction.setOrder(order))
    } catch (error) {
      yield put(OrderAction.error(error));
    }
  }
  
}
