import IAction from "../IAction";
export default class SearchAction {
  public static readonly SEARCH: string = "SearchAction.SEARCH";
  public static readonly IS_SEARCHING: string = "SearchAction.IS_SEARCHING";  
  public static readonly SEARCH_RESULTS: string = "SearchAction.SEARCH_RESULTS";
  public static readonly ERROR: string = "SearchAction.ERROR";
  public static search(searchCriteria: any): IAction<string> {
    return {
      payload: searchCriteria,
      type: SearchAction.SEARCH
    };
  }
  public static setIsSearching(flag:boolean): IAction<string>{
    return {
      payload: flag,
      type: SearchAction.IS_SEARCHING
    };
  }
  public static searchResults(results: any): IAction<string> {
    return {
      payload: results,
      type: SearchAction.SEARCH_RESULTS
    };
  }

  public static error(error: Error): IAction<string> {
    return {
      payload: error,
      type: SearchAction.ERROR
    };
  }
}
