import IAction from "../IAction";
import IRMSLocation from "./models/IRMSLocation";
import IRMSServiceRequest from "../rms-service-requests/models/IRMSServiceRequest";

export default class RMSLocationAction {
  public static readonly ADD_LOCATIONS: string = "RMSLocationAction.ADD_LOCATIONS";
  public static readonly ERROR: string = "RMSLocationAction.ERROR";
  public static readonly GET_LOCATION: string = "RMSLocationAction.GET_LOCATION";
  public static readonly GET_LOCATIONS: string = "RMSLocationAction.GET_LOCATIONS";
  public static readonly SELECTED_LOCATION: string = "RMSLocationAction.SELECTED_LOCATION";
  public static readonly SET_EXPECTED_START_TIME: string = "RMSLocationAction.SET_EXPECTED_START_TIME";
  public static readonly SET_SERVICE_REQUESTS_IN_CONTEXT: string = "RMSLocationAction.SET_SERVICE_REQUESTS_IN_CONTEXT";
  public static getLocations(): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: null,
      type: RMSLocationAction.GET_LOCATIONS
    };
  }

  public static getLocation(locationId: string): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: locationId,
      type: RMSLocationAction.GET_LOCATION
    };
  }
  public static setExpectedStartTime(timestamp: number): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: timestamp,
      type: RMSLocationAction.SET_EXPECTED_START_TIME
    };
  }

  public static selectedLocation(location: IRMSLocation | null): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: location,
      type: RMSLocationAction.SELECTED_LOCATION
    };
  }
  public static setServiceRequestsInContext(serviceRequests: IRMSServiceRequest[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: serviceRequests,
      type: RMSLocationAction.SET_SERVICE_REQUESTS_IN_CONTEXT
    };
  }
  public static addLocations(locations: IRMSLocation[]): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: locations,
      type: RMSLocationAction.ADD_LOCATIONS
    };
  }

  public static error(error: Error): IAction<string> {
    return {
      payload: error,
      type: RMSLocationAction.ERROR
    };
  }
}
