import React, {MutableRefObject} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import PersonIcon from "@material-ui/icons/Person";

import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IServiceRequestTask from "stores/tasks/models/ITask";
import DateFormatter from "utilities/date-formatter";
import ServiceRequestHelper from "utilities/service-request-helper";

interface IServiceRequestDetailsPrintProps extends WithStyles<typeof useStyles> {
  ref: MutableRefObject<undefined>;
  serviceRequest: IRMSServiceRequest;
  bikeTasks: any;
}

const useStyles = () => createStyles({
  root: {
    margin: "16px",
  },
  h1: {
    color: "#000",
    fontSize: "36px",
    fontWeight: "bold"
  },
  h2: {
    color: "#000",
    fontSize: "32px",
    fontWeight: "bold"
  },
  h3: {
    color: "#000",
    fontSize: "24px",
    fontWeight: "bold"
  },
  label: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "bold"
  },
  body: {
    color: "#000",
    fontSize: "16px"
  },
  status: {
    color: "#000",
    fontSize: "16px",
    textTransform: "capitalize"
  },
  notesBox: {
    borderBottom: "solid 1px #000",
    marginBottom: "16px",
    paddingBottom: "16px",
    pageBreakInside: "avoid"
  },
  divider: {
    color: "#000",
    margin: "16px 0"
  },
  bikeBox: {
    marginBottom: "24px",
  },
  bikeButton: {
    marginLeft: "-8px",
  },
  bikeDataContainer: {
    marginLeft: "24px"
  },
  bikeData: {
    pageBreakInside: "avoid"
  },
  customerData: {
    pageBreakInside: "avoid"
  }
});

class ServiceRequestDetailsPrint extends React.Component<IServiceRequestDetailsPrintProps> {
  render() {
    const { bikeTasks, classes, serviceRequest } = this.props;

    const assigneeName =
      serviceRequest.assignee && serviceRequest.assignee.firstname && serviceRequest.assignee.lastname ? serviceRequest.assignee.firstname + " " + serviceRequest.assignee.lastname : "Unassigned";

    return (
      <Box className={classes.root}>
        <Typography className={classes.h1}>
          Service Request {serviceRequest.id}
        </Typography>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Service Location: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.location && serviceRequest.location.name && (serviceRequest.location.name)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Status: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.status}>
              {serviceRequest.status && serviceRequest.status}
            </Typography>
          </Grid>
        </Grid>

        <Typography className={classes.h2}>
          Contact Info
        </Typography>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Location Name: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.contact && serviceRequest.contact.alias && (serviceRequest.contact.alias)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Name: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.contact && serviceRequest.contact.firstname ? serviceRequest.contact.firstname : ""}
              {serviceRequest.contact && serviceRequest.contact.firstname && serviceRequest.contact.lastname ? " " : ""}
              {serviceRequest.contact && serviceRequest.contact.lastname ? serviceRequest.contact.lastname : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Email: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.contact && serviceRequest.contact.email && serviceRequest.contact.email}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Phone: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.contact && serviceRequest.contact.phone && serviceRequest.contact.phone}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Address: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.contact && serviceRequest.contact.address1 &&<>{ serviceRequest.contact.address1 }<br /></>}
              {serviceRequest.contact && serviceRequest.contact.address2 && <>{ serviceRequest.contact.address2 }<br /></>}
              {serviceRequest.contact && serviceRequest.contact.locality && serviceRequest.contact.locality}
              {serviceRequest.contact && (serviceRequest.contact.locality && (serviceRequest.contact.region || serviceRequest.contact.postcode)) && ", "}
              {serviceRequest.contact && serviceRequest.contact.region && serviceRequest.contact.region}
              {serviceRequest.contact && (serviceRequest.contact.region && (serviceRequest.contact.postcode)) && ", "}
              {serviceRequest.contact && serviceRequest.contact.postcode && serviceRequest.contact.postcode}
              {serviceRequest.contact && (serviceRequest.contact.locality || serviceRequest.contact.region || serviceRequest.contact.postcode) && <br />}
              {serviceRequest.contact && serviceRequest.contact.country && serviceRequest.contact.country}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Date: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.expected_start_time && DateFormatter.defaultDateFormat(serviceRequest.expected_start_time)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Time: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.scheduled_time && DateFormatter.timeFormat(serviceRequest.scheduled_time)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Assignee: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {assigneeName && assigneeName}
            </Typography>
          </Grid>
        </Grid>

        {serviceRequest.notes &&
        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.label}>Description: </Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Typography className={classes.body}>
              {serviceRequest.notes}
            </Typography>
          </Grid>
        </Grid>}

        {serviceRequest.otherNotes && (
          <>
            <Typography className={classes.h2}>
              Other Notes
            </Typography>

            {serviceRequest.otherNotes.map((otherNote: any) => (
              <Box className={classes.notesBox} key={otherNote.id}  >
                <Typography gutterBottom={true} className={classes.label}>
                  {DateFormatter.defaultDateFormat(otherNote.created_at)}
                </Typography>
                <Typography className={classes.body}>
                  {otherNote.note}
                </Typography>
              </Box>
            ))}
          </>
        )}

        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography className={classes.h2}>Tasks</Typography>
          </Grid>
        </Grid>


        {bikeTasks &&
        Object.entries(bikeTasks).map((bike, idx) => {
          const _bike = ServiceRequestHelper.bikeForTask(serviceRequest, bike[1][0])
          const bikeSerialNumber = _bike ? _bike.serial_number : "";

          return (
            <Box key={`bsn_${idx}`} className={classes.bikeBox}>
              <Grid container={true} direction="row" spacing={1}>
                {bikeSerialNumber && bikeSerialNumber !== "UNKNOWN" ? (
                  <Grid item={true} sm={6}>
                    <>
                      <Typography className={classes.h3}>
                        <DirectionsBikeIcon />
                        &nbsp;{_bike.serial_number}
                      </Typography>

                      <Box className={classes.bikeDataContainer}>
                        <Box className={classes.bikeData}>
                          <Typography variant={"caption"}>
                            Model Data: {_bike.model} - {_bike.manufacturer_description} (version:{_bike.version || "unknown"}, factory: {_bike.factory} )
                          </Typography>
                          <Typography variant={"caption"}>
                            {_bike.month_manufactured} {_bike.year_model}
                            {_bike.manufacturer_model}
                            {_bike.manufacturer_sku && <div>Manufacturer SKU: {_bike.manufacturer_sku}</div>}
                            {_bike.serial_number_motor && <div>Motor SN: {_bike.serial_number_motor}</div>}
                            {_bike.serial_number_battery && <div>Battery SN: {_bike.serial_number_battery}</div>}
                          </Typography>
                        </Box>
                        {_bike.customer &&
                          <Box className={classes.customerData}>
                            <Grid container={true}>
                              <Grid item={true} xs={12}>
                                <Typography variant={"caption"} style={{ display: "flex", alignItems: "center" }}>
                                  <PersonIcon />
                                  &nbsp;{_bike.customer.shopifyData.first_name} {_bike.customer.shopifyData.last_name}
                                </Typography>
                              </Grid>
                              <Grid item={true} xs={12}>
                                <Typography variant="caption">{_bike.customer.shopifyData.email && `Email: ${_bike.customer.shopifyData.email}`}</Typography>
                              </Grid>
                              <Grid item={true} xs={12}>
                                <Typography variant="caption">{_bike.customer.shopifyData.phone && `Phone: ${_bike.customer.shopifyData.phone}`}</Typography>
                              </Grid>
                            </Grid>
                      
                            {_bike.customer.shopifyData.addresses.map((addy: any) => (
                              <div key={addy.id}>
                                <Typography variant="caption">
                                  {addy.address1}
                                  {addy.address2 && `, ${addy.address2}`}
                                  {addy.state && `, ${addy.state}, `}
                                  {addy.province && `, ${addy.province}, `}
                                  {addy.zip}&nbsp;{addy.country}
                                </Typography>
                              </div>
                            ))}
                          </Box>
                        }
                      </Box>
                    </>
                  </Grid>
                ) : (
                  <Grid item={true} sm={6}>
                    <Typography className={classes.h3}><DirectionsBikeIcon /> {bikeSerialNumber}</Typography>
                  </Grid>
                )}
              </Grid>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Task</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Gravity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bike[1] instanceof Array && (bike[1].map((task: IServiceRequestTask) => {
                    const gravity = task.true_gravity ? task.true_gravity : task.task.gravity;
                    return (
                      <TableRow key={task.id}>
                        <TableCell>
                          {task.task && task.task.name && (task.task.name)}
                        </TableCell>
                        <TableCell>{task.status && task.status}</TableCell>
                        <TableCell>{task.notes && task.notes}</TableCell>
                        <TableCell>{gravity}</TableCell>
                      </TableRow>
                    )
                  }))}
                </TableBody>
              </Table>
            </Box>
          );
        })}
      </Box>
    );
  }
};

export default withStyles(useStyles)(ServiceRequestDetailsPrint);
