import { COLORS } from '../../../theme/theme';
import IBike from "stores/bikes/models/IBike";
import IContact from 'stores/contact/models/IContact';
import ICustomer from "stores/customers/models/ICustomer";
import INote from "stores/notes/models/INote";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IServiceRequestTask from "stores/tasks/models/ITask";
import IUser from "stores/user/models/IUser";

export const styleForStatus = (sr: IRMSServiceRequest) => {
  let bgColor = null;

  switch (sr.status) {
    case "deferred":
      bgColor = COLORS.CALENDAR.DEFERRED;
      break;
    case "canceled":
      bgColor = COLORS.CALENDAR.CANCELED;
      break;
    case "created":
      bgColor = COLORS.CALENDAR.CREATED;
      break;
    case "completed":
      bgColor = COLORS.CALENDAR.COMPLETE;
      break;
    case "ready":
      bgColor = COLORS.CALENDAR.READY;
      break;
    case "past-due":
      bgColor = COLORS.CALENDAR.PAST_DUE;
      break;
    default:
      break;
  }
  return { backgroundColor: bgColor };
};

export const RPBServiceRequestStatus = {
  CANCELED: "canceled",
  COMPLETE: "completed",
  CREATED: "created",
  DEFERRED: "deferred",
  PAST_DUE: "past-due",
  READY: "ready",
};
export interface IRPBEmployee{
  email:string;
  firstname:string;
  lastname:string;
  id:number
}
export default interface IRMSServiceRequest {  
  approved_at:string;
  approved_by_user_id:number;  
  assigned_user_id: number;
  assignee:IUser;    
  bikes:IBike[];
  cancelation_reason?:string;  
  contact:IContact;
  customer_id:number;
  customer:ICustomer
  expected_start_time:string;  
  id: number;
  is_approved:boolean;  
  location: IRMSLocation;
  notes: string;
  otherNotes:INote[]  
  scheduled_time:string;
  service_location_id:number;  
  status: string;
  createdBy:IRPBEmployee;
  tasks: IServiceRequestTask[];
  created_at?: Date;
}
