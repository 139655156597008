import ApplicationError from "stores/errors/models/ApplicationError";
import { Dictionary } from "lodash";
import IAction from "../IAction";
import IAudit from "stores/audits/models/IAudit";
import IRMSServiceRequest from "./models/IRMSServiceRequest";
import RMSServiceRequestAction from "./RMSServiceRequestAction";
import { SnackbarVariant } from "components/Snackbar";
import UserAction from "../user/UserAction";

export interface IRMSServiceRequestState {
  error?: ApplicationError;
  audits: IAudit[];
  masterCalendarServiceRequests: IRMSServiceRequest[];
  orderedServiceRequests: Dictionary<IRMSServiceRequest[]>;
  priorityServiceRequests: IRMSServiceRequest[];
  selectedServiceRequest: IRMSServiceRequest;
  serviceRequests: IRMSServiceRequest[];
  unapprovedServiceRequests: IRMSServiceRequest[];
  updating: SnackbarVariant | null;
  updatingCalendar: boolean;
}

const initState = (): IRMSServiceRequestState => ({
  audits: null,
  masterCalendarServiceRequests: null,
  orderedServiceRequests: null,
  priorityServiceRequests: null,
  selectedServiceRequest: null,
  serviceRequests: null,
  unapprovedServiceRequests: null,
  updating: null,
  updatingCalendar: false
});
export default class RMSServiceRequestReducer {
  public static reducer(state: IRMSServiceRequestState = initState(), action: IAction<any>): IRMSServiceRequestState {
    state.updating = null;

    switch (action.type) {
      case UserAction.LOGOUT_COMPLETE:
        return initState();
      case RMSServiceRequestAction.SET_SERVICE_REQUESTS:
        return { ...state, serviceRequests: action.payload };
      case RMSServiceRequestAction.SET_PRIORITY_SERVICE_REQUESTS:
        return { ...state, priorityServiceRequests: action.payload };
      case RMSServiceRequestAction.SET_UNAPPROVED_SERVICE_REQUESTS:
        return { ...state, unapprovedServiceRequests: action.payload };
      case RMSServiceRequestAction.SET_SERVICE_REQUESTS_ORDERED:
        return { ...state, orderedServiceRequests: action.payload };
      case RMSServiceRequestAction.GET_SERVICE_REQUEST_SUCCESS:
        return { ...state, selectedServiceRequest: action.payload };
      case RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS_SUCCESS:
        return { ...state, audits: action.payload };
      case RMSServiceRequestAction.GET_SERVICE_REQUESTS:
        return { ...state, updatingCalendar: true, masterCalendarServiceRequests: [] };
      case RMSServiceRequestAction.ERROR:
        return { ...state, error: action.payload };
      case RMSServiceRequestAction.SET_SERVICE_REQUESTS_FOR_CALENDAR:
        return { ...state, updatingCalendar: false, masterCalendarServiceRequests: action.payload };
      case RMSServiceRequestAction.UPDATE_SUCCESS:
        return { ...state, updating: action.payload };
      default:
        return state;
    }
  }
}
