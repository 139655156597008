import IAction from '../IAction';
import { IGoogleResponse } from 'utilities/api-service';
import IUser from './models/IUser';

export default class UserAction {
    public static readonly ME: string = 'UserAction.ME';
    public static readonly IMPERSONATE: string = 'UserAction.IMPERSONATE';
    public static readonly LOGOUT: string = 'UserAction.LOGOUT';
    public static readonly LOGOUT_COMPLETE: string = 'UserAction.LOGOUT_COMPLETE';
    public static readonly REGISTER_TOKEN: string = 'UserAction.REGISTER_TOKEN';
    public static readonly UPDATE_TOKEN: string = 'UserAction.UPDATE_TOKEN';
    public static readonly REGISTER_USER_DATA: string = 'UserAction.REGISTER_USER_DATA';
    public static readonly REMOVE_TOKEN: string = 'UserAction.REMOVE_TOKEN';
    public static readonly ERROR: string = 'UserAction.ERROR';
    

    public static me(): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: null,
            type: UserAction.ME
        };
    }
    public static impersonate(token: IGoogleResponse): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: token,
            type: UserAction.IMPERSONATE
        };
    }

    public static logout(): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: null,
            type: UserAction.LOGOUT
        };
    }
    public static logoutComplete(): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: null,
            type: UserAction.LOGOUT_COMPLETE
        };
    }
    
    public static registerToken(token: IGoogleResponse): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: token,
            type: UserAction.REGISTER_TOKEN,
        };
    }

    public static updateTokenData(token: IGoogleResponse): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: token,
            type: UserAction.UPDATE_TOKEN,
        };
    }

    public static registerUserData(userData: IUser): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: userData,
            type: UserAction.REGISTER_USER_DATA,
        };
    }

    public static removeToken(): IAction<string> {
        return {
            payload: null,
            type: UserAction.REMOVE_TOKEN,
        };
    }
    public static error(error:Error): IAction<string> {
        return {
            payload: error,
            type: UserAction.ERROR,
        };
    }

    
}
