import * as React from "react";

import BikeDetail from "components/Bike/BikeDetail";
import { Box } from "@material-ui/core";
import IAudit from "stores/audits/models/IAudit";
import IBike from "stores/bikes/models/IBike";
import Loading from "../Loading/Loading";
import Typography from "@material-ui/core/Typography";

export interface IAuditTimelineObjectProps {
  data: IAudit;
}

// const useStyles = makeStyles(() => ({
//   note: {
//     whiteSpace: 'pre-line'
//   }
// }));

const AuditTimelineObject = (props: IAuditTimelineObjectProps) => {
  //   const classes = useStyles(props);
  const { data } = props;

  if (!data) {
    return <Loading isLoading={true} />;
  }

  return (
    <>
      <Typography variant="button">Audit</Typography>
      <br />
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="subtitle1" color="secondary">Action:</Typography>&nbsp;
        <Typography color="primary" variant="subtitle1">
          {data.action}
        </Typography>
      </Box>
      <Typography variant="caption">From:</Typography>
      <BikeDetail bike={data.old_state as IBike} />
      <Typography variant="caption">To:</Typography>
      <BikeDetail bike={data.new_state as IBike} />
    </>
  );
};
export default AuditTimelineObject;
