import IAction from "../IAction";
import IUser from "./models/IUser";
import UserAction from "./UserAction";

export interface IUserState {
  error: Error;
  tokenData?: any;
  loadingUser:boolean;
  userData?: IUser;
}
const initState = (loading:boolean = true): IUserState => ({
  error: null,
  tokenData: null,
  loadingUser:loading,
  userData: null
});
export default class UserReducer {
  public static reducer(
    state: IUserState = initState(),
    action: IAction<any>
  ): IUserState {
    switch (action.type) {
      case UserAction.ME:
        return {...state,loadingUser:true}
      case UserAction.LOGOUT_COMPLETE:
        return initState(false);
      case UserAction.REGISTER_TOKEN:
      case UserAction.UPDATE_TOKEN:
        return { ...state, tokenData: action.payload };
      case UserAction.REGISTER_USER_DATA:
        return { ...state, userData: action.payload ,loadingUser:false};
      case UserAction.IMPERSONATE:
        return { ...state, userData: null };
      case UserAction.REMOVE_TOKEN:
        return { ...state, tokenData: action.payload };
      case UserAction.ERROR:
        return { ...state, error: action.payload , loadingUser:false};
      default:
        return state;
    }
  }
}
