import * as React from "react";

import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import DateFormatter from "utilities/date-formatter";
import IAudit from "stores/audits/models/IAudit";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IStore from "stores/IStore";
import RMSServiceRequestAction from "stores/rms-service-requests/RMSServiceRequestAction";
import { RouteComponentProps } from "react-router-dom";
import ServiceRequestTimelineObject from "components/Timeline/ServiceRequestTimelineObject";

export interface IServiceRequestChangesProps {}
const ServiceRequestChanges = (props: IServiceRequestChangesProps & RouteComponentProps) => {
  const audits = useSelector((state: IStore) => state.serviceRequest.audits);
  
  const dispatch = useDispatch();
  const getParam = React.useCallback(
    (name: string) => {
      return props.match.params[name];
    },
    [props.match.params]
  );

  React.useEffect(() => {
    const serviceRequestId = getParam("id");
    if (serviceRequestId) {
      dispatch(RMSServiceRequestAction.getServiceRequest(serviceRequestId));
      dispatch(RMSServiceRequestAction.getServiceRequestAudits(serviceRequestId));
    }
  }, [dispatch, getParam]);
  const allAudits = () => {
    if (audits) {
      return audits.map((a: IAudit) => {
        const old = JSON.parse(a.old_state);
        const nw = JSON.parse(a.new_state);
        return (
          <Grid style={{marginTop:10}} key={a.id} container={true}>
            <Grid item={true} xs={12}>
              <Divider/>
              <Typography color="primary" variant="subtitle1">
                {DateFormatter.detailedDateFormat(a.created_at)}
              </Typography>
            </Grid>            
            <Grid item={true} xs={6}>
              <Typography color="primary" variant="subtitle1">
                Is:
              </Typography>
              <ServiceRequestTimelineObject orignalState={old} data={nw as IRMSServiceRequest} />
            </Grid>
          </Grid>
        );
      });
    }
    return null;
  };
  return <Box>
    {allAudits()}
    </Box>;

  
};
export default ServiceRequestChanges;
