import React, { Component } from "react";
import withConnection, { IConnectedRoutedComponentProps, IStoreProps } from "../HOC/ConnectedRouted";

import { Grid } from "@material-ui/core";
import IContact from "../../stores/contact/models/IContact";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import MapContainer from "components/Map";
import RMSLocationAction from "../../stores/rms-locations/RMSLocationAction";

interface IRMSLocationSettingsProps {
  stateToProps: {
    selectedLocaio: IRMSLocation;
  };
}
class RMSLocationSettings extends Component<IRMSLocationSettingsProps & IConnectedRoutedComponentProps> {
  public componentDidMount() {
    const objId = this.props.routerProps.match.params["id"];
    if (objId) {
      this.props.dispatch(RMSLocationAction.getLocation(objId));
    }
  }
  public render() {
    const selectedLocation: IRMSLocation = this.props.data["selectedLocation"];

    if (selectedLocation) {
      const contact: IContact = selectedLocation.contact;
      return (
        <div>
          <Grid container={true}>
            {selectedLocation.contact && (
              <>
                <Grid item={true} xs={12} sm={6}>
                  <div>
                    <span>
                      {contact.address1}
                      <br />
                    </span>
                    {contact.address2 && (
                      <span>
                        {contact.address2}
                        <br />
                      </span>
                    )}
                    {contact.locality},{contact.region}
                    <br />
                    {contact.country}
                  </div>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <MapContainer
                    coordinates={[
                      {
                        lat: contact.latitude,
                        lng: contact.longitude
                      }
                    ]}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      );
    }
    return <Loading isLoading={true} />;
  }
}
const mapStateToProps = (state: IStore): IStoreProps => ({
  selectedLocation: state.location.selectedLocation
});
const details = withConnection(RMSLocationSettings, mapStateToProps);
export default details;
