import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Typography } from "@material-ui/core";
export interface IIconFlagProps {
  store: string;
}
const eu = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.001 512.001">
    <path
      style={{ fill: "#41479B" }}
      d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828  h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z"
    />
    <g>
      <path
        style={{ fill: "#FFE15A" }}
        d="M259.59,126.686l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C257.187,125.312,259.132,125.312,259.59,126.686z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M259.59,354.548l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C257.187,353.174,259.132,353.174,259.59,354.548z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M373.521,240.617l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C371.118,239.243,373.063,239.243,373.521,240.617z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M145.658,240.617l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C143.256,239.243,145.201,239.243,145.658,240.617z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M162.762,181.121l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C160.36,179.747,162.303,179.747,162.762,181.121z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M360.096,295.052l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C357.694,293.678,359.638,293.678,360.096,295.052z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M318.395,139.419l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C315.992,138.045,317.935,138.045,318.395,139.419z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M204.463,336.754l3.54,10.613l11.187,0.087c1.449,0.011,2.049,1.859,0.884,2.72l-9,6.646   l3.374,10.666c0.437,1.38-1.135,2.524-2.314,1.681l-9.101-6.506l-9.101,6.506c-1.178,0.842-2.751-0.3-2.314-1.681l3.374-10.666   l-9-6.646c-1.165-0.861-0.565-2.709,0.884-2.72l11.187-0.087l3.54-10.613C202.061,335.38,204.004,335.38,204.463,336.754z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M357.236,181.121l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646   l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666   l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C359.638,179.747,357.694,179.747,357.236,181.121z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M159.902,295.052l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646   l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666   l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C162.303,293.678,160.36,293.678,159.902,295.052z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M201.603,139.419l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646   l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666   l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C204.004,138.045,202.061,138.045,201.603,139.419z"
      />
      <path
        style={{ fill: "#FFE15A" }}
        d="M315.534,336.754l-3.54,10.613l-11.187,0.087c-1.449,0.011-2.049,1.859-0.884,2.72l9,6.646   l-3.374,10.666c-0.437,1.38,1.135,2.524,2.314,1.681l9.101-6.506l9.101,6.506c1.178,0.842,2.751-0.3,2.314-1.681l-3.374-10.666   l9-6.646c1.165-0.861,0.565-2.709-0.884-2.72l-11.187-0.087l-3.54-10.613C317.935,335.38,315.992,335.38,315.534,336.754z"
      />
    </g>
  </svg>
);

const usa = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.001 512.001">
    <path
      style={{ fill: "#F5F5F5" }}
      d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828  h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z"
    />
    <g>
      <path style={{ fill: "#FF4B55" }} d="M512,114.081H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828   L512,114.081L512,114.081z" />
      <rect y="191.491" style={{ fill: "#FF4B55" }} width="512" height="25.804" />
      <rect y="139.881" style={{ fill: "#FF4B55" }} width="512" height="25.804" />
      <path style={{ fill: "#FF4B55" }} d="M512,268.903H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.977h512V268.903z" />
      <rect y="346.321" style={{ fill: "#FF4B55" }} width="512" height="25.804" />
      <path style={{ fill: "#FF4B55" }} d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828v-16.976h512v16.977   C512,419.773,508.047,423.725,503.172,423.725z" />
      <rect y="294.711" style={{ fill: "#FF4B55" }} width="512" height="25.804" />
    </g>
    <path
      style={{ fill: "#41479B" }}
      d="M229.517,88.277H8.828C3.953,88.277,0,92.229,0,97.104v162.97c0,4.875,3.953,8.828,8.828,8.828  h220.69c4.875,0,8.828-3.953,8.828-8.828V97.104C238.345,92.229,234.392,88.277,229.517,88.277z"
    />
    <g>
      <path
        style={{ fill: "#F5F5F5" }}
        d="M24.789,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928L24,122.843l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,107.779,24.535,107.779,24.789,108.538z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M24.789,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928L24,153.497l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,138.434,24.535,138.434,24.789,139.192z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M24.789,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928L24,184.151l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,169.088,24.535,169.088,24.789,169.846z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M24.789,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928L24,214.806l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,199.742,24.535,199.742,24.789,200.501z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M24.789,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928L24,245.46l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C23.463,230.395,24.535,230.395,24.789,231.155z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M48.582,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,122.809,48.329,122.809,48.582,123.567z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M48.582,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,153.463,48.329,153.463,48.582,154.222z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M48.582,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,184.117,48.329,184.117,48.582,184.876z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M48.582,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C47.255,214.772,48.329,214.772,48.582,215.53z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M72.375,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,107.779,72.122,107.779,72.375,108.538z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M72.375,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,138.434,72.122,138.434,72.375,139.192z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M72.375,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,169.088,72.122,169.088,72.375,169.846z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M72.375,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,199.742,72.122,199.742,72.375,200.501z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M72.375,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C71.049,230.395,72.122,230.395,72.375,231.155z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M96.169,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,122.809,95.916,122.809,96.169,123.567z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M96.169,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,153.463,95.916,153.463,96.169,154.222z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M96.169,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,184.117,95.916,184.117,96.169,184.876z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M96.169,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C94.842,214.772,95.916,214.772,96.169,215.53z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M119.962,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,107.779,119.709,107.779,119.962,108.538z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M119.962,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,138.434,119.709,138.434,119.962,139.192z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M119.962,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,169.088,119.709,169.088,119.962,169.846z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M119.962,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,199.742,119.709,199.742,119.962,200.501z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M119.962,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.026-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C118.636,230.395,119.709,230.395,119.962,231.155z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M143.755,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,122.809,143.502,122.809,143.755,123.567z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M143.755,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,153.463,143.502,153.463,143.755,154.222z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M143.755,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,184.117,143.502,184.117,143.755,184.876z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M143.755,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C142.43,214.772,143.502,214.772,143.755,215.53z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M167.549,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,107.779,167.296,107.779,167.549,108.538z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M167.549,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,138.434,167.296,138.434,167.549,139.192z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M167.549,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,169.088,167.296,169.088,167.549,169.846z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M167.549,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,199.742,167.296,199.742,167.549,200.501z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M167.549,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C166.222,230.395,167.296,230.395,167.549,231.155z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M191.342,123.567l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,122.809,191.09,122.809,191.342,123.567z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M191.342,154.222l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.165-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,153.463,191.09,153.463,191.342,154.222z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M191.342,184.876l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,184.117,191.09,184.117,191.342,184.876z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M191.342,215.53l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67l1.864,5.889   c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889l-4.969-3.67   c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C190.016,214.772,191.09,214.772,191.342,215.53z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M215.136,108.538l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,107.779,214.882,107.779,215.136,108.538z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M215.136,139.192l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,138.434,214.882,138.434,215.136,139.192z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M215.136,169.846l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,169.088,214.882,169.088,215.136,169.846z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M215.136,200.501l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,199.742,214.882,199.742,215.136,200.501z"
      />
      <path
        style={{ fill: "#F5F5F5" }}
        d="M215.136,231.155l1.954,5.86l6.177,0.047c0.8,0.007,1.131,1.027,0.488,1.502l-4.969,3.67   l1.864,5.889c0.242,0.762-0.627,1.394-1.278,0.928l-5.025-3.592l-5.025,3.592c-0.651,0.465-1.518-0.166-1.278-0.928l1.864-5.889   l-4.969-3.67c-0.643-0.476-0.312-1.495,0.488-1.502l6.177-0.047l1.954-5.86C213.81,230.395,214.882,230.395,215.136,231.155z"
      />
    </g>
  </svg>
);

const canada = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.001 512.001">
    <g>
      <path
        style={{ fill: "#F5F5F5" }}
        d="M503.172,423.722H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.101c0-4.875,3.953-8.828,8.828-8.828   h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.77,508.047,423.722,503.172,423.722z"
      />
      <rect x="114.76" y="88.278" style={{ fill: "#F5F5F5" }} width="282.48" height="335.45" />
    </g>
    <g>
      <path style={{ fill: "#FF4B55" }} d="M8.828,88.274C3.953,88.274,0,92.226,0,97.101v317.793c0,4.875,3.953,8.828,8.828,8.828h105.931   V88.274H8.828z" />
      <path style={{ fill: "#FF4B55" }} d="M503.172,88.274H397.241v335.448h105.931c4.875,0,8.828-3.953,8.828-8.828V97.101   C512,92.226,508.047,88.274,503.172,88.274z" />
      <path
        style={{ fill: "#FF4B55" }}
        d="M309.569,294.758l52.383-29.932l-12.02-6.01c-3.371-1.686-5.301-5.326-4.802-9.063l3.911-29.322   l-22.177,6.452c-4.899,1.426-9.983-1.588-11.085-6.569l-2.124-9.6l-20.823,24.37c-2.887,3.378-8.386,0.798-7.633-3.582   l8.893-51.708l-13.615,3.724c-3.977,1.088-8.177-0.722-10.116-4.36l-14.337-26.871v-0.04l-0.01,0.02l-0.011-0.02v0.04   l-14.337,26.871c-1.941,3.637-6.141,5.447-10.118,4.36l-13.616-3.724l8.893,51.708c0.753,4.378-4.747,6.959-7.634,3.582   l-20.823-24.37l-2.124,9.6c-1.102,4.982-6.186,7.994-11.085,6.569l-22.177-6.452l3.911,29.322c0.499,3.736-1.431,7.377-4.802,9.063   l-12.02,6.01l52.383,29.933c5.426,3.101,7.804,9.677,5.615,15.53l-4.478,11.977l44.885-3.832c2.484-0.212,4.598,1.788,4.525,4.279   l-1.414,48.044h8.828L259,322.711c-0.073-2.492,2.041-4.491,4.525-4.279l44.906,3.834l-4.478-11.977   C301.766,304.435,304.143,297.858,309.569,294.758z"
      />
    </g>
  </svg>
);

const IconFlag = (props: IIconFlagProps) => {
  const { store } = props;
  if(!store){
      return <Typography>
          <div style={{display:'flex'}}>
          <NotListedLocationIcon />
          <span>Unspecifed Shopify store</span>
          </div>
      </Typography>
  }
  if (store.includes("-canada")) {
    return <SvgIcon>{canada()}</SvgIcon>;
  }

  if (store.includes("-eu")) {
    return <SvgIcon>{eu()}</SvgIcon>;
  }
  return <SvgIcon>{usa()}</SvgIcon>;
};

export default IconFlag;
