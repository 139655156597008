import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { TextField } from "final-form-material-ui";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from 'react-select';

import RMSTable from "components/Table/RMSTable";
import IBike from "stores/bikes/models/IBike";
import IStore from "stores/IStore";
import { groupTasksForSelect } from "stores/tasks/models/ITask";

export interface ISectionTaskProps {
  bikes: IBike[];
  updateAvailableCustomers: (shopifyData: any, updateType: "ADD" | "REMOVE") => void;
}

const SectionTask = (props: ISectionTaskProps) => {
  const tasks = useSelector((state: IStore) => state.commonData.tasks);
  const unknownBike = useSelector((state: IStore) => state.bike.unknownBike);

  let tasksData = tasks || [];
  // const _tasks = tasksData.map(t => ({ label: t.name, value: t.id }));
  let taskGroup = groupTasksForSelect(tasksData);
  const [bikeList, setBikeList] = useState([]);

  useEffect(() => {
    const getUnknownBike = async () => {
      const bikes: { label: any; value: any, cid?: any }[] = [];
      if (unknownBike) {
        bikes.push({ label: unknownBike.serial_number, value: unknownBike.id });
      }
      props.bikes.forEach((t: any) => bikes.push({ label: t.serial_number, value: t.id, cid: t.customer.shopifyData.id }));
      setBikeList(bikes);
    };

    getUnknownBike();
  }, [props.bikes, unknownBike]);

  const isLastofBike = (fields: any, dataPoint: any) => {
    let count = 0;
    fields.value.forEach((item: any) => {
      if (item.serial_number === dataPoint.serial_number) {
        count++
      }
    });

    if (count === 1) {
      return true;
    }
    return false;
  }

  const ReactSelectAdapterBike = ({ input, fields, dataPoint, ...rest }: any) => (
    <Select
      {...input}
      {...rest}
      inputLabel="Assign a Bike"
      isClearable={true}
      optionLabel="serial_number"
      options={bikeList}
      onChange={(value: any, trigger: any) => {
        if (trigger.action === "clear") {
          const isLast = isLastofBike(fields, dataPoint);

          if (isLast) {
            props.updateAvailableCustomers(dataPoint.serial_number.cid, "REMOVE")
          }
        } else if (trigger.action === "select-option") {
          if (value.label !== "UNKNOWN") {
            if (props.bikes && props.bikes.length > 0) {
              props.updateAvailableCustomers(props.bikes[0].customer.shopifyData, "ADD")
            }
          }
        }
        input.onChange(value);
      }}
    />
  )

  const ReactSelectAdapterTask = ({ input, ...rest }: any) => (
    <Select
      {...input}
      {...rest}
      inputLabel="Select a Task"
      isClearable={true}
      optionLabel="label"
      options={taskGroup}
      onChange={(value: any) => {
        input.onChange(value)
      }}
    />
  )

  return (
    <Grid container={true} alignItems="flex-start" spacing={2}>
      <Grid item={true} xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Tasks
        </Typography>
        <RMSTable>
          <TableBody>
            <FieldArray name="tasks">
              {({ fields }) => {
                // Remove tasks assigned to bikes found in a customer look up when switching or removing the current customer
                // if (props.bikes && props.bikes.length === 0 && fields.value && fields.value.length > 0) {
                //   fields.value.forEach((item, index) => {
                //     if (item.serial_number && item.serial_number !== "UNKNOWN") {
                //       fields.remove(index);
                //     }
                //   })
                // }
                return (
                  <>
                    {fields.map((name, index) => {
                      const dataPoint = fields.value[index];
                      return (
                        <React.Fragment key={name}>
                          <TableRow>
                            <TableCell>
                              <Field name={`${name}.serial_number`} label="Assign a Bike" fields={fields} dataPoint={dataPoint} fullWidth={true} required={false} component={ReactSelectAdapterBike} />
                            </TableCell>
                            <TableCell>
                              <Field name={`${name}.task`} label="Select a Task" fullWidth={true} required={false} component={ReactSelectAdapterTask} />
                            </TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                onClick={() => {
                                  fields.remove(index)
                                  if (dataPoint.serial_number && dataPoint.serial_number.label !== "UNKNOWN") {
                                    const isLast = isLastofBike(fields, dataPoint);

                                    if (isLast) {
                                      props.updateAvailableCustomers(dataPoint.serial_number.cid, "REMOVE")
                                    }
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Field name={`${name}.task_notes`} placeholder="Chain link is bent..." label="Task Notes" fullWidth={true} required={false} component={TextField} />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Fab
                          variant="extended"
                          aria-label="create"
                          color="primary"
                          size="medium"
                          onClick={() => {
                            fields.push({ serial_number: null, task: null });
                          }}>
                          <AddIcon fontSize="small" />
                          Task
                        </Fab>
                      </TableCell>
                    </TableRow>
                  </>
                )
              }}
            </FieldArray>
          </TableBody>
        </RMSTable>
      </Grid>
    </Grid>
  );
};

export default SectionTask;
