import ApiService, { ENDPOINTS } from "utilities/api-service";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import IServiceRequestTask from "stores/tasks/models/ITask";
// import SelectInput from "components/common/FormInputs/SelectInput";
import React from "react";
import Snackbar from "components/Snackbar";

interface ITaskReassignmentFormProps {
  isVisible?: boolean;
  serviceRequestId: number;
  currentTaskId: number;
  tasks: IServiceRequestTask[];
  closeModal: () => void;
}

interface ITaskReassignmentFormState {
  selectedTask:number;
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarVariant: "error" | "info" | "success" | "warning";
}

interface ITaskReassignmentFormValues {
  serial_number: number;
}

interface ITaskReassignmentFormValidationErrors {
  serial_number: string;
}

class TaskReassignmentForm extends React.Component<ITaskReassignmentFormProps, ITaskReassignmentFormState> {
  public state: ITaskReassignmentFormState = {
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarVariant: "info",
    selectedTask:null
  };

  public onSubmit = (values: ITaskReassignmentFormValues) => {
    const { serviceRequestId, currentTaskId } = this.props;

    const data = {
      serial_number: values.serial_number,
      task_ids: [ currentTaskId ]
    }

    ApiService.post(ENDPOINTS.serviceRequestsIdTaskReassignment(serviceRequestId.toString()), data)
      .then(() => {
        this.setState(
          {
            snackbarMessage: "Service Request Task successfully updated.",
            snackbarOpen: true,
            snackbarVariant: "success",
            selectedTask: null
          },
          () => {
            this.props.closeModal();
          }
        );
      })
      .catch(error => {
        this.setState({
          snackbarMessage: `Service Request Task update failed: ${error.message}`,
          snackbarOpen: true,
          snackbarVariant: "error"
        });
      });
  };

  public validate = (values: ITaskReassignmentFormValues) => {    
    const errors = {} as ITaskReassignmentFormValidationErrors;

    if (!values.serial_number) {
      errors.serial_number = "Required";
    }
    return errors;
  };

  public handleSnackBarClose = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  public render() {
    return (
      <>
        {this.props.isVisible && (
          <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
            <Typography variant="h4" align="center" component="h1" gutterBottom={true}>
              Task Re-assignment
            </Typography>
            <Form              
              onSubmit={this.onSubmit}
              validate={this.validate}
              render={({ handleSubmit, submitting, values }) => {                
                return (<form onSubmit={handleSubmit} noValidate={true}>
                  <Paper style={{ padding: 16 }}>
                    <Grid container={true} alignItems="flex-start" spacing={2}>
                      <Grid item={true} xs={12}>
                        <Field name="serial_number" fullWidth={true} required={true} component={TextField} type="text" label="Serial Number" />
                      </Grid>
                      <Grid item={true} style={{ marginTop: 16 }}>
                        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              )
              }}
            />
          </div>
        )}
        <Snackbar isOpen={this.state.snackbarOpen} message={this.state.snackbarMessage} onClose={this.handleSnackBarClose} variant={this.state.snackbarVariant} />
      </>
    );
  }
}

export default TaskReassignmentForm;
