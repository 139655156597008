import ApiService, { ENDPOINTS } from "utilities/api-service";
import { call, put } from "redux-saga/effects";

import CustomerAction from "./CustomerAction";
import IAction from "../IAction";
import ICustomer from "./models/ICustomer";
import { ITimelineObject } from "../timeline/models/TimelineObject";
import { get } from "lodash-es";

export default class CustomerSaga {
  public static *getCustomer(action: IAction<void>): any {
    try {
      // get the customer
      const response = yield call(
        ApiService.get,
        ENDPOINTS.customerId(action.payload.id,action.payload.store || "US")
      );
      const customer: ICustomer = get(response, "data");
      yield put(CustomerAction.getCustomerSuccess(customer));      
    } catch (error) {
      yield put(CustomerAction.error(error));
    }
  }

  public static *getCustomerTimeline(action: IAction<void>): any {
    try {
      yield call(CustomerSaga.getCustomer, action);            
      const url = ENDPOINTS.customerIdTimeline(action.payload.id,action.payload.store)
      console.log(url,'url');
      
      const response = yield call(
        ApiService.get,
        url
      );
      const timelineData: any[] = get(response, "data");
      const timeline: ITimelineObject[] = timelineData.map(obj=>({data:obj,objectType:obj.objectType}))
      yield put(CustomerAction.getTimelineSuccess({data:timeline}));
    } catch (error) {
      console.log(error);

      yield put(CustomerAction.error(error));
    }
  }

  public static *search(action: IAction<void>) {    
    try {
      const response = yield call(
        ApiService.get,
        ENDPOINTS.customerSearch(action.payload)
      );
      const customers: ICustomer[] = get(response, "data.results");      
      yield put(
        CustomerAction.searchResults(customers)
      );      
    } catch (error) {
      yield put(CustomerAction.error(error));
    }
  }
}
