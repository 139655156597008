export  interface IRPBTask {
  needs_approval: boolean;
  id: number;
  name: string;
  gravity: number;
  created_at: string;
  updated_at: string;
  category: string;
}
export default interface IServiceRequestTask {
  bike_id: number;
  gravity: number;
  id: number;
  name: string;
  notes?: string;
  rpb_service_task_id: number;
  status?: string;  
  task:IRPBTask;
  true_gravity?: number;
}

export const groupTasksForSelect = (tasks: IRPBTask[]) => {  
  const cats = tasks.map((t: IRPBTask) => t.category);
  const options = tasks.map((t: IRPBTask) => ({ label: t.name, value: t.id, category: t.category }));
  const unique = cats.filter((v, i, a) => a.indexOf(v) === i);
  const catAr = unique.map(cat => {
    return {
      label: cat,
      options: options.filter((task: any) => task.category === cat)
    };
  });
  return catAr;
};
