import ApiService, { ENDPOINTS } from "utilities/api-service";
import { call, put } from "redux-saga/effects";

import BikeAction from "stores/bikes/BikeAction";
import CommonDataAction from "./CommonActions";
import IAction from "../IAction";
import { ICommonData } from "./models/CommonData";
import { get } from "lodash-es";

export default class CommonDataSaga {
  public static *getCommonData(action: IAction<void>) {
    try {
      const commonDataResponse = yield call(ApiService.get,ENDPOINTS.dataTypes)
      const commonData:ICommonData = get(commonDataResponse,'data')
      yield put(CommonDataAction.setCommonData(commonData))

      const alltasks = yield call(ApiService.get,ENDPOINTS.serviceTasks)      

      // common tasks
      const _tasks = alltasks.data.results.filter( (task:any) =>(task.category !== 'event'))      
      yield put(CommonDataAction.setCommonDataTasks(_tasks))

      // common events
      const _events = alltasks.data.results.filter( (task:any) =>(task.category === 'event'))
      yield put(CommonDataAction.setCommonDataEvents(_events))

      const unknownBikeResults = yield call(ApiService.get,ENDPOINTS.bikeSearch("UNKNOWN"));
      if(unknownBikeResults && unknownBikeResults.data && unknownBikeResults.data.results){
        yield put(BikeAction.setUnknownBike(unknownBikeResults.data.results[0]))
      }
      
      const wcgConfigs = yield call(ApiService.get,ENDPOINTS.wcg)      
      if(wcgConfigs && wcgConfigs.data){
        yield put(CommonDataAction.setCommonDataWCG(wcgConfigs.data))
      }

    } catch (error) {
      yield put(CommonDataAction.error(error));
    }
  }
  
}
