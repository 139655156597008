import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginBottom: "20px"
    },
    detailContainer: {
      flexWrap: "nowrap"
    },
    detailContent: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center"
    },
    detailTitle: {
      whiteSpace: "nowrap"
    },
    divider: {
      marginBottom: "20px"
    },
    label: {
      justifyContent: "flex-start",
      textTransform: "none"
    },
    labelCapitalized: {
      padding: "18.5px 14px",
      justifyContent: "flex-start",
      textTransform: "capitalize"
    },
    labelUppercase: {
      padding: "18.5px 14px",
      justifyContent: "flex-start",
      textTransform: "uppercase"
    },
    notes: {
      margin: "0"
    },
    root: {
      marginTop: 3,
      width: "100%"
    },
    table: {
      minWidth: 500
    },
    tableTools: {
      flexGrow: 1,
      marginBottom: "10px"
    },
    tableWrapper: {
      overflowX: "auto"
    }
  })
);
