import ApiService, {
  ENDPOINTS,
  IGoogleResponse
} from "../../utilities/api-service";
import { call, fork, put } from "redux-saga/effects";

import CommonDataAction from "../common/CommonActions";
import IAction from "../IAction";
import IUser from "./models/IUser";
import RMSLocationAction from "../rms-locations/RMSLocationAction";
import StorageHelper from "../../utilities/storage-helper";
import UserAction from "./UserAction";
import { get } from "lodash-es";

export default class UserSaga {
  
  public static *logout(action: IAction<void>) {
    try {
      const response = yield call(ApiService.post, ENDPOINTS.logout);
      if (response) {
        console.log(response, "logout!");
        yield call(StorageHelper.removeAuthToken);
        yield call(StorageHelper.setAuthToken, action.payload);
        yield put(UserAction.logoutComplete());
      }
    } catch (error) {
      yield put(UserAction.error(error));
    }
  }
  public static *handleUserAndLocations(user: IUser) {
    try {
      yield put(UserAction.registerUserData(user));
      yield put(CommonDataAction.getCommonData())
      yield put(RMSLocationAction.getLocations());
    } catch (error) {
      yield put(UserAction.error(error));
    }
  }
  public static *findMe(action: IAction<void>) {
    try {
      const tokenData: IGoogleResponse = yield call(StorageHelper.getAuthToken);
      if (tokenData) {
        yield put(UserAction.updateTokenData(tokenData));
        const response = yield call(ApiService.get, ENDPOINTS.me);
        const user: IUser = get(response, "data");

        yield fork(UserSaga.handleUserAndLocations, user);
        yield put(CommonDataAction.getCommonData())
      }else{
        yield put(UserAction.error(new Error("Token was not found.")));
      }

    } catch (error) {
      yield put(UserAction.error(error));
    }
  }
  public static *registerToken(action: IAction<void>) {
    try {
      yield call(StorageHelper.setAuthToken, action.payload);
      const response: any = yield call(
        ApiService.registerWithTokenId,
        action.payload
      );
      const user: IUser = get(response, "data");
      yield fork(UserSaga.handleUserAndLocations, user);
    } catch (error) {
      yield put(UserAction.error(error));
    }
  }

  // dev only, allows user to become a user with known token (intended for seed data)
  // TODO - hide this in production
  public static *impersonate(action: IAction<void>) {
    try {
      yield call(StorageHelper.removeAuthToken);
      yield call(StorageHelper.setAuthToken, action.payload);

      const tokenData: IGoogleResponse = yield call(StorageHelper.getAuthToken);
      if (tokenData) {
        yield put(UserAction.updateTokenData(tokenData));
        const response = yield call(ApiService.get, ENDPOINTS.me);
        const user: IUser = get(response, "data");
        yield fork(UserSaga.handleUserAndLocations, user);
      }
    } catch (error) {
      yield put(UserAction.error(error));
    }
  }
}
