import IAction from "../IAction";
import IRMSLocation from "./models/IRMSLocation";
import IRMSServiceRequest from "../rms-service-requests/models/IRMSServiceRequest";
import RMSLocationAction from "./RMSLocationAction";
import UserAction from "../user/UserAction";

export interface IRMSLocationState {
  error: Error;
  expectedStartTime:number;
  locations: IRMSLocation[];
  selectedLocation: IRMSLocation;
  serviceRequestsInContext: IRMSServiceRequest[];
}

const initState = (): IRMSLocationState => ({
  error: null,
  expectedStartTime:null,
  locations: null,
  selectedLocation: null,
  serviceRequestsInContext: null
});
export default class RMSLocationReducer {
  public static reducer(state: IRMSLocationState = initState(), action: IAction<any>): IRMSLocationState {
    switch (action.type) {
      case UserAction.LOGOUT_COMPLETE:
        return initState();
      case RMSLocationAction.ADD_LOCATIONS:
        return { ...state, locations: action.payload };
      case RMSLocationAction.SELECTED_LOCATION:
        return { ...state, selectedLocation: action.payload };
      case RMSLocationAction.SET_EXPECTED_START_TIME:
        return { ...state, expectedStartTime: action.payload };
      case RMSLocationAction.SET_SERVICE_REQUESTS_IN_CONTEXT:
        return { ...state, serviceRequestsInContext: action.payload };
      case RMSLocationAction.ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  }
}
