import * as React from "react";

import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@material-ui/core";

import DateFormatter from "utilities/date-formatter";
import IMedia from "../../stores/notes/models/IMedia";
import INote from "stores/notes/models/INote";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

export interface INoteProps {
  onUpdate: () => void;
  serviceRequest: IRMSServiceRequest;
}
interface INoteCardProps {
  note: INote;
}
const useStyles = makeStyles({
  card: {marginBottom:10},
  cardsContainer: {
    marginTop: 10
  },
  media: {
    height: 140
  }
});

const NoteCard = (props: INoteCardProps) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom={true} variant="h5" component="h2">
          {DateFormatter.defaultDateFormat(props.note.created_at)}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.note.note}
        </Typography>
      </CardContent>
      {props.note.media.map((mediaObject: IMedia) => (
        <CardMedia key={mediaObject.id} component="img" alt={`Image for note ${props.note.id}`} height="140" image={mediaObject.signedUrl} title={`Image for note ${props.note.id}`} />
      ))}

      <CardActions>
        <Button size="small" color="primary" component={Link} to={`/notes/${props.note.id}`}>
          Details
        </Button>
      </CardActions>
    </Card>
  );
};
export function Note(props: INoteProps) {
  const classes = useStyles(props);
  return (
    <Box className={classes.cardsContainer}>
      {props.serviceRequest.otherNotes.map((otherNote: INote) => (
        <div className={classes.card} key={otherNote.id}  >
          <NoteCard   note={otherNote} />
        </div>
      ))}
    </Box>
  );
}
