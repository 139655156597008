import CustomerAction from "./CustomerAction";
import IAction from "../IAction";
import ICustomer from "./models/ICustomer";
import { ITimeline } from "../timeline/models/Timeline";

export interface ICustomerState {
  error: Error;
  searchResults: ICustomer[];
  selectedCustomer: ICustomer;
  timeline: ITimeline;
}

const initState = (): ICustomerState => ({
  error: null,
  searchResults: null,
  selectedCustomer: null,
  timeline: null
});
export default class CustomerReducer {
  public static reducer(
    state: ICustomerState = initState(),
    action: IAction<any>
  ): ICustomerState {
    switch (action.type) {
      case CustomerAction.GET_CUSTOMER_SUCCESS:
      case CustomerAction.SET_CURRENT_CUSTOMER:
        return { ...state, selectedCustomer: action.payload };
      case CustomerAction.TIMELINE_SUCCESS:
        return { ...state, timeline: action.payload };
      case CustomerAction.SEARCH_RESULTS:
        return { ...state, searchResults: action.payload };
      case CustomerAction.ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  }
}
