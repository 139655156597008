import React from "react";
import Button from "@material-ui/core/Button";

export interface IDatePickerButton {
  onClick?: any
  id?: any
  disabled?: any
  inputProps?: any
  value?: any
  text: string
  inputRef?: any
};

const DatePickerButton = function(props: IDatePickerButton) {
  return (
    <Button
      ref={props.inputRef}
      onClick={props.onClick} // do not override
      id={props.id} // do not override
      disabled={props.disabled} // do not override
      {...props.inputProps} // do not override
    >
      {props.text}
    </Button>
  );
};

export default DatePickerButton;