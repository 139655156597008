import * as React from "react";

import IRMSServiceRequest, { styleForStatus } from "stores/rms-service-requests/models/IRMSServiceRequest";
import { TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { isEqual, sortBy } from "lodash";

import MapContainer from "components/Map";
import RMSTable from "components/Table/RMSTable";
import ServiceRequestItem from "./ServiceRequestItem";

export interface IServiceRequstListProps {
  serviceRequests: IRMSServiceRequest[];
  showMap?: boolean;
  styleProps?: any;
  title?: string;
}

export interface IServiceRequstListState {
  coordinates: ICoordinates[];
  serviceRequests: IRMSServiceRequest[];
}

interface ICoordinates {
  lat: any;
  lng: any;
}

class ServiceRequestList extends React.Component<IServiceRequstListProps, IServiceRequstListState> {
  public static defaultProps = {
    showMap: false
  };

  state: IServiceRequstListState = {
    coordinates: [],
    serviceRequests: this.props.serviceRequests
  };

  componentDidUpdate(prevProps: IServiceRequstListProps) {
    if (isEqual(sortBy(this.props.serviceRequests), sortBy(prevProps.serviceRequests)) === false) {
      if (this.props.serviceRequests && this.props.serviceRequests.length > 0) {
        const coordinates = this.props.serviceRequests.map(sr => {
          if (sr.contact && sr.contact.latitude && sr.contact.longitude) {
            return { lat: sr.contact.latitude, lng: sr.contact.longitude };
          }
          return null;
        });
        this.setState({ coordinates: coordinates });
      } else {
        this.setState({ coordinates: [] });
      }
    }
  }

  public render() {
    const ttle = this.props.title ? this.props.title : "Service Requests";
    function createMarkup() { return {__html: ttle}; };

    return (
      <div>
        <Typography variant="subtitle1"><div dangerouslySetInnerHTML={createMarkup()} /></Typography>
        {this.list()}
      </div>
    );
  }

  private list = () => {
    const { serviceRequests } = this.props;
    const { coordinates } = this.state;

    if (!serviceRequests) {
      return <div>There are no service requests for this day</div>;
    }

    return (
      <>
        {coordinates.length > 0 && this.props.showMap && <MapContainer coordinates={coordinates} />}
        <RMSTable>
          <>
            <TableHead>
              <TableRow>
                <TableCell>ID:</TableCell>
                <TableCell>Contact:</TableCell>
                <TableCell>Date:</TableCell>
                <TableCell>Tasks:</TableCell>
                <TableCell>Status:</TableCell>
                {/* <TableCell>Actions:</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceRequests.map((sr, idx) => {
                return (
                  <TableRow className={`service-request ${sr.status}`} style={styleForStatus(sr)} key={idx}>
                    <ServiceRequestItem serviceRequest={sr} />
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        </RMSTable>
      </>
    );
  };
}

export default ServiceRequestList;
