import ApiService, {
  ENDPOINTS,
} from "utilities/api-service";
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';

import ICustomer from '../../../stores/customers/models/ICustomer';
import React from 'react';
import Snackbar from 'components/Snackbar';
import { TextField } from 'final-form-material-ui';

type ICustomerFormType = 'CREATE' | 'UPDATE';

interface ICustomerFormProps {
  closeModal?: (response:any) => void
  customer?:ICustomer;
  type: ICustomerFormType
};

interface ICustomerFormState {
  snackbarMessage: string
  snackbarOpen: boolean
  snackbarVariant: 'error' | 'info' | 'success' | 'warning'
}

interface ICustomerFormValues {
  email: string
  firstname: string
  lastname: string
  phone: string
};

interface ICustomerFormValidationErrors {
  email: string
  firstname: string
  lastname: string
  phone: string
}

class CustomerForm extends React.Component<ICustomerFormProps, ICustomerFormState> {
  public state: ICustomerFormState = {
    snackbarMessage: '',
    snackbarOpen: false,
    snackbarVariant: 'info'
  };

  public onSubmit = (values: ICustomerFormValues) => {
    let promiseRequest 
    if(this.props.type === "UPDATE" && this.props.customer){
      promiseRequest = ApiService.patch(ENDPOINTS.customerId(this.props.customer.id.toString()), values)
    }else{
      promiseRequest = ApiService.post(ENDPOINTS.customers, values)
    }
    promiseRequest
    .then((response:any) => {
      this.setState({
        snackbarMessage: 'Customer successfully created.',
        snackbarOpen: true,
        snackbarVariant: 'success',
      }, () => {
        if (this.props.closeModal) {
          this.props.closeModal(response)
        }
      });
    })
    .catch((error) => {
      this.setState({
        snackbarMessage: `Customer creation failed: ${error.message}`,
        snackbarOpen: true,
        snackbarVariant: 'error',
      });
    });
  };

  public validate = (values: ICustomerFormValues) => {
    const errors = {} as ICustomerFormValidationErrors;

    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.firstname) {
      errors.firstname = 'Required';
    }
    if (!values.lastname) {
      errors.lastname = 'Required';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    }
    return errors;
  };

  public handleSnackBarClose = () => {
    this.setState({
      snackbarOpen: false
    })
  }

  public getTItle = () => {
    let title = '';
    switch (this.props.type) {
      case 'CREATE':
        title = 'Create Customer';
        break;
      case 'UPDATE':
          title = 'Update Customer';
          break;
      default:
          title = '';
    }
    return title;
  }

  public render() {
    return (
      <>
        <div style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
          <Typography variant="h4" align="center" component="h1" gutterBottom={true}>
            { this.getTItle() }
          </Typography>
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} noValidate={true}>
                <Paper style={{ padding: 16 }}>
                  <Grid container={true} alignItems="flex-start" spacing={2}>
                    <Grid item={true} xs={12}>
                      <Field
                        defaultValue={this.props.customer ? this.props.customer.firstname : null}
                        fullWidth={true}
                        required={true}
                        name="firstname"
                        component={TextField}
                        type="text"
                        label="First Name"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                        defaultValue={this.props.customer ? this.props.customer.lastname : null}
                        fullWidth={true}
                        required={true}
                        name="lastname"
                        component={TextField}
                        type="text"
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                      defaultValue={this.props.customer ? this.props.customer.email : null}
                        name="email"
                        fullWidth={true}
                        required={true}
                        component={TextField}
                        type="email"
                        label="Email"
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Field
                      defaultValue={this.props.customer ? this.props.customer.phone : null}
                        name="phone"
                        fullWidth={true}
                        required={true}
                        component={TextField}
                        type="phone"
                        label="Phone"
                      />
                    </Grid>
                    <Grid item={true} style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          />
        </div>
        <Snackbar
          isOpen={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          onClose={this.handleSnackBarClose}
          variant={this.state.snackbarVariant}
        />
      </>
    );
  }
};

export default CustomerForm;
