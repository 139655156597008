import IAction from "../IAction";
import { IOrder } from "./models/IOrder";
import OrderAction from "./OrderAction";

export interface IOrderState {
  error: Error;
  selectedOrder:IOrder;
}
const initState = (): IOrderState => ({
  error: null,
  selectedOrder:null
});
export default class OrderReducer {
  public static reducer(
    state: IOrderState = initState(),
    action: IAction<any>
  ): IOrderState {
    switch (action.type) {
      case OrderAction.SET_ORDER:
        return { ...state, selectedOrder: action.payload };    
      case OrderAction.ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  }
}
