import React, { Component } from "react";
import ApiService, { ENDPOINTS } from "utilities/postal-code-api-service";
import withConnection, { IConnectedRoutedComponentProps, IStoreProps } from "../HOC/ConnectedRouted";
import moment from "moment";

import { Button, ButtonGroup, Checkbox, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import RMSLocationAction from "../../stores/rms-locations/RMSLocationAction";

type IPostalCodesResult = {
  code: string,
  onlineDate: Date
};
type IPostalCodesResults = Array<IPostalCodesResult>;

interface IRMSLocationPostalCodesProps {
  stateToProps: {
    selectedLocation: IRMSLocation;
  };
};
interface IRMSLocationPostalCodesState {
  postalcodes: {
    success: boolean,
    codes: IPostalCodesResults,
    shopifyLocationId: number,
    isLite: boolean
  },
  checked: Array<string>,
  toAdd: Array<string>
};
class RMSLocationPostalCodes extends Component<IRMSLocationPostalCodesProps & IConnectedRoutedComponentProps, IRMSLocationPostalCodesState> {
  state: IRMSLocationPostalCodesState = {
    postalcodes: {
      success: false,
      codes: [],
      shopifyLocationId: NaN,
      isLite: false
    },
    checked: [],
    toAdd: []
  }

  public addPostalCodes = this._addPostalCodes.bind(this);
  public editPostalCodes = this._editPostalCodes.bind(this);
  public deletePostalCodes = this._deletePostalCodes.bind(this);
  public setPostalCodesToAdd = this._setPostalCodesToAdd.bind(this);

  public handleToggle(code: string) {
    const currentIndex = this.state.checked.indexOf(code);
    const updated = [...this.state.checked];

    if (currentIndex === -1) {
      updated.push(code);
    } else {
      updated.splice(currentIndex, 1);
    }

    this.setState({
      postalcodes: {
        success: this.state.postalcodes.success,
        codes: this.state.postalcodes.codes,
        shopifyLocationId: this.state.postalcodes.shopifyLocationId,
        isLite: this.state.postalcodes.isLite
      },
      checked: updated,
      toAdd: this.state.toAdd
    })
  }

  public componentDidMount() {
    const objId = this.props.routerProps.match.params["id"];
    if (objId) {
      this.props.dispatch(RMSLocationAction.getLocation(objId));
    }
    if (this.props.data["selectedLocation"]) {
      this._getPostalCodes();
    }
  }
  public componentDidUpdate(newProps: IConnectedRoutedComponentProps) {
    if (this.props.data["selectedLocation"] !== newProps.data["selectedLocation"]) {
      this._getPostalCodes();
    }
  }
  public render() {
    const selectedLocation: IRMSLocation = this.props.data["selectedLocation"];
    const postalCodes: IPostalCodesResults = this.state.postalcodes.codes;
    const now = moment();

    if (selectedLocation) {
      return (
        <div>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Typography variant="h2">Manage Postal Codes {selectedLocation ? `(${selectedLocation.name})` : ''}</Typography>

              <form noValidate autoComplete="off" style={({marginBottom: "1.5rem"})}>
                <div>
                  <TextField
                    id="add-postalcodes"
                    label="Postal codes to add"
                    helperText="Press Shft + Rtn to add another postal code"
                    multiline
                    rowsMax={4}
                    onChange={this.setPostalCodesToAdd}
                  />
                  <TextField
                    id="add-online-date"
                    label="Online Date/Time"
                    helperText="Date postal codes will be live for customers"
                    type="datetime-local"
                    defaultValue={now.format("YYYY-MM-DDTHH:mm")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button onClick={this.addPostalCodes}>Add All in Textbox</Button>
                </ButtonGroup>
              </form>

              <form noValidate autoComplete="off" style={({marginBottom: "1.5rem"})}>
                <div>
                  <TextField
                    id="edit-online-date"
                    label="Online Date/Time"
                    helperText="Date postal codes will be live for customers"
                    type="datetime-local"
                    defaultValue={now.format("YYYY-MM-DDTHH:mm")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                  <Button onClick={this.editPostalCodes}>Publish Edits to Selected</Button>
                  <Button onClick={this.deletePostalCodes}>Delete Selected</Button>
                </ButtonGroup>
              </form>

              {postalCodes && (
                <TableContainer component={Paper}>
                  <Table aria-label="postal codes table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Postal Code</TableCell>
                        <TableCell>Online Date</TableCell>
                        <TableCell align="right">({this.state.checked.length}) Selected</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {postalCodes.map((code) => (
                        <TableRow key={code.code}>
                          <TableCell>
                            {moment(code.onlineDate).diff(now) <= 0 && (
                              <Chip
                                size="small"
                                label="Live"
                                color="primary"
                              />
                            )}
                            {moment(code.onlineDate).diff(now) > 0 && (
                              <Chip
                                size="small"
                                label="Pending"
                                color="default"
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {code.code}
                          </TableCell>
                          <TableCell>{moment(code.onlineDate).format('lll')}</TableCell>
                          <TableCell align="right">
                            <Checkbox
                              edge="start"
                              checked={this.state.checked.includes(code.code)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': `checkbox-list-label-${code.code}` }}
                              onChange={() => {this.handleToggle(code.code)}}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </div>
      );
    }
    return <Loading isLoading={true} />;
  }

  private _getFormOnlineDate(selector: string) {
    const onlineDateNode = document.getElementById(selector) as HTMLInputElement;
    return new Date(onlineDateNode.value);
  }

  private _getRegionData(country: string) {
    const countriesToRegions = {
      'US': 'US',
      'USA': 'US',
      'UNITED STATES': 'US',
      'CA': 'CA',
      'CANADA': 'CA',
      'NL': 'EU',
      'NETHERLANDS': 'EU',
    }
    const region = countriesToRegions[country.toUpperCase()];

    const regionData = {
      US: {
        store: 'rad-power-bikes.myshopify.com',
        currencyCode: 'USD',
        price: 199
      },
      CA: {
        store: 'rad-power-bikes-canada.myshopify.com',
        currencyCode: 'CAN',
        price: 199
      },
      EU: {
        store: 'rad-power-bikes-eu.myshopify.com',
        currencyCode: 'EUR',
        price: 199
      }
    }

    if (regionData.hasOwnProperty(region)) {
      return regionData[region];
    }

    return regionData['US'];
  }

  private async _addPostalCodes() {
    const postalCodes = this.state.toAdd;

    if (postalCodes.length > 0) {
      const location = this.props.data["selectedLocation"];
      const postalCodeState = this.state.postalcodes;
      const contact = location.contact;
      const region = this._getRegionData(contact.country);

      const onlineDate = this._getFormOnlineDate("add-online-date");

      // Add new postal codes
      const requests = postalCodes.map(async (code) => {
        return await this._addPostalCode(code, contact.email, postalCodeState.shopifyLocationId, region.store, location.id, postalCodeState.isLite, region.currencyCode, region.price, onlineDate);
      });

      await Promise.all(requests);
      this._getPostalCodes();
    }
  }

  private async _addPostalCode(code: string, email: string, shopifyLocationId: number, shopifyStore: string, hubLocationId: number, isLite: boolean, currency: string, price: number, onlineDate: Date) {
    return await ApiService.post(ENDPOINTS.postalCodes, {
      code: code,
      email: email,
      shopifyLocationId: shopifyLocationId,
      shopifyStore: shopifyStore,
      hubLocationId: hubLocationId,
      isLite: isLite,
      currency: currency,
      price: price,
      onlineDate: onlineDate.toISOString()
    })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.warn("POSTAL CODE ADD: ", error);
        return true;
      });
  }

  private async _editPostalCodes() {
    const postalCodes = this.state.checked;

    const location = this.props.data["selectedLocation"];
    const postalCodeState = this.state.postalcodes;
    const contact = location.contact;
    const region = this._getRegionData(contact.country);

    const onlineDate = this._getFormOnlineDate("edit-online-date");

    // Edit postal codes
    const requests = postalCodes.map(async (code) => {
      return await this._updatePostalCode(code, contact.email, postalCodeState.shopifyLocationId, region.store, location.id, postalCodeState.isLite, region.currencyCode, region.price, onlineDate);
    });

    await Promise.all(requests);
    this._getPostalCodes();
  }

  private async _deletePostalCodes() {
    const confirmed = window.confirm("Are you sure you want to delete?");

    if (confirmed) {
      const postalCodes = this.state.checked;
      const requests = postalCodes.map(async (code) => {
        return await this._deletePostalCode(code);
      });

      await Promise.all(requests);
      this._getPostalCodes();
    }
  }

  private async _deletePostalCode(code: string) {
    return await ApiService.delete(ENDPOINTS.postalCode(code))
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.warn("POSTAL CODE DELETE: ", error);
        return false;
      });
  }

  private _setPostalCodesToAdd(event:React.ChangeEvent<HTMLInputElement>) {
    const toAdd: Array<string> = event.target.value.split('\n');

    this.setState({
      postalcodes: {
        success: this.state.postalcodes.success,
        codes: this.state.postalcodes.codes,
        shopifyLocationId: this.state.postalcodes.shopifyLocationId,
        isLite: this.state.postalcodes.isLite
      },
      checked: this.state.checked,
      toAdd: toAdd
    });
  }

  private _getPostalCodes() {
    if (this.props.data["selectedLocation"]) {
      const today = moment();
      const future = today.add(1, "year"); // Need to query a date into the future to show future postal codes
      ApiService.get(ENDPOINTS.hubLocationPostalCodes(this.props.data["selectedLocation"].id, future.format()))
        .then((response) => {
          const firstResult: any = response.data["postalcodes"].length ? response.data["postalcodes"][0] : {};
          this.setState({
            postalcodes: {
              success: response.data["success"],
              codes: response.data["postalcodes"].map((result: any) => {
                return {
                  code: result["code"],
                  onlineDate: result["onlineDate"]
                };
              }),
              shopifyLocationId: firstResult["shopifyLocationId"],
              isLite: firstResult["isLite"]
            },
            checked: [],
            toAdd: this.state.toAdd
          });
        })
        .catch((error) => {
          this.setState({
            postalcodes: {
              success: false,
              codes: [],
              shopifyLocationId: NaN,
              isLite: false
            },
            checked: [],
            toAdd: this.state.toAdd
          });
          console.warn("POSTAL CODE LOOKUP: ", error);
        });
    }
  }

  private async _updatePostalCode(code: string, email: string, shopifyLocationId: number, shopifyStore: string, hubLocationId: number, isLite: boolean, currency: string, price: number, onlineDate: Date) {
    return await ApiService.put(ENDPOINTS.postalCode(code), {
      email: email,
      shopifyLocationId: shopifyLocationId,
      shopifyStore: shopifyStore,
      hubLocationId: hubLocationId,
      isLite: isLite,
      currency: currency,
      price: price,
      onlineDate: onlineDate.toISOString()
    })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.warn("POSTAL CODE ADD: ", error);
        return true;
      });
  }
}
const mapStateToProps = (state: IStore): IStoreProps => ({
  selectedLocation: state.location.selectedLocation
});
const details = withConnection(RMSLocationPostalCodes, mapStateToProps);
export default details;
