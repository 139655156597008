import ApiService, { ENDPOINTS } from "utilities/api-service";
import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomerDetail from "components/Customer/CustomerDetail";
import Grid from "@material-ui/core/Grid";
import ICustomer from "stores/customers/models/ICustomer";
import RMSTable from "components/Table/RMSTable";
import SelectInput from "components/common/FormInputs/SelectInput";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

export interface ICustomerSearchProps {
  onUpdateData: (data: any) => unknown;
}

const useStyles = makeStyles(() => ({
  customerDetails: {
    marginTop: 10,
    marginBottom: 10
  },
  searchBox: {
    marginBottom: "16px"
  },
  searchResultsBox: {
    marginBottom: "16px"
  },
  submitButton: {
    marginTop: "16px"
  }
}));

const storeOptions = [
  { label: "US", value: "US" },
  { label: "CA", value: "CA" },
  { label: "EU", value: "EU" }
];

const CustomerSearch = (props: ICustomerSearchProps) => {
  const classes = useStyles(props);

  const [customerResults, setCustomerResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedStore, setSelectedStore] = useState("US");
  const [selectedCustomer, setSelectedCustomer] = useState(null as ICustomer);
  const [bikeSearchValue, setBikeSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, customerResults.length - page * rowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeBikeSearch = (e: any) => {
    const value = e.target.value;
    
    if (value) {
      setBikeSearchValue(value);
    } else {
      setBikeSearchValue(value);
      setCustomerResults([]);
    }
  }

  const searchCustomers = (e: any) => {
    const value = bikeSearchValue
    setIsSearching(true);
    setSelectedCustomer(null);
    ApiService.get(ENDPOINTS.customerSearch(value, selectedStore))
      .then(response => {
        if (response.data.results && response.data.results) {
          setSelectedCustomer(null);
          setIsSearching(false);
          if (value === "") {
            setCustomerResults([]);
          } else {
            setPage(0);
            setSelectedRow(null);
            setCustomerResults(response.data.results);
          }
        }
      })
      .catch(error => {
        setIsSearching(false);
        console.warn("CUSTOMER RESULTS: ", error);
      });
  };

  const handleRowClick = (e: any, id: number, row: any) => {
    setSelectedRow(id);    
    ApiService.get(`${ENDPOINTS.customerId(id.toString(),selectedStore || 'US')}`)
      .then(response => {
        if (response.data) {
          setSelectedCustomer(response.data);
        }
      })
      .catch(error => {
        console.warn("SELECT CUSTOMER: ", error);
      });
  };

  const onChangeStore = (value: any) => {
    setSelectedStore(value);
    setPage(0);
    setSelectedRow(null);
    setCustomerResults([]);
  };

  const onSubmit = () => {
    props.onUpdateData(selectedCustomer);
  }

  const customerDetails = () => (
    <Box className={classes.customerDetails}>
      <Card>
        <CardContent>
          <CustomerDetail customer={selectedCustomer} />
        </CardContent>
      </Card>
      <Button className={classes.submitButton} variant="contained" color="primary" onClick={onSubmit} disabled={!selectedCustomer}>
        Submit
      </Button>
    </Box>
  );

  return (
    <>
      <Grid className={classes.searchBox} container={true} alignItems="flex-end" spacing={1}>
        <Grid item={true} xs={9}>
          <TextField label="Customer Lookup" fullWidth={true} required={false} onChange={handleChangeBikeSearch} />
        </Grid>
        <Grid item={true} xs={2}>
          <SelectInput
            defaultValue={{ label: "US", value: "US" }}
            options={storeOptions}
            inputLabel="Store"
            isMulti={false}
            optionLabel="label"
            onChange={onChangeStore}
            isAsync={false}
            isClearable={false}
          />
        </Grid>
        <Grid item={true} xs={1}>
        <Button variant="contained" color="primary" onClick={() => searchCustomers(bikeSearchValue)} disabled={!bikeSearchValue || isSearching}>
          Search
        </Button>
        </Grid>
      </Grid>
      {selectedCustomer !== null && customerDetails()}
      {isSearching && <CircularProgress  color="primary" />}
      {customerResults.length > 0 && (
        <Box className={classes.searchResultsBox}>
          <RMSTable>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? customerResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : customerResults).map(row => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={e => {
                    handleRowClick(e, row.id, row);
                    setSelectedCustomer(row);
                    setCustomerResults([]);
                  }}
                  selected={selectedRow === row.id}>
                  <TableCell>
                    {row.firstname && row.firstname} {row.lastname && row.lastname}
                    {row.shopifyData.addresses.map((addy: any) => (
                      <div key={addy.id}>
                        <Typography variant={"caption"}>
                          {addy.address1}
                          {addy.address2 && `, ${addy.address2}`}
                          {addy.state && `, ${addy.state}, `}
                          {addy.province && `, ${addy.province}, `}
                          {addy.zip} {addy.country}
                        </Typography>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>{row.email && row.email}</TableCell>
                  <TableCell>{row.phone && row.phone}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={customerResults.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </RMSTable>
        </Box>
      )}
    </>
  );
};

export default CustomerSearch;
