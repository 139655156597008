import * as React from "react";

import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import DateFormatter from "../../utilities/date-formatter";
import IRMSServiceRequest from "../../stores/rms-service-requests/models/IRMSServiceRequest";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";

export interface IServiceRequestTimelineObjectProps {
  data: IRMSServiceRequest;
  orignalState?: IRMSServiceRequest;
}
const useStyles = makeStyles(() => ({
  highlight: {
    backgroundColor: "#fff2ac",
    color: "red"
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  strikethru:{
    textDecoration: "line-through"
  }
}));
const ServiceRequestTimelineObject = (props: IServiceRequestTimelineObjectProps) => {
  const classes = useStyles(props);

  const { data } = props;
  if (!data) {
    return <Loading isLoading={true} />;
  }

  const getClassName = (prp: any) => {
    let clas = null;
    if (props.orignalState) {
      const otherStateProp = props.orignalState[prp];
      if (otherStateProp) {
        return otherStateProp !== data[prp] ? classes.highlight : null;
      } else {
        return classes.highlight;
      }
    }
    return clas;
  };

  const getRowFor = (prp: string, override?: string) => {
    
    const hadInOriginal = props.orignalState && props.orignalState[prp];
    let changeVal = data[prp] || null;
    if(!changeVal || prp === 'id'){
      return null
    }
    let originalVal = hadInOriginal ? props.orignalState[prp] : null;
    const dateProps = ["expected_start_time", "updated_at"];
    const userProps = ["assigned_user_id"];
    if (dateProps.includes(prp)) {
      changeVal = DateFormatter.detailedDateFormat(data[prp]);
      originalVal = DateFormatter.detailedDateFormat(props.orignalState[prp]);
      // format a date val
    } else if (userProps.includes(prp)) {
      // format a user val
      changeVal = data[prp] ? `${data[prp].firstname} ${data[prp].lastname}` : null;
      originalVal = props.orignalState[prp] ? `${props.orignalState[prp].firstname} ${props.orignalState[prp].lastname}` : null;
    } else {
      // use the rawdata
      changeVal = data[prp];
      originalVal = props.orignalState[prp];
    }

    return (
      <Grid xs={12} item={true}>
        <span>
          <Box className={classes.inline}>
            
            <Typography variant="body1" color="primary">
              {prp}:
            </Typography>
            &nbsp;
            <Typography variant="body1" className={classes.strikethru} >
            {originalVal}
            </Typography>
            <Typography variant="body1" className={getClassName(prp)}>
              {changeVal}
            </Typography>
          </Box>
        </span>
      </Grid>
    );
  };

  const keys = props.orignalState ? Object.keys(props.orignalState) : [];
  // const nots = ["id","expected_start_time","created_at","updated_at","createdBy","assignee"]
  // const kys = keys.filter(ky =>(!nots.includes(ky)))
  return (
    <div>
      <Grid container={true}>
        <Grid xs={12} item={true}>
          Service Request: <Link to={`/service-requests/${data.id}`}>{data.id}</Link>
        </Grid>
        
        {getRowFor("assignee")}
        {keys.map(ky => getRowFor(ky))}
      </Grid>
      <div></div>
    </div>
  );
};
export default ServiceRequestTimelineObject;
