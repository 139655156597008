import ApiService, { ENDPOINTS } from "utilities/api-service";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { IRPBTask, groupTasksForSelect } from "stores/tasks/models/ITask";
import SelectInput, { ISelectGroupedInputOption, ISelectInputOption } from "components/common/FormInputs/SelectInput";

import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IServiceRequestTask from "stores/tasks/models/ITask";
import IStore from "stores/IStore";
import React from "react";
import Snackbar from "components/Snackbar";
import { TextField } from "final-form-material-ui";
import { connect } from "react-redux";
import IBike from "stores/bikes/models/IBike";

interface IAddServiceRequestTaskFormProps {
  isVisible?: boolean;
  bikeId?: number;
  serviceRequest: IRMSServiceRequest;
  tasks: IRPBTask[];
  closeModal: () => void;
  unknownBike: IBike;
}

interface IAddServiceRequestTaskFormState {
  selectedTask:number;
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarVariant: "error" | "info" | "success" | "warning";
}

interface IAddServiceRequestTaskFormValues {
  serial_number: string;
  notes: string;
  tasks: number | number[];
}

interface IAddServiceRequestTaskFormValidationErrors {
  tasks: string;
}

const ReactSelectAdapterTasks = (options: ISelectInputOption[], group: ISelectGroupedInputOption[],form:AddServiceRequestTaskForm) => ({ input, ...rest }: any) => {
  const filtr = options.filter(o=> o.value === form.state.selectedTask)
  const selectedOption = filtr.length > 0 ? filtr[0] : null
  return <SelectInput 
  {...input} 
  {...rest} 
  isAsync={false}
  group={group} 
  options={options} 
  inputLabel="Select a Task" 
  optionLabel="name" 
  selected={selectedOption}
  isMulti={false}   
  onChange={(value: any) => {
    form.setState({selectedTask:value})
    input.onChange(value)
  }} 
  />
};

class AddServiceRequestTaskForm extends React.Component<IAddServiceRequestTaskFormProps, IAddServiceRequestTaskFormState> {
  public state: IAddServiceRequestTaskFormState = {
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarVariant: "info",
    selectedTask:null
  };

  public onSubmit = (values: IAddServiceRequestTaskFormValues) => {
    let ogTaskIds: number[] = [];
    let tasks: any = [];
    const newTask: any = {
      bike_id: this.props.bikeId ? this.props.bikeId : this.props.unknownBike.id,
      rpb_service_task_id: values.tasks,
      notes: values.notes
    };

    if (this.props.serviceRequest.tasks && this.props.serviceRequest.tasks.length > 0) {
      tasks = this.props.serviceRequest.tasks.slice(0);
    }
    
    if (tasks.length > 0) {
      ogTaskIds = tasks.map((task: IServiceRequestTask) => {
        return task.id
      })
    }

    tasks.push(newTask);

    const data = {
      id: this.props.serviceRequest.id,
      tasks: tasks
    };

    // TODO - move to saga
    ApiService.patch(ENDPOINTS.serviceRequestsId(this.props.serviceRequest.id.toString()), data)
      .then((response) => {
        let taskIds = response.data.tasks.map((task: IServiceRequestTask) => {
          return task.id
        })

        if (ogTaskIds.length > 0) {
          ogTaskIds.forEach(ogId => {
            taskIds = taskIds.filter((item: number) => item !== ogId)
          })
        }

        const reassignmentData = {
          serial_number: values.serial_number,
          task_ids: taskIds
        }

        ApiService.post(ENDPOINTS.serviceRequestsIdTaskReassignment(this.props.serviceRequest.id.toString()), reassignmentData)
          .then(() => {
            this.setState(
              {
                snackbarMessage: "Service Request Task successfully created.",
                snackbarOpen: true,
                snackbarVariant: "success"
              },
              () => {
                this.props.closeModal();
              }
            );
          })
          .catch(error => {
            this.setState({
              snackbarMessage: `Service Request Task failed: ${error.message}`,
              snackbarOpen: true,
              snackbarVariant: "error"
            });
          });
      })
      .catch(error => {
        this.setState({
          snackbarMessage: `Service Request Task failed: ${error.message}`,
          snackbarOpen: true,
          snackbarVariant: "error"
        });
      });
  };

  public validate = (values: IAddServiceRequestTaskFormValues) => {    
    const errors = {} as IAddServiceRequestTaskFormValidationErrors;

    if (!values.tasks) {
      errors.tasks = "Required";
    }
    return errors;
  };

  public handleSnackBarClose = () => {
    this.setState({
      snackbarOpen: false
    });
  };

  public render() {
    let tasksData = this.props.tasks || [];
    const tasks = tasksData.map(t => ({ label: t.name, value: t.id }));
    let taskGroup = groupTasksForSelect(tasksData);
    return (
      <>
        {this.props.isVisible && (
          <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
            <Typography variant="h4" align="center" component="h1" gutterBottom={true}>
              Add Service Request Task
            </Typography>
            <Form
              onSubmit={this.onSubmit}
              initialValues={{ serial_number: "", notes: "", tasks: null }}
              validate={this.validate}
              render={({ handleSubmit, submitting,values }) => {
                return (<form onSubmit={handleSubmit} noValidate={true}>
                  <Paper style={{ padding: 16 }}>
                    <Grid container={true} alignItems="flex-start" spacing={2}>
                      {!this.props.bikeId && (
                        <Grid item={true} xs={12}>
                          <Field name="serial_number" fullWidth={true} required={true} component={TextField} type="text" label="Serial Number" />
                        </Grid>
                      )}
                      <Grid item={true} xs={12}>
                        <Field name="tasks" fullWidth={true} required={true} component={ReactSelectAdapterTasks(tasks, taskGroup,this)} />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Field fullWidth={true} name="notes" component={TextField} multiline={true} label="Notes" />
                      </Grid>
                      <Grid item={true} style={{ marginTop: 16 }}>
                        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              )}}
            />
          </div>
        )}
        <Snackbar isOpen={this.state.snackbarOpen} message={this.state.snackbarMessage} onClose={this.handleSnackBarClose} variant={this.state.snackbarVariant} />
      </>
    );
  }
}

const mapStateToProps = (store: IStore) => ({
  tasks: store.commonData.tasks,
  unknownBike: store.bike.unknownBike
});

export default connect(mapStateToProps)(AddServiceRequestTaskForm);
