import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";
import React from "react";
import { TextField } from "final-form-material-ui";

export interface IServiceRequestSearch {
  values: any;
}

const ServiceRequestSearch = (props: IServiceRequestSearch) => {
  return (
    <>
      <Grid item={true} xs={10}>
        <Field defaultValue={props.values ? props.values.name : null} style={{ display: "flex" }} label="Name or email" name="service_request.name" component={TextField} type="input" />
      </Grid>
    </>
  );
};

export default ServiceRequestSearch;
