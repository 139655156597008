import ApiService from "utilities/api-service";
import AsyncSelect from 'react-select/async';
import { COLORS } from "theme/theme";
import React from 'react';
import Select from 'react-select';
import { StylesConfig } from "react-select/src/styles";

export interface ISelectInputOption { 
  value: number | string
  label: string 
}

export interface ISelectGroupedInputOption {   
  label: string ,
  options: ISelectInputOption[]
}
const selectStyles:StylesConfig = {
  groupHeading:()=>({
    backgroundColor:'#f2f0f0',
    color:COLORS.PRIMARY.BRAND,
    paddingLeft:10,
    paddingRight:10
  })
}
const formatGroupLabel = (data:any) => (
  <div style={{paddingTop:10,paddingBottom:10,display:'flex',justifyContent:"space-between"}}>
    <span>{data.label.toUpperCase()}</span>
    <span >{data.options.length}</span>
  </div>
);
export interface ISelectInputProps {
  autoFocus?: boolean
  defaultValue?: { label: string, value: string }
  openMenuOnFocus?: boolean
  url?: string
  group?: ISelectGroupedInputOption[]
  inputLabel: string | (() => string)
  isAsync?: boolean
  isClearable?: boolean
  isMulti?: boolean
  minQueryLength?: number
  optionLabel: string | string[]
  options?: ISelectInputOption[]
  selected?:ISelectInputOption,
  onBlur?: () => void
  onFocus?: () => void
  onChange: (value: number | string | Array<string | number>,option?:any) => void
}

interface ISelectInputState {  
  isLoading: boolean  
  options: ISelectInputOption[]
  selectedOption: ISelectInputOption | ISelectInputOption[]
}

class SelectInput extends React.Component<ISelectInputProps, ISelectInputState> {
  public static defaultProps = {
    autoFocus: false,
    isClearable: true,
    isMulti: false,
    minQueryLength: 3,
    openMenuOnFocus: true,
  };

  public state: ISelectInputState = {
    isLoading: true,
    options: [],
    selectedOption: undefined,
  };

  public componentDidMount() {
    if (!this.props.isAsync) {
      this.getOptions();
    }
    if(this.props.selected && !this.state.selectedOption){
      this.setState({selectedOption:this.props.selected})
    }
  }

  public getAsyncOptions = async (query: string) => {
    if (query.length >= this.props.minQueryLength) {
      await this.getOptions(query);
    }
    
    return Promise.resolve(this.state.options)
  }

  public getOptions = (query?: string) => {
    const { options, url } = this.props;

    if (url) {
      let endpoint = url;
      if (query) {
        endpoint = endpoint + query
      }
      ApiService.get(endpoint)
        .then((response) => {
          if (response.data) {
            if (response.data.results) {
              const apiOptions: any = [];
              response.data.results.forEach((option: any) => {
                let label = '';
                if (this.props.optionLabel instanceof Array) {
                  this.props.optionLabel.forEach((column, index) => {
                    if (index === this.props.optionLabel.length - 1) {
                      label = label + option[column];
                    } else {
                      label = label + `${option[column]} `;
                    }
                  })
                } else {
                  label = option[this.props.optionLabel];
                }
                const menuOption = {
                  label,
                  value: option.id
                }
                apiOptions.push(menuOption);
              })
              this.setState({
                isLoading: false,
                options: apiOptions
              });
            } else if (response.data["serviceRequestStatusTypes"]) {
              const statusTypes = []
              for (const key of Object.keys(response.data["serviceRequestStatusTypes"])) {
                const statusType = {
                  label: key,
                  value: response.data["serviceRequestStatusTypes"][key]
                };
                statusTypes.push(statusType);
              }
              this.setState({
                isLoading: false,
                options: statusTypes
              });
            }
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    } else if (options && options.length > 0) {
      this.setState({
        isLoading: false,
        options
      })
    }
  }

  public handleChange = (selectedOptionOrig: any) => {
    const selectedOption : ISelectInputOption | ISelectInputOption[] = selectedOptionOrig as ISelectInputOption | ISelectInputOption[]
    this.setState({ selectedOption }, () => {
      if (this.state.selectedOption instanceof Array) {
        const values = this.state.selectedOption.map((option: ISelectInputOption) => 
          option.value
        )
        this.props.onChange(values);
      } else if (this.state.selectedOption) {
        this.props.onChange(this.state.selectedOption.value,this.state.selectedOption);
      }
    });
  };

  public render() {
    const { autoFocus, defaultValue, inputLabel, isAsync, isClearable, isMulti, openMenuOnFocus, onBlur, onFocus , group } = this.props;
    const { isLoading, options, selectedOption } = this.state;
    
    return (
      <>
        {isAsync ? (
          <AsyncSelect
            cacheOptions={false}
            defaultValue={defaultValue}
            autoFocus={autoFocus}
            defaultOptions={options}
            isMulti={isMulti}
            loadOptions={this.getAsyncOptions}
            onBlur={onBlur}     
            onFocus ={onFocus}       
            openMenuOnFocus={openMenuOnFocus}
            placeholder={inputLabel}
            onChange={this.handleChange}
            isClearable={isClearable}
          />
        ) : (
          <Select
            defaultValue={defaultValue}
            styles={selectStyles}
            formatGroupLabel={formatGroupLabel}
            isLoading={isLoading}
            isMulti={isMulti}
            value={selectedOption}
            onBlur={onBlur}
            onFocus ={onFocus}
            onChange={this.handleChange}
            options={ group ? group :options}
            onBlurResetsInput={false}
            placeholder={inputLabel}
            autoFocus={autoFocus}
            openMenuOnFocus={openMenuOnFocus}
            isClearable={isClearable}
          />
        )}
      </>
    );
  }
}

export default SelectInput;
