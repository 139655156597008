import * as React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import INote from "stores/notes/models/INote";
import Loading from "../Loading/Loading";

export interface INoteTimelineObjectProps {
  data: INote
}

const useStyles = makeStyles(() => ({
  note: {
    whiteSpace: 'pre-line'
  }
}));

const NoteTimelineObject = (props: INoteTimelineObjectProps) => {
  const classes = useStyles(props);
  const { data } = props;

  if (!data) {
    return <Loading isLoading={true} />;
  }

  return (
    <>
      <Link to={`/notes/${data.id}`}>
        Note
      </Link>
      <Typography variant="body1" className={classes.note}>
        {data.note}
      </Typography>
    </>
  );
}
export default NoteTimelineObject