import IAction from '../IAction';
import { IOrder } from './models/IOrder';

export default class OrderAction {
    public static readonly GET_ORDER: string = 'OrderAction.GET_ORDER';
    public static readonly SET_ORDER: string = 'OrderAction.SET_ORDER';
    public static readonly ERROR: string = 'OrderAction.ERROR';
    

    public static getOrder(orderId:number): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: orderId,
            type: OrderAction.GET_ORDER
        };
    }

    public static setOrder(order:IOrder): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: order,
            type: OrderAction.SET_ORDER
        };
    }

    public static error(error:Error): IAction<string> {
        return {
            error: true,
            meta: null,
            payload: error,
            type: OrderAction.ERROR
        };
    }
    
    
}
