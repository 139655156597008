import { Field } from "react-final-form";
import { Grid } from "@material-ui/core";
import React from "react";
import { TextField } from "final-form-material-ui";

export interface ICustomerSearch {
  values?:any,
  store?:string
}

const CustomerSearch = (props: ICustomerSearch) => {
  
  return (
    <>
      <Grid item={true} xs={12}>
      <label>Shopify Store</label>
        <Field defaultValue={props.store ? props.store : "US"}  style={{ border: "none", fontSize: "20px" }} name="store" component="select">
          <option value="US">US</option>
          <option value="CA">CA</option>
          <option value="EU">EU</option>
        </Field>
      </Grid>
      <Grid item={true} xs={12}>
        <Field defaultValue={props.values ? props.values.misc : null} style={{ display: "flex" }} label="Name" name="customer.misc" component={TextField} type="input" />
      </Grid>
      <Grid item={true} xs={12}>
        <Field defaultValue={props.values ? props.values.email : null} style={{ display: "flex" }} label="Email" name="customer.email" component={TextField} type="input" />
      </Grid>
      <Grid item={true} xs={12}>
        <Field defaultValue={props.values ? props.values.phone : null} style={{ display: "flex" }} label="Phone Number" name="customer.phone" component={TextField} type="input" />
      </Grid>
    </>
  );
};

export default CustomerSearch;
