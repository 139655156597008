import React from "react";

import CustomerForm from "components/Customer/CustomerForm";

const CreateCustomer = () => {
  return (
    <>
      <CustomerForm type="CREATE" />
    </>
  );
}

export default CreateCustomer;
