import ApiService, { ENDPOINTS } from "../../utilities/api-service";
import {
  Button,
  CardMedia,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";
import React, { Component } from "react";
import withConnection, {
  IConnectedRoutedComponentProps,
  IStoreProps
} from "../HOC/ConnectedRouted";

import DateFormatter from "utilities/date-formatter";
import IMedia from "stores/notes/models/IMedia";
import INote from "../../stores/notes/models/INote";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IStore from "stores/IStore";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading";

interface INoteDetailsProps {
  stateToProps: {
    selectedServiceRequest: IRMSServiceRequest;
  };
}
interface INoteDetailsState {
  note: INote;
  error: Error;
}

interface ILinkForNote {
  link: string;
  title: string;
}
const linkForNote = (note: INote) => {
  const result: ILinkForNote = {
    link: "/",
    title: "Home"
  };
  switch (note.noteable_type) {
    case "RPBServiceRequest":
      result.title = "Show Service Request";
      result.link = `/service-requests/${note.noteable_id}`;
      break;
    case "Bike":
      result.title = "Show Bike";
      result.link = `/bikes/${note.noteable_id}`;
      break;
    default:
      console.log("unhandled noteable type", note.noteable_type);
  }
  return result;
};

class NoteDetails extends Component<
  INoteDetailsProps & IConnectedRoutedComponentProps,
  INoteDetailsState
> {
  public state: INoteDetailsState = { note: null, error: null };
  public deleteNote = this._deleteNote.bind(this);
  public async componentDidMount() {
    const noteId = this.props.routerProps.match.params["id"];
    if (noteId) {
      try {
        const noteResponse = await ApiService.get(ENDPOINTS.noteId(noteId));
        if (noteResponse.data) {
          this.setState({ note: noteResponse.data, error: null });
        }
      } catch (error) {
        this.setState({ error });
      }
    }
  }
  public render() {
    if (this.state.error) {
      return <div>There was an error finding this note.</div>;
    }
    const { note } = this.state;
    if (note) {
      const _linkForNote = linkForNote(note);
      return (
        <>
        <Grid container={true}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width:'100%',
            }}
          >
            <Typography variant="h3">
              {DateFormatter.defaultDateFormat(note.created_at)}
            </Typography>
            <Button variant="text" component={Link} to={_linkForNote.link}>
              {_linkForNote.title}
            </Button>
          </div>
        </Grid>
        <Grid>
          <Typography variant="caption">
            By: {note.author.firstname} {note.author.lastname}
          </Typography>

          <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
            {note.note}
          </Typography>

          <Divider variant="middle" />

          {note.media.map((mediaObject: IMedia) => (
            <CardMedia
              key={mediaObject.id}
              component="img"
              alt={`Image for note ${note.id}`}
              image={mediaObject.signedUrl}
              title={`Image for note ${note.id}`}
            />
          ))}
        </Grid>
        <Grid>
          <Button variant="outlined" style={{color:"red", marginTop: "16px"}} onClick={this.deleteNote} >
            Delete
          </Button>
        </Grid>
        </>
      );
    }
    return <Loading isLoading={true} />;
  }
  private  _deleteNote(){
    // eslint-disable-next-line no-restricted-globals
    const yesno = confirm("Are you sure you want to delete this note?")
    if(yesno){
      (async()=>{
        const _linkForNote = linkForNote(this.state.note);
        const deleted = await ApiService.delete(ENDPOINTS.noteId(this.state.note.id.toString()))
        console.log(deleted,'notedelete');
        if(deleted.status === 200){
          this.props.routerProps.history.push(`${_linkForNote.link}`)
        }
      })()        
    }
  }
}
const mapStateToProps = (state: IStore): IStoreProps => ({
  selectedServiceRequest: state.serviceRequest.selectedServiceRequest
});
const details = withConnection(NoteDetails, mapStateToProps);
export default details;
