import { Link, RouteComponentProps } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Snackbar, { SnackbarVariant } from "components/Snackbar";
import { useDispatch, useSelector } from "react-redux";

import ApplicationError from "stores/errors/models/ApplicationError";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import IBike from "stores/bikes/models/IBike";
import { IConnectedRoutedComponentProps } from "../HOC/ConnectedRouted";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import IStore from "stores/IStore";
import Loading from "components/Loading/Loading";
import PrintIcon from "@material-ui/icons/Print";
import RMSServiceRequestAction from "stores/rms-service-requests/RMSServiceRequestAction";
import ReactToPrint from "react-to-print";
import ServiceRequestContactInfo from "components/ServiceRequest/ServiceRequestContactInfo";
import ServiceRequestDetailsComponent from "components/ServiceRequest/ServiceRequestDetailsComponent";
import ServiceRequestDetailsPrint from "components/ServiceRequest/ServiceRequestDetailsPrint";
import Typography from "@material-ui/core/Typography";
import { groupBy } from "lodash";
import { makeStyles } from "@material-ui/styles";

interface IServiceRequestDetailsProps {
  locations?: IRMSLocation[];
  searchResults: IBike[];
  serviceRequest: IRMSServiceRequest;
  serviceRequestUpdate: string;
  serviceRequestError: ApplicationError;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "16px"
  }
}));

const ServiceRequestDetails = (props: IServiceRequestDetailsProps & IConnectedRoutedComponentProps & RouteComponentProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const printRef = useRef();

  const serviceRequest = useSelector((state: IStore) => state.serviceRequest.selectedServiceRequest);
  const serviceRequestError = useSelector((state: IStore) => state.serviceRequest.error);
  const serviceRequestUpdate = useSelector((state: IStore) => state.serviceRequest.updating);

  const [currentId, setCurrentId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [bikeTasks, setBikeTasks] = useState(null);

  const getParam = useCallback(
    (name: string) => {
      return props.match.params[name];
    },
    [props.match.params]
  );

  const getSRDetails = useCallback(
    (serviceRequestId: string) => {
      if (serviceRequestId) {
        dispatch(RMSServiceRequestAction.getServiceRequest(serviceRequestId));
      }
      return serviceRequestId;
    },
    [dispatch]
  );

  useEffect(() => {
    const id = getParam("id");

    if (id !== currentId) {
      setCurrentId(id);
      getSRDetails(id);
    }

    if (serviceRequestUpdate === "success") {
      setSnackbarVariant(serviceRequestUpdate);
      setSnackbarOpen(true);
      setSnackbarMessage("Success");
    }

    if (serviceRequestError) {
      setSnackbarMessage(serviceRequestError.error.errorMessage);
      setSnackbarOpen(true);
      setSnackbarVariant("error");
    }

    if (serviceRequest) {
      let tasks = serviceRequest.tasks.slice(0);

      const groupedTasks: any = groupBy(tasks, task => {
        return task.bike_id;
      });
      setBikeTasks(groupedTasks);
    }
  }, [currentId, serviceRequest, serviceRequestError, serviceRequestUpdate, getParam, getSRDetails]);

  const serviceRequestDetails = (serviceRequest: IRMSServiceRequest) => {
    return <ServiceRequestDetailsComponent bikeTasks={bikeTasks} serviceRequest={serviceRequest} />;
  };

  const contactInfo = (serviceRequest: IRMSServiceRequest) => {
    return <ServiceRequestContactInfo serviceRequest={serviceRequest} />;
  };

  const details = (serviceRequest: IRMSServiceRequest) => {
    const { location } = serviceRequest;
    const msg = props.data && props.data["serviceRequestUpdate"] === "error" ? "ERROR" : "SUCCESS";

    return (
      <Box className={classes.root}>
        <Grid container={true} direction="row" spacing={1}>
          <Grid item={true} sm={6}>
            <Typography variant="h2">Service Request {serviceRequest.id}</Typography>
          </Grid>
          <Grid item={true} sm={6}>
            <Grid container={true} justify="flex-end">
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    <PrintIcon /> Print
                  </Button>
                )}
                content={() => printRef.current}
              />
            </Grid>
          </Grid>
        </Grid>

        {location && (
          <Typography>
            Location:{" "}
            <Button to={`/locations/${location.id}`} component={Link}>
              {location.name}
            </Button>
          </Typography>
        )}

        <Typography>
          Status: {serviceRequest.status.toUpperCase()}&nbsp;{serviceRequest.status === "past-due" && <ErrorIcon color="error"></ErrorIcon>}
        </Typography>
        {serviceRequest.createdBy && (
          <Typography>
            Created By: {serviceRequest.createdBy.firstname} {serviceRequest.createdBy.lastname}
          </Typography>
        )}

        {serviceRequest.cancelation_reason && <span>Canceled: {serviceRequest.cancelation_reason}</span>}
        {contactInfo(serviceRequest)}
        {serviceRequestDetails(serviceRequest)}

        <Snackbar
          message={snackbarMessage || msg}
          isOpen={snackbarOpen}
          variant={snackbarVariant as SnackbarVariant}
          onClose={() => {
            dispatch(RMSServiceRequestAction.updateServiceRequestStatus(null));
            dispatch(RMSServiceRequestAction.error(null));
            setSnackbarOpen(false);
          }}
        />

        <div style={{ display: "none" }}>
          <ServiceRequestDetailsPrint ref={printRef} serviceRequest={serviceRequest} bikeTasks={bikeTasks} />
        </div>
      </Box>
    );
  };

  if (!serviceRequest) {
    return (
      <>
        <Loading isLoading={true} />
      </>
    );
  }

  return <>{details(serviceRequest)}</>;
};

export default ServiceRequestDetails;
