import { ICommonData, IWarrantyCodeConfigProps } from "./models/CommonData";

import IAction from "../IAction";
import IServiceRequestTask from "stores/tasks/models/ITask";

export default class CommonDataAction {
  public static readonly GET_COMMON_DATA: string = "CommonDataAction.GET_COMMON_DATA";
  public static readonly SET_COMMON_DATA: string = "CommonDataAction.SET_COMMON_DATA";
  public static readonly SET_COMMON_DATA_TASKS: string = "CommonDataAction.SET_COMMON_DATA_TASKS";
  public static readonly SET_COMMON_DATA_EVENTS: string = "CommonDataAction.SET_COMMON_DATA_EVENTS";
  public static readonly SET_COMMON_DATA_WCG_CONFIGS: string = "CommonDataAction.SET_COMMON_DATA_WCG_CONFIGS";
  public static readonly ERROR: string = "CommonDataAction.ERROR";

  public static getCommonData(): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: null,
      type: CommonDataAction.GET_COMMON_DATA
    };
  }

  public static setCommonData(commonData: ICommonData): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: commonData,
      type: CommonDataAction.SET_COMMON_DATA
    };
  }
  public static setCommonDataTasks(commonData: IServiceRequestTask): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: commonData,
      type: CommonDataAction.SET_COMMON_DATA_TASKS
    };
  }
  public static setCommonDataEvents(commonData: IServiceRequestTask): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: commonData,
      type: CommonDataAction.SET_COMMON_DATA_EVENTS
    };
  }
  public static setCommonDataWCG(commonData: IWarrantyCodeConfigProps): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: commonData,
      type: CommonDataAction.SET_COMMON_DATA_WCG_CONFIGS
    };
  }
  public static error(error: Error): IAction<string> {
    return {
      error: true,
      meta: null,
      payload: error,
      type: CommonDataAction.ERROR
    };
  }
}
