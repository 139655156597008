import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AppModal from "components/common/Modal/AppModal";
import BikeAction from "stores/bikes/BikeAction";
import Box from "@material-ui/core/Box";
import { COLORS } from "theme/theme";
import DeleteIcon from "@material-ui/icons/Delete";
import EditCustomer from "./EditCustomer";
import EditIcon from "@material-ui/icons/Edit";
import EditMileage from "./EditMileage";
import IStore from "stores/IStore";
import IconButton from "@material-ui/core/IconButton";
import Loading from "components/Loading/Loading";
import { RouteComponentProps } from "react-router-dom";
import Snackbar from "components/Snackbar";
import Timeline from "components/Timeline/Timeline";
import Typography from "@material-ui/core/Typography";
import { distanceMetricToImperial } from "utilities/mileage-conversion";
import { makeStyles } from "@material-ui/styles";

interface IBikeDetails {}

type ISnackbarVariant = "error" | "info" | "success" | "warning";
type IUpdateType = "edit" | "delete";

const useStyles = makeStyles(() => ({
  box: {
    borderBottom: "solid 1px #DDD",
    marginBottom: "12px",
    paddingBottom: "12px"
  },
  noCustomer: {
    display: "block",
    color: COLORS.ERROR
  }
}));

const BikeDetails = (props: IBikeDetails & RouteComponentProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const selectedBike = useSelector((state: IStore) => state.bike.selectedBike);
  const timeline = useSelector((state: IStore) => state.bike.timeline);

  const [updateType, setUpdateType] = useState("edit");
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false);
  const [editMileageModalOpen, setEditMileageModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const getParam = useCallback(
    (name: string) => {
      return props.match.params[name];
    },
    [props.match.params]
  );

  useEffect(() => {
    const bikeId = getParam("id");
    if (bikeId) {
      dispatch(BikeAction.getBike(bikeId));
    }
  }, [dispatch, getParam]);

  const handlePageUpdates = (error: any) => {
    setEditCustomerModalOpen(false);
    setEditMileageModalOpen(false);
    if (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error);
      setSnackbarVariant("error");
    } else {
      const bikeId = getParam("id");
      if (bikeId) {
        dispatch(BikeAction.getBike(bikeId));
        setSnackbarOpen(true);
        setSnackbarMessage("Bike successfully updated.");
        setSnackbarVariant("success");
      }
    }
  };

  const handleSnackBarClose = () => {
    setSnackbarOpen(false);
  };

  if (selectedBike) {
    return (
      <>
        <Box className={classes.box}>
          <Typography variant={"h2"}>Serial #: {selectedBike.serial_number}</Typography>
          <Typography style={{ alignItems: "center", display: "flex" }} variant={"subtitle1"}>
            Mileage: {selectedBike.mileage ? distanceMetricToImperial(selectedBike.mileage,true) : "Unknown"} miles
            <IconButton
              onClick={() => {
                setEditMileageModalOpen(true);
              }}
              aria-label="edit-mileage"
              color="primary">
              <EditIcon />
            </IconButton>
          </Typography>

          {selectedBike.model && (
            <>
              <Typography variant={"subtitle1"}>Model</Typography>
              {selectedBike.model}
            </>
          )}

          {selectedBike.month_manufactured && selectedBike.year_model && (
            <>
              <Typography variant={"subtitle1"}>Manufacture Date</Typography>
              {selectedBike.month_manufactured} {selectedBike.year_model}
            </>
          )}

          {selectedBike.manufacturer_model && (
            <>
              <Typography variant={"subtitle1"}>Manufacturer Model</Typography>
              {selectedBike.manufacturer_model}
            </>
          )}

          {selectedBike.manufacturer_sku && (
            <>
              <Typography variant={"subtitle1"}>Manufacturer SKU</Typography>
              {selectedBike.manufacturer_sku}
            </>
          )}

          {selectedBike.serial_number_motor && (
            <>
              <Typography variant={"subtitle1"}>Motor SN</Typography>
              {selectedBike.serial_number_motor}
            </>
          )}

          {selectedBike.serial_number_battery && (
            <>
              <Typography variant={"subtitle1"}>Battery SN</Typography>
              {selectedBike.serial_number_battery}
            </>
          )}
        </Box>

        <Box className={classes.box}>
          <Typography variant={"h4"}>
            Customer Info
            <IconButton
              aria-label="edit-customer"
              size="small"
              onClick={() => {
                setUpdateType("edit");
                setEditCustomerModalOpen(true);
              }}>
              <EditIcon color="secondary" fontSize="small" />
            </IconButton>
            {selectedBike && selectedBike.shopify_customer_id && (
              <IconButton
                aria-label="delete-customer"
                size="small"
                onClick={() => {
                  setUpdateType("delete");
                  setEditCustomerModalOpen(true);
                }}>
                <DeleteIcon color="secondary" fontSize="small" />
              </IconButton>
            )}
          </Typography>

          {selectedBike.customer ? (
            <>
              {selectedBike.customer.email && (
                <>
                  <Typography variant="subtitle1">Email</Typography>
                  <Typography variant="body1">{selectedBike.customer.email}</Typography>
                </>
              )}

              {selectedBike.customer.phone && (
                <>
                  <Typography variant="subtitle1">Phone</Typography>
                  <Typography variant="body1">{selectedBike.customer.phone}</Typography>
                </>
              )}

              {selectedBike.customer.shopifyData && selectedBike.customer.shopifyData.default_address && (
                <>
                  <Typography variant="subtitle1">Address</Typography>
                  <Typography variant="body1">
                    {selectedBike.customer.shopifyData && selectedBike.customer.shopifyData.default_address &&
                      <>
                        {selectedBike.customer.shopifyData.default_address.address1 &&<>{ selectedBike.customer.shopifyData.default_address.address1 }<br /></>}
                        {selectedBike.customer.shopifyData.default_address.address2 && <>{ selectedBike.customer.shopifyData.default_address.address2 }<br /></>}
                        {selectedBike.customer.shopifyData.default_address.locality && selectedBike.customer.shopifyData.default_address.locality}
                        {(selectedBike.customer.shopifyData.default_address.locality && (selectedBike.customer.shopifyData.default_address.region || selectedBike.customer.shopifyData.default_address.postcode)) && ", "}
                        {selectedBike.customer.shopifyData.default_address.region && selectedBike.customer.shopifyData.default_address.region}
                        {(selectedBike.customer.shopifyData.default_address.region && (selectedBike.customer.shopifyData.default_address.postcode)) && ", "}
                        {selectedBike.customer.shopifyData.default_address.postcode && selectedBike.customer.shopifyData.default_address.postcode}
                        {(selectedBike.customer.shopifyData.default_address.locality || selectedBike.customer.shopifyData.default_address.region || selectedBike.customer.shopifyData.default_address.postcode) && <br />}
                        {selectedBike.customer.shopifyData.default_address.country && selectedBike.customer.shopifyData.default_address.country}
                      </>
                    }
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Typography variant="body1" className={classes.noCustomer}>
              No customer is currently assigned to this bike!
            </Typography>
          )}
        </Box>

        <Box className={classes.box}>
          <Typography variant={"h4"}>Timeline</Typography>
          {timeline ? <Timeline timeline={timeline} /> : null}
        </Box>

        <Snackbar isOpen={snackbarOpen} message={snackbarMessage} onClose={handleSnackBarClose} variant={snackbarVariant as ISnackbarVariant} />

        <AppModal
          open={editCustomerModalOpen}
          cancel={() => {
            setEditCustomerModalOpen(false);
          }}
          maxWidth="lg">
          {editCustomerModalOpen && <EditCustomer bike={selectedBike} onUpdate={(error: any) => handlePageUpdates(error)} updateType={updateType as IUpdateType} />}
        </AppModal>

        <AppModal
          open={editMileageModalOpen}
          cancel={() => {
            setEditMileageModalOpen(false);
          }}
          maxWidth="lg">
          <EditMileage key={new Date().getTime()} bike={selectedBike} onUpdate={(error: any) => handlePageUpdates(error)} updateType="edit" />
        </AppModal>
      </>
    );
  }

  return <Loading isLoading={true} />;
};

export default BikeDetails;
