import "react-vertical-timeline-component/style.min.css";

import * as React from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";

import AuditTimelineObject from "./AuditTimelineObject";
import BikeTimelineObject from "./BikeTimelineObject";
import BuildIcon from "@material-ui/icons/BuildRounded";
import CustomerTimelineObject from "./CustomerTimelineObject";
import DateFormatter from "utilities/date-formatter";
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import IAudit from "stores/audits/models/IAudit";
import IBike from "stores/bikes/models/IBike";
import ICustomer from "../../stores/customers/models/ICustomer";
import INote from "stores/notes/models/INote";
import { IOrder } from "../../stores/orders/models/IOrder";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import { ITimeline } from "stores/timeline/models/Timeline";
import IZendesk from "stores/zendesks/models/IZendesk";
import NoteTimelineObject from "./NoteTimelineObject";
import OrderTimelineObject from "./OrderTimelineObject";
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ServiceRequestTimelineObject from "./ServiceRequestTimelineObject";
import TimeIcon from '@material-ui/icons/AvTimer';
import ZendeskTimelineObject from "./ZendeskTimelineObject";

export interface ITimelineProps {
  timeline: ITimeline;
  customer?: ICustomer;
}

class Timeline extends React.Component<ITimelineProps> {
  public render() {
    const { data } = this.props.timeline;
    if (!data) {
      return <div>Timeline not ready...</div>;
    }
    return (
      <VerticalTimeline layout="1-column">
        {data.map((obj, idx) => {
          let tmComp = <div/>;
          let dateText = "xxx";
          let icon=<HelpIcon/>
          const unknown: any = obj.data;
          dateText = `${DateFormatter.detailedDateFormat(unknown.created_at)}`;
          switch (obj.objectType) {
            case "audits":
              icon = <TimeIcon/>
              const audit: IAudit = unknown as IAudit;
              tmComp = <AuditTimelineObject data={audit} />;
              break;
            case "bikes":
              icon = <DirectionsBikeIcon/>
              const bike: IBike = unknown as IBike;
              tmComp = <BikeTimelineObject data={bike} />;
              break;
            case "customers":
              icon=<PersonIcon/>
              const customer: ICustomer = unknown as ICustomer;
              tmComp = <CustomerTimelineObject data={customer} />;
              break;
            case "notes":
              icon = <EditIcon/>
              const note: INote = unknown as INote;
              tmComp = <NoteTimelineObject data={note} />;
              break;
            case "orders":
              icon =<ReceiptIcon/>
              const order: IOrder = unknown as IOrder;
              tmComp = <OrderTimelineObject customer={this.props.customer} data={order} />;
              break;
            case "rpb_services_requests":
              icon=<BuildIcon />
              const sr: IRMSServiceRequest = unknown as IRMSServiceRequest;
              tmComp = <ServiceRequestTimelineObject data={sr} />;
              break;
            case "zendesks":
              icon = <PermPhoneMsgIcon/>
              const zd: IZendesk = unknown as IZendesk;
              tmComp = <ZendeskTimelineObject data={zd} />;
              break;
            default:
              tmComp = <div>Undefined component for {obj.objectType}</div>;
          }
          return (
            <VerticalTimelineElement
              key={idx}
              date={dateText}
              className="vertical-timeline-element--work"
              contentStyle={{ background: "rgb(235, 235, 235)" }}
              contentArrowStyle={{ borderRight: "7px solid rgb(235, 235, 235)" }}
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={icon}
            >
              <div>
              {tmComp}
              </div>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    );
  }
}

export default Timeline;
