import { Paper, Table } from "@material-ui/core";

import React from "react";
import { useStyles } from './style';

interface IRMSTableProps{}

const  RMSTable : React.FunctionComponent<IRMSTableProps>  = (props)=>{
    const classes = useStyles(props)
    return (
        <Paper className={classes.tableContainer} >
            <Table className={classes.table}>
                {props.children}
            </Table>
        </Paper>
    )
}
export default RMSTable