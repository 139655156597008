import './app.css'

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './containers/app/App';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root'),
);

if ((module as any).hot && process.env.NODE_ENV !== 'production') {
  (module as any).hot.accept('./containers/app', () => {
      ReactDOM.render(
          <Provider store={store}>
              <App />
          </Provider>,
          document.getElementById('root'),
      );
  });
}
