import BikeAction from "./BikeAction";
import IAction from "../IAction";
import IBike from "./models/IBike";

export interface IBikeState {
  bikes: IBike[];
  searchResults: IBike[];
  selectedBike: IBike;
  timeline: any;
  unknownBike: IBike;
}

const initState = (): IBikeState => ({
  bikes: null,
  searchResults: null,
  selectedBike: null,
  timeline: null,
  unknownBike: null
});

export default class BikeReducer {
  public static reducer(state: IBikeState = initState(), action: IAction<any>): IBikeState {
    switch (action.type) {
      case BikeAction.GET_BIKE_SUCCESS:
        return { ...state, selectedBike: action.payload };
      case BikeAction.TIMELINE_SUCCESS:
        return { ...state, timeline: action.payload };
      case BikeAction.SEARCH_RESULTS:
        return { ...state, searchResults: action.payload };
      case BikeAction.SET_UNKNONWN_BIKE:
        return { ...state, unknownBike: action.payload };
      default:
        return state;
    }
  }
}
