import React, { useEffect, useState } from "react";
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Form } from "react-final-form";
import { OnChange } from 'react-final-form-listeners'
import { TextField } from "final-form-material-ui";

export interface IPostalCodes {}

interface IPostalCodesFormValues {
  postalCode: string;
}

interface IPostalCodesFormErrors {
  postalCode: string;
}

type IPostalCodesResults = "success" | "error" | "";

const useStyles = makeStyles(() => ({
  results: {
    marginTop: "16px"
  },
  submitButton: {
    marginTop: "16px"
  }
}));

const PostalCodes = (props: IPostalCodes) => {
  const classes = useStyles(props);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState("" as IPostalCodesResults);

  useEffect(() => {}, [results]);

  const onSubmit = (values: IPostalCodesFormValues) => {
    setIsLoading(true);
    axios.get(`https://peregrine.radpowerdata.com/postalcodes/${values.postalCode}`)
      .then(() => {
       setResults("success");
      })
      .catch((error) => {
        setResults("error");
        console.warn("POSTAL CODE LOOKUP: ", error);
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  const validate = (values: IPostalCodesFormValues) => {
    const errors = {} as IPostalCodesFormErrors;
    if (!values.postalCode) {
      errors.postalCode = 'Required';
    }
    return errors;
  };

  return (
    <>
      <Typography variant="h2">Rad Mobile Service</Typography>
      <Typography variant="h6" gutterBottom={true}>Postal Code Lookup</Typography>

      <Grid item={true} xs={12}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Field label="Postal Code" name="postalCode" component={TextField} type="input" required={true} />
              <OnChange name="postalCode">
                {() => {
                  setResults("");
                }}
              </OnChange>
              <Grid item={true} className={classes.submitButton}>
                <Button variant="contained" color="primary" type="submit" aria-label="check-coverage" disabled={submitting}>
                  Check Coverage!
                </Button>
              </Grid>
              {isLoading ? (
                <Grid item={true} className={classes.results}>
                  <CircularProgress  color="primary" />
                </Grid>
              ) : (
                <Grid item={true} className={classes.results}>
                  {results &&
                    <>
                      {results === "success" &&
                      <Alert severity="success">Good to go!</Alert>}
                      {results === "error" &&
                      <Alert severity="error">No service available.</Alert>}
                    </>
                  }
                </Grid>
              )}
            </form>
          )}
        />
      </Grid>
    </>
  );
};

export default PostalCodes;
