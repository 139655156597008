import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IUser from "stores/user/models/IUser";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

interface ISignInProps {
  user?: IUser;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  image: {
    height: "60vh",
    backgroundImage: "url(https://rad-service.s3-us-west-2.amazonaws.com/images/rms_bg.webp)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const SignIn: React.SFC<ISignInProps> = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={3}>        
        <Grid item xs={12}>
          <div className={classes.paper}>
            {!props.user ? (
              <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography variant="h1" component="h2">
                    Rad Service Center
                  </Typography>
                </div>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
              </>
            ) : (
              <Typography variant="h2">
                Welcome {props.user.firstname}!
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.image}></Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
