import { ICommonData, IWarrantyCodeConfigProps } from "./models/CommonData";

import CommonDataAction from "./CommonActions";
import IAction from "../IAction";
import IServiceRequestTask from "stores/tasks/models/ITask";

export interface ICommonDataState {
  error: Error;
  statusTypes: ICommonData;
  tasks: IServiceRequestTask[];
  events: IServiceRequestTask[];
  warrantyCodeGeneratorConfigs:IWarrantyCodeConfigProps;
}
const initState = (): ICommonDataState => ({
  error: null,
  statusTypes: null,
  tasks: null,
  events: null,
  warrantyCodeGeneratorConfigs:null
});
export default class CommonDataReducer {
  public static reducer(state: ICommonDataState = initState(), action: IAction<any>): ICommonDataState {
    switch (action.type) {
      case CommonDataAction.SET_COMMON_DATA:
        return { ...state, statusTypes: action.payload };
      case CommonDataAction.SET_COMMON_DATA_TASKS:
        return { ...state, tasks: action.payload };
      case CommonDataAction.SET_COMMON_DATA_EVENTS:
        return { ...state, events: action.payload };
      case CommonDataAction.ERROR:
        return { ...state, error: action.payload };
      case CommonDataAction.SET_COMMON_DATA_WCG_CONFIGS:
        return { ...state, warrantyCodeGeneratorConfigs: action.payload };
      default:
        return state;
    }
  }
}
