import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Collapse from '@material-ui/core/Collapse';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { v4 as uuidv4 } from 'uuid';

import RMSTable from "components/Table/RMSTable";
import Snackbar, { SnackbarVariant } from "components/Snackbar";
import ApiService, { ENDPOINTS } from "utilities/api-service";
import DateFormatter from "utilities/date-formatter";

interface IServiceRequestProps {}

const BuiltAndDelivered = (props: IServiceRequestProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastProcessed, setLastProcessed] = useState('');
  const [orders, setOrders] = useState([]);
  const [storeAbbr, setStoreAbbr] = useState('US');
  const [shopifyStore, setShopifyStore] = useState('rad-power-bikes.myshopify.com');
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState(['0']);
  const [currentPage, setCurrentPage] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("info");

  useEffect(() => {
    let url = '';
    if (currentPage === 0) {
      url = `${ENDPOINTS.builtAndDelivered}?store=${storeAbbr}`;
    } else {
      url = `${ENDPOINTS.builtAndDelivered}?store=${storeAbbr}&LastEvaluatedKey=${lastEvaluatedKeys[currentPage]}`;
    }

    ApiService.get(url)
      .then(response => {
        if (response.data && response.data.orders) {
          setOrders(response.data.orders);
          if (response.data.lastEvaluatedKey) {
            if (!lastEvaluatedKeys.includes(response.data.lastEvaluatedKey.id)) {
              const updatedKeys = lastEvaluatedKeys.slice();
              updatedKeys.push(response.data.lastEvaluatedKey.id);
              setLastEvaluatedKeys(updatedKeys);
            }
          }
        } else if (response.data && !response.data.orders) {
          setOrders([]);
          setLastEvaluatedKeys(['0']);
        }
      })
      .catch(error => {
        console.warn("GET B&D ORDERS: ", error);
        setSnackbarMessage("Failed to load B&D orders.");
        setSnackbarOpen(true);
        setSnackbarVariant("error");
      }).then(() => {
        setIsLoading(false);
        setIsProcessing(false);
      });
  }, [currentPage, lastEvaluatedKeys, lastProcessed, storeAbbr]);
 
  const handleChangeStore = (event: any) => {
    setIsLoading(true);
    setLastEvaluatedKeys(['0']);
    setCurrentPage(0);
    const newStore = event.target.value;
    setStoreAbbr(newStore);
    if (newStore === 'US') {
      setShopifyStore('rad-power-bikes.myshopify.com');
    } else if (newStore === 'CA') {
      setShopifyStore('rad-power-bikes-canada.myshopify.com');
    } else if (newStore === 'EU') {
      setShopifyStore('rad-power-bikes-eu.myshopify.com');
    }
  }

  const handleChangePage = (pageNum: number) => {
    setIsLoading(true);
    setCurrentPage(pageNum);
  }

  const processOrder = (orderId: string, storeAbbr: string, postalCode: string) => {
    setIsProcessing(true);
    const uniqueId = uuidv4();

    ApiService.get(ENDPOINTS.orderFulfillment(orderId, storeAbbr, postalCode))
    .then(response => {
      setSnackbarMessage("Order successfully processed.");
      setSnackbarOpen(true);
      setSnackbarVariant("success");
      setLastProcessed(`${response.data.fulfillmentCreate.fulfillment.id}-${uniqueId}`);
    })
    .catch(error => {
      console.warn("ERROR PROCESSING ORDER: ", error);
      setSnackbarMessage("Failed to process order.");
      setSnackbarOpen(true);
      setSnackbarVariant("error");
      setLastProcessed(`error-processing-${orderId}-${uniqueId}`);
    })
  }

  const OrderRow = (props: { order: any }) => {
    const { order } = props;
    const processedAt = order.processed_at ? DateFormatter.defaultDateFormat(order.processed_at.toString()) : "";

    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{order.id && (<Link target="_new" href={`https://${shopifyStore}/admin/orders/${order.id}`}>{order.name}</Link>)}</TableCell>
          <TableCell>
            {order.shipping_address && order.shipping_address.name && order.shipping_address.name}
            {order.shipping_address && order.shipping_address.name && order.contact_email && (<br />)}
            {order.contact_email && order.contact_email}
          </TableCell>
          <TableCell>{order.source_name && order.source_name}</TableCell>
          <TableCell>{processedAt}</TableCell>
          <TableCell>
            {order.shipping_address && order.shipping_address.address1 && <>{order.shipping_address.address1}<br /></>}
            {order.shipping_address && order.shipping_address.address2 && <>{order.shipping_address.address2}<br /></>}
            {order.shipping_address && order.shipping_address.city && <>{order.shipping_address.city}<br /></>}
            {order.shipping_address && order.shipping_address.zip && <>{order.shipping_address.zip}<br /></>}
            {order.shipping_address && order.shipping_address.country && order.shipping_address.country}
          </TableCell>
          <TableCell>
            {order.fulfillment_status !== "fulfilled"&&
            <Button variant="contained" color="primary" type="submit" disabled={isProcessing} onClick={() => processOrder(order.id, storeAbbr, order.shipping_address.zip)}>
              Process
            </Button>}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography>Line Items</Typography>
                <RMSTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Qty</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(order.line_items && order.line_items.length > 0) ? (order.line_items.map((li: any) => {
                      return (
                        <TableRow key={li.id}>
                          <TableCell>{li.quantity.toString()}</TableCell>
                          <TableCell>{li.sku}</TableCell>
                          <TableCell>{li.name}</TableCell>
                        </TableRow>
                      )
                    })) : (
                      <Typography>No line items for this order.</Typography>
                    )}
                  </TableBody>
                </RMSTable>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <Typography variant="h2" gutterBottom={true}>Built and Delivered</Typography>

      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <RMSTable>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7}>
                <Select
                  id="store-select"
                  value={storeAbbr}
                  onChange={handleChangeStore}
                  displayEmpty={false}
                >
                  <MenuItem value="US">US</MenuItem>
                  <MenuItem value="CA">CA</MenuItem>
                  <MenuItem value="EU">EU</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Order Placed</TableCell>
              <TableCell>Ship To</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orders && orders.length > 0) ? (orders.map((order: any) => {
              return (
                <OrderRow order={order} key={order.id} />
              )
            })) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography variant="body1">No results found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TableCell align="left">
              {orders && orders.length > 0 && currentPage !== 0 &&
              <IconButton onClick={() => handleChangePage(currentPage - 1)}><ArrowBackIos fontSize="small" /></IconButton>}
            </TableCell>
            <TableCell colSpan={5}></TableCell>
            <TableCell align="right">
              {orders && orders.length > 0 && lastEvaluatedKeys[currentPage + 1] !== "last" &&
              <IconButton onClick={() => handleChangePage(currentPage + 1)}><ArrowForwardIos fontSize="small" /></IconButton>}
            </TableCell>
          </TableRow>
        </TableFooter>
        </RMSTable>
      )}
      <Snackbar
        isOpen={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
        variant={snackbarVariant as SnackbarVariant}
      />
    </>
  );
};

export default BuiltAndDelivered;
