import { ForkEffect, all, takeLatest } from "redux-saga/effects";

import BikeAction from './bikes/BikeAction';
import BikeSaga from "./bikes/BikeSaga";
import CommonDataAction from "./common/CommonActions";
import CommonDataSaga from "./common/CommonSaga";
import CustomerAction from './customers/CustomerAction';
import CustomerSaga from "./customers/CustomerSaga";
import OrderAction from "./orders/OrderAction";
import OrderSaga from "./orders/OrderSaga";
import RMSLocationAction from "./rms-locations/RMSLocationAction";
import RMSLocationSaga from "./rms-locations/RMSLocationSaga";
import RMSServiceRequestAction from "./rms-service-requests/RMSServiceRequestAction";
import RMSServiceRequestSaga from "./rms-service-requests/RMSServiceRequestSaga";
import SearchAction from "./search/SearchActions";
import SearchSaga from "./search/SearchSaga";
import UserAction from "./user/UserAction";
import UserSaga from "./user/UserSaga";

export default function* rootSaga() {
  const filteredSagas: ForkEffect[] = [
    // USER
    takeLatest(UserAction.REGISTER_TOKEN, UserSaga.registerToken),
    takeLatest(UserAction.ME, UserSaga.findMe),
    takeLatest(UserAction.LOGOUT, UserSaga.logout),
    takeLatest(UserAction.IMPERSONATE, UserSaga.impersonate),
    // Locations
    takeLatest(RMSLocationAction.GET_LOCATIONS, RMSLocationSaga.getLocations),
    takeLatest(RMSLocationAction.GET_LOCATION, RMSLocationSaga.getLocation),

    // service requests
    takeLatest(RMSServiceRequestAction.GET_SERVICE_REQUEST, RMSServiceRequestSaga.getServiceRequest),
    takeLatest(RMSServiceRequestAction.GET_SERVICE_REQUEST_AUDITS, RMSServiceRequestSaga.getServiceRequestAudits),
    takeLatest(RMSServiceRequestAction.GET_SERVICE_REQUESTS, RMSServiceRequestSaga.getServiceRequests),
    takeLatest(RMSServiceRequestAction.UPDATE, RMSServiceRequestSaga.updateServiceRequest),
    takeLatest(RMSServiceRequestAction.TOGGLE_APPROVE, RMSServiceRequestSaga.toggleApproveServiceRequest),

    // bike
    takeLatest(BikeAction.SEARCH, BikeSaga.search),
    takeLatest(BikeAction.GET_BIKE, BikeSaga.getBikeTimeline),

    // customer
    takeLatest(CustomerAction.SEARCH, CustomerSaga.search),
    takeLatest(CustomerAction.GET_CUSTOMER, CustomerSaga.getCustomerTimeline),

    // Order
    takeLatest(OrderAction.GET_ORDER, OrderSaga.getOrder),

    // CommonData
    takeLatest(CommonDataAction.GET_COMMON_DATA, CommonDataSaga.getCommonData),

    // Search
    takeLatest(SearchAction.SEARCH,SearchSaga.onSearch)
  ];

  yield all(filteredSagas);
}
