interface IAddressBuilder {
  setAddress1(): void
  setLocality(): void
  setRegion(): void
  setPostcode(): void
  setCountry(): void
  getAddress(): Object
}

function randomId(length: number) {
  return Math.floor(Math.pow(10, length-1) + Math.random() * 9 * Math.pow(10, length-1));
}

class USAddressBuilder implements IAddressBuilder {
  addressComponents: any
  address1: string
  locality: string
  region: string
  postcode: string
  country: string

  constructor(googleLocData: any) {
    this.addressComponents = googleLocData.address_components;
  }

  extractAddressByType = (desiredType: String) => {
    for (const line of this.addressComponents) {
      if (line.types.includes(desiredType)) {
        return line
      }
    }
    throw new Error('Address not found.')
  }

  setAddress1(): void {
    try{
      const streetNumber = this.extractAddressByType("street_number").long_name
      const route = this.extractAddressByType("route").long_name
      this.address1 = streetNumber + ' ' + route
    } catch (error) {
      this.address1 = ""
    }
  }

  setLocality(): void {
    this.locality = this.extractAddressByType("locality").long_name
  }

  setRegion(): void {
    this.region = this.extractAddressByType("administrative_area_level_1").short_name
  }

  setPostcode(): void {
    this.postcode = this.extractAddressByType("postal_code").short_name
  }

  setCountry(): void {
    this.country = this.extractAddressByType("country").short_name
  }

  getAddress() {
    return {
      id: randomId(8),
      address1: this.address1,
      address2: "",
      locality: this.locality,
      region: this.region,
      postcode: this.postcode,
      country: this.country
    }
  }
}

class AddressDirector {
  private builder: USAddressBuilder

  constructor(builder: USAddressBuilder) {
    this.builder = builder;
  }

  buildUSAddress = () => {
    this.builder.setAddress1()
    this.builder.setLocality()
    this.builder.setRegion()
    this.builder.setPostcode()
    this.builder.setCountry()
    return this.builder.getAddress()
  };
}

export { AddressDirector, USAddressBuilder }
