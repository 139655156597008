import { Button, Grid, Typography } from "@material-ui/core";

import ICustomer from "stores/customers/models/ICustomer";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";

export interface ICustomerDetail {
  customer: ICustomer;
}
const countryForShopifyStore = (store: string) => {
  if(store.includes("can")){
    return "CA";
  }else if(store.includes("eu")){
    return "EU"
  }else{
    return "US"
  }
};

const CustomerDetail = (props: ICustomerDetail) => {
  const { customer } = props;

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Button style={{ paddingLeft: 0 }} to={`/customers/${customer.shopify_customer_id}?store=${countryForShopifyStore(customer.shopify_store)}`} component={Link}>
            <Typography variant={"caption"} style={{ display: "flex", alignItems: "center" }}>
              <PersonIcon />
              &nbsp;{customer.shopifyData.first_name} {customer.shopifyData.last_name}
            </Typography>
          </Button>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="caption">{customer.shopifyData.email && `Email: ${customer.shopifyData.email}`}</Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="caption">{customer.shopifyData.phone && `Phone: ${customer.shopifyData.phone}`}</Typography>
        </Grid>
      </Grid>

      {customer.shopifyData.addresses.map((addy: any) => (
        <div key={addy.id}>
          <Typography variant="caption">
            {addy.address1}
            {addy.address2 && `, ${addy.address2}`}
            {addy.state && `, ${addy.state}, `}
            {addy.province && `, ${addy.province}, `}
            {addy.zip}&nbsp;{addy.country}
          </Typography>
        </div>
      ))}
    </>
  );
};

export default CustomerDetail;
