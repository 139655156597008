import IAction from "../IAction";
import IBike from "./models/IBike";

export default class BikeAction {
  public static readonly GET_BIKE: string = "BikeAction.GET_BIKE";
  public static readonly GET_BIKE_SUCCESS: string = "BikeAction.GET_BIKE_SUCCESS";
  public static readonly SEARCH: string = "BikeAction.SEARCH";
  public static readonly SEARCH_RESULTS: string = "BikeAction.SEARCH_RESULTS";
  public static readonly SET_UNKNONWN_BIKE: string = "BikeAction.SET_UNKNONWN_BIKE";
  public static readonly TIMELINE: string = "BikeAction.TIMELINE";
  public static readonly TIMELINE_SUCCESS: string = "BikeAction.TIMELINE_SUCCESS";
  public static readonly ERROR: string = "BikeAction.ERROR";
  
  public static getBike(id:string): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: id,
      type: BikeAction.GET_BIKE
    }
  }

  public static getBikeSuccess(bike:IBike): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: bike,
      type: BikeAction.GET_BIKE_SUCCESS
    }
  }

  public static getTimeline(id:string): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: id,
      type: BikeAction.TIMELINE
    }
  }

  public static getTimelineSuccess(timeline:any): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: timeline,
      type: BikeAction.TIMELINE_SUCCESS
    }
  }

  public static search(serialText:string): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: serialText,
      type: BikeAction.SEARCH
    }
  }

  public static searchResults(bikes:IBike[]): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: bikes,
      type: BikeAction.SEARCH_RESULTS
    }
  }
  public static setUnknownBike(bike:IBike): IAction<string>{
    return {
      error: true,
      meta: null,
      payload: bike,
      type: BikeAction.SET_UNKNONWN_BIKE
    }
  }
  public static error(error:Error): IAction<string> {
    return {
      payload: error,
      type: BikeAction.ERROR,
    };
  }
}
