import React, { Component } from "react";
import ApiService, { ENDPOINTS } from "utilities/api-service";
import { CalendarProps, NavigateAction, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HelpIcon from "@material-ui/icons/Help";
import DialogTitle from "@material-ui/core/DialogTitle";

import withConnection, { IConnectedRoutedComponentProps, IStoreProps } from "../HOC/ConnectedRouted";
import AppCalendar from "components/AppCalendar";
import Loading from "components/Loading/Loading";
import ServiceRequestList from "components/ServiceRequest/ServiceRequestList/ServiceRequestList";
import ServiceRequestLegend from "components/ServiceRequest/ServiceRequestLegend";
import IRMSServiceRequest, { styleForStatus } from "stores/rms-service-requests/models/IRMSServiceRequest";
import RMSServiceRequestAction from "stores/rms-service-requests/RMSServiceRequestAction";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";

interface IMasterCalendarState {
  hideFilteredIds: number[];
  toggleHideAll: boolean;
  selectedServiceRequest?: IRMSServiceRequest;
  showLegend: boolean;
}

interface IMasterCalendarProps {
  stateToProps: {
    locations: IRMSLocation[];
    serviceRequests: IRMSServiceRequest[];
    updatingCalendar:boolean;
  };
}

class MasterCalendar extends Component<IMasterCalendarProps & IConnectedRoutedComponentProps, IMasterCalendarState> {
  state: Readonly<IMasterCalendarState> = {
    hideFilteredIds: [],
    toggleHideAll: false,
    selectedServiceRequest: null,
    showLegend: false
  };

  private _handleSelectedServiceRequest = async (sr: IRMSServiceRequest) => {
    try {
      const srData = await ApiService.get(ENDPOINTS.serviceRequestsId(sr.id.toString()));
      this.setState({ selectedServiceRequest: srData.data });
    } catch (error) {
      console.log(error, "error for sr request");
    }
  };

  public handleCheckboxChange = this._handleCheckboxChange.bind(this);
  public handleToggleHideAll = this._handleToggleHideAll.bind(this);
  public handleSelectedServiceRequest = this._handleSelectedServiceRequest.bind(this);
  public handleNewDates = this._handleNewDates.bind(this);

  public componentDidMount() {
    // const start = moment();
    // const end = moment().add(7, "days");
    // this.props.dispatch(RMSServiceRequestAction.getServiceRequests({ startTimeStamp: start.toDate().getTime(), endTimeStamp: end.toDate().getTime() }));
    const wednesday = moment(new Date()).startOf("week").add(3, "days").toDate();
    this.getServiceRequestForDate(wednesday);
  }
  
  getServiceRequestForDate = (date: Date) => {
    const start = moment(date).subtract(3, "days").toDate();
    const end = moment(date).endOf("day").add(3, "days").toDate();
    this.props.dispatch(RMSServiceRequestAction.getServiceRequests({ startTimeStamp: start.getTime(), endTimeStamp: end.getTime(), perPage: 1000 }));
  }

  filterData = () => {
    let data: IRMSServiceRequest[] = this.props.data["serviceRequests"];
    const { hideFilteredIds } = this.state;

    if (hideFilteredIds.length > 0) {
      data = data.filter((sr: IRMSServiceRequest) => (hideFilteredIds.includes(sr.service_location_id) ? false : true));
    }
    return data;
  }

  // custom view for each calendar item
  customEventView = (event: any) => {
    const { resource } = event.event;
    const sr: IRMSServiceRequest = resource as IRMSServiceRequest;
    const { locations } = this.props.data;

    if (!locations) {
      return null;
    }

    const locationFilter = locations.filter((loc: IRMSLocation) => loc.id === sr.service_location_id);

    if (locationFilter && locationFilter.length > 0) {
      return (
        <div onClick={() => this.handleSelectedServiceRequest(sr)} style={styleForStatus(sr)}>
          <>
            <Typography variant={"caption"}>{locationFilter[0].name}</Typography>
          </>
          <>{event.start}</>
          <>{event.title}</>
        </div>
      );
    }

    return null;
  };

  handleCloseLegend = () => {
    this.setState({ showLegend: false });
  }

  public render() {
    const data = this.filterData();
    const { locations,updatingCalendar } = this.props.data;

    if (data) {
      const globalizeLocalizer = momentLocalizer(moment);
      const calProps: CalendarProps = {
        localizer: globalizeLocalizer
      };

      return (
        <>
          <Grid container={true}>
            <Grid xs={12} item={true}>            
              <Typography variant="h2">Master Calendar {updatingCalendar && <Loading isLoading={true}/>}</Typography>            
            </Grid>

            <Grid container={true} item={true}>
              <Grid container={true}>
                <Grid xs={6} item={true}>
                  <FormGroup row={true}>
                    <FormControlLabel
                      onChange={this.handleToggleHideAll}
                      control={<Checkbox checked={this.state.toggleHideAll} value={this.state.toggleHideAll ? 1 : 0} />}
                      label={this.state.toggleHideAll ? "Show All" : "Hide All"}
                    />
                  </FormGroup>
                </Grid>

                <Grid xs={6} item={true}>
                  <Grid container={true} justify="flex-end">
                    <Button variant="outlined" onClick={() => this.setState({ showLegend: true })}>
                      <HelpIcon color="primary" /> Legend
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} item={true}>
              <Divider />
            </Grid>

            {locations && (
              <Grid xs={12} item={true}>
                <FormGroup row={true}>
                  {locations.map((location: IRMSLocation) => {
                    const included = this.state.hideFilteredIds.includes(location.id);

                    return (
                      <FormControlLabel key={location.id} onChange={this.handleCheckboxChange} control={<Checkbox checked={included ? false : true} value={location.id} />} label={location.name} />
                    );
                  })}
                </FormGroup>
              </Grid>
            )}

            <Grid style={{ height: 800, overflowY: "scroll" }} xs={12} item={true}>
              <AppCalendar onNavigate={(newDate:Date,action:NavigateAction)=>{
                this.getServiceRequestForDate(moment(newDate).startOf("day").toDate());
              }} customEventView={this.customEventView} serviceRequests={data} calendarProps={calProps} />
              <Dialog fullScreen={true} open={this.state.selectedServiceRequest != null} keepMounted={true}>
              <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({ selectedServiceRequest: null });
                    }}
                    color="primary">
                    Done
                  </Button>
                </DialogActions>
                {this.state.selectedServiceRequest && (
                  <>                  
                    <DialogContent>
                      <ServiceRequestList serviceRequests={[this.state.selectedServiceRequest]} />
                    </DialogContent>
                  </>
                )}
                
              </Dialog>
            </Grid>
          </Grid>

          <Dialog onClose={this.handleCloseLegend} open={this.state.showLegend}>
            <DialogTitle>Service Requests Legend</DialogTitle>
            <DialogContent><ServiceRequestLegend /></DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseLegend} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
    return <Loading isLoading={true} />;
  }

  private _handleCheckboxChange(e: any) {
    const val = parseInt(e.target.value);
    const { hideFilteredIds } = this.state;

    let nw: number[] = hideFilteredIds;
    if (hideFilteredIds.includes(val)) {
      const remove = hideFilteredIds.filter(id => id !== val);
      nw = remove;
    } else {
      nw.push(val);
    }
    this.setState({ hideFilteredIds: nw, toggleHideAll: false });
  }

  private _handleToggleHideAll(e: any) {
    const { toggleHideAll } = this.state;
    const { locations } = this.props.data;

    if (locations) {
      this.setState({
        toggleHideAll: !toggleHideAll,
        hideFilteredIds: !toggleHideAll ? locations.map((loc: IRMSLocation) => loc.id) : []
      });
    }
  }

  private _handleNewDates(){

  }
}
const mapStateToProps = (state: IStore): IStoreProps => ({
  locations: state.location.locations,
  serviceRequests: state.serviceRequest.masterCalendarServiceRequests,
  updatingCalendar: state.serviceRequest.updatingCalendar,
});

const details = withConnection(MasterCalendar, mapStateToProps);

export default details;
