import Axios from "axios";
import IStore from "stores/IStore";
import store from "../store";
const HOST = process.env.REACT_APP_API_ENDPOINT;
const API_V1 = `${HOST}/api/v1/rpb-hub`
export const ENDPOINTS = {
  auth: `${HOST}/auth/id-token`,
  bikeSearch:(query:string) => `${API_V1}/bikes?query=${query}`,
  bikes: `${API_V1}/bikes`,    
  bikesId: (id:string) => `${API_V1}/bikes/${id}`,
  bikesIdNotes: (id:string) => `${API_V1}/bikes/${id}/notes`,
  bikesIdTimeline: (id:string) => `${API_V1}/bikes/${id}/timeline`,
  builtAndDelivered: `${API_V1}/built-and-delivered`,
  customerId: (id:string,store:string = "US") => `${API_V1}/customers/${id}?store=${store}`,
  customerIdTimeline: (id:string,store:string = "US") => `${API_V1}/customers/${id}/timeline?store=${store}`,
  customerSearch:(query:string, store: string = "US")=> `${API_V1}/customers?query=${query}&store=${store}&limit=25`,
  customers: `${API_V1}/customers`,
  dataTypes: `${API_V1}/data-types`,
  locations: `${API_V1}/service-locations`,
  locationsId: (id:string) => (`${API_V1}/service-locations/${id}`),
  logout: `${HOST}/auth/logout`,  
  me: `${API_V1}/me`,  
  noteId: (id:string) => `${API_V1}/notes/${id}`,
  orderId: (id:string) => `${API_V1}/orders/${id}`,
  orderFulfillment: (orderId: string, storeAbbr: string, postalCode: string) => `${API_V1}/orders/${orderId}/fulfillments/${storeAbbr}/${postalCode}`,
  search: `${API_V1}/search`,
  serviceRequests: `${API_V1}/service-requests`,
  serviceRequestsId: (id:string) => (`${API_V1}/service-requests/${id}`),
  serviceRequestsIdAudits: (id:string) => (`${API_V1}/service-requests/${id}/audits`),
  serviceRequestsIdNotes: (id:string) => (`${API_V1}/service-requests/${id}/notes`),
  serviceRequestsIdTaskReassignment: (id:string) => (`${API_V1}/service-requests/${id}/tasks/reassign`),
  serviceTasks: `${API_V1}/service-tasks`,
  users: `${API_V1}/users`,
  wcg: `${API_V1}/utility/warranty-code`,
};

const axiosConfig = (options: any,upload:boolean = false) => {
  const config = {
    data: options.data || null,
    headers: {
      "Content-Type":"application/json"
    },
    method: options.method || "GET",
    url: options.url
  };
  if (options.token) {
    config.headers['Authorization'] = `Bearer ${options.token}`
  }
  if(upload){
    config.headers["Content-Type"] = 'multipart/form-data'
  }
  return config;
};

export interface IGoogleResponse {
  tokenId: string;
  accessToken: string;
}

export default class ApiService {
  public static async registerWithTokenId(
    object: IGoogleResponse
  ): Promise<any> {
    const config = axiosConfig({
      data: { tokenId: object.tokenId },
      method: "POST",
      token: object.accessToken,
      url: ENDPOINTS.auth
    });
    const response = await Axios(config);
    return response;
  }

  public static async get(url: string): Promise<any> {
    // grab current state
    const state: IStore = store.getState();
    const config = axiosConfig({
      method: "GET",
      token: state.user.tokenData.accessToken,
      url
    });
    const response = await Axios(config);
    return response;
  }

  public static async post(url: string,data?:any,upload:boolean = false): Promise<any> {
    // grab current state
    const state: IStore = store.getState();
    const config = axiosConfig({
      data,
      method: "POST",
      token: state.user.tokenData.accessToken,
      url,
    },upload);
    const response = await Axios(config);
    return response;
  }

  public static async patch(url: string,data?:any): Promise<any> {
    // grab current state
    const state: IStore = store.getState();
    const config = axiosConfig({
      data,
      method: "PATCH",
      token: state.user.tokenData.accessToken,
      url,
    });
    const response = await Axios(config);
    return response;
  }

  public static async delete(url: string): Promise<any> {
    // grab current state
    const state: IStore = store.getState();
    const config = axiosConfig({
      method: "DELETE",
      token: state.user.tokenData.accessToken,
      url,
    });
    const response = await Axios(config);
    return response;
  }
}
