import * as React from "react";

import { Dispatch } from "redux";
import { GoogleLogin } from "react-google-login";
import IAction from "../../stores/IAction";
import { IDispatchToProps } from "interfaces";
import IStore from "../../stores/IStore";
import IUser from "stores/user/models/IUser";
import UserAction from "stores/user/UserAction";
import { connect } from "react-redux";

interface IState {}
interface IProps {}
interface IStateToProps {
  token: any;
  user: IUser;
}


const mapStateToProps = (state: IStore): IStateToProps => ({
  token: state.user.tokenData,
  user: state.user.userData
});
const mapDispatchToProps = (
  dispatch: Dispatch<IAction<any>>
): IDispatchToProps => ({
  dispatch
});

class AuthButton extends React.Component<
  IStateToProps & IDispatchToProps & IProps,
  IState
> {
  public responseGoogle = this._responseGoogle.bind(this);

  public render(): JSX.Element {
    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Sign in with Google"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    );
  }

  private async _responseGoogle(response: any) {
    this.props.dispatch(UserAction.registerToken(response));
  }
}

export default connect(  
  mapStateToProps,
  mapDispatchToProps
)(AuthButton);
