import { Button, Collapse } from "@material-ui/core";

import AllServiceRequests from "@material-ui/icons/Assignment";
import AppBar from "@material-ui/core/AppBar";
import AuthButton from "components/AuthButton/AuthButton";
import BlurLinear from "@material-ui/icons/BlurLinear";
import { COLORS } from "theme/theme";
import CloseIcon from "@material-ui/icons/Close";
import CreateServiceRequestIcon from "@material-ui/icons/Ballot";
import CssBaseline from "@material-ui/core/CssBaseline";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import { Dispatch } from "redux";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";
import IAction from "stores/IAction";
import { IDispatchToProps } from "interfaces";
import IRMSLocation from "stores/rms-locations/models/IRMSLocation";
import IStore from "stores/IStore";
import IUser from "stores/user/models/IUser";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import MobileServiceIcon from "@material-ui/icons/LocalShipping";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import React from "react";
import MailIcon from '@material-ui/icons/Mail';
import SearchIcon from "@material-ui/icons/Search";
import StoreIcon from "@material-ui/icons/StoreMallDirectory";
import TodayIcon from "@material-ui/icons/Today";
import LocalShipping from "@material-ui/icons/LocalShipping"
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import UserAction from "stores/user/UserAction";
import clsx from "clsx";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core/styles";

interface AppWrapperProps {
  locations?: IRMSLocation[];
  selectedLocation?: IRMSLocation;
  user?: IUser;  
  dispatch?: Function;
}

const AppWrapper: React.FunctionComponent<AppWrapperProps> = props => {
  const classes = useStyles(props);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const listButton = (title: string, icon: any, action?: Function) => (
    <ListItem button key={title} onClick={() => (action ? action() : null)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title.toUpperCase()} />
    </ListItem>
  );
  const handleClick = () => {
    setOpen(!open);
  };
  const privateDrawerLists = () => (
    <>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"sr_create"}
          onClick={() => {
            window.location.href = "/calendar";
          }}>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText primary={"Master Calendar"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"sr_create"}
          onClick={() => {
            window.location.href = "/service-requests/create";
          }}>
          <ListItemIcon>
            <CreateServiceRequestIcon />
          </ListItemIcon>
          <ListItemText primary={"Create Service Request"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"all_service_requests"}
          onClick={() => {
            window.location.href = "/service-requests";
          }}>
          <ListItemIcon>
            <AllServiceRequests />
          </ListItemIcon>
          <ListItemText primary={"View All Service Requests"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"all_service_requests"}
          onClick={() => {
            window.location.href = "/built-and-delivered";
          }}>
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary={"View All Built and Delivered Orders"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"all_service_requests"}
          onClick={() => {
            window.location.href = "/warranty-code-generator";
          }}>
          <ListItemIcon>
            <BlurLinear />
          </ListItemIcon>
          <ListItemText primary={"Warranty Code Generator"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button={true} key={"locations"} onClick={handleClick}>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary={"Locations"} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      {(props.locations && props.locations.length) > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          <List component="div" disablePadding>
            <ListItem button={true} className={classes.nested}>
              {props.locations.map((loc: IRMSLocation) => {
                return (
                  <ListItem
                    onClick={() => {
                      window.location.href = `/locations/${loc.id}`;
                    }}
                    key={loc.id}
                    button={true}>
                    <ListItemIcon>{loc.location_type === "mobile service" ? <MobileServiceIcon /> : <StoreIcon />}</ListItemIcon>
                    <ListItemText primary={loc.name} />
                  </ListItem>
                );
              })}
            </ListItem>
          </List>
        </Collapse>
      )}
      <Divider />
      <List>
        {listButton("Log Out", <PowerSettingsNewIcon />, () => {
          props.dispatch(UserAction.logout());
        })}
      </List>
    </>
  );
  const drawer = (
    <div>
      <div className={classes.toolbar} style={{ backgroundColor: COLORS.BLUE_DARK, textAlign: "center" }}>
        {mobileOpen && (
          <IconButton edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <CloseIcon style={{ color: "white", paddingLeft: 10 }} />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.title}>
          Rad Service Center <DirectionsBikeIcon></DirectionsBikeIcon>
        </Typography>
      </div>
      <Divider />
      <List>
        {listButton("Home", <HomeIcon />, () => {
          window.location.href = "/";
        })}
      </List>
      <Divider />
      <List>
        <ListItem
          button={true}
          key={"postal_codes"}
          onClick={() => {
            window.location.href = "/postal-codes";
          }}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary={"Postal Code Lookup"} />
        </ListItem>
      </List>
      {props.user && privateDrawerLists()}
    </div>
  );
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.mainToolBar}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon className={classes.menuButtonIcon} />
          </IconButton>
          {!props.user ? (
            <AuthButton />
          ) : (
            <div>
              <Button color="secondary" variant="text">
                <IconButton style={{ color: "white" }} size="small" href="/search">
                  <SearchIcon /> Search
                </IconButton>
              </Button>
              <Button color="secondary" variant="text">
                <IconButton style={{ color: "white" }} size="small" href="/service-requests/create">
                  <CreateServiceRequestIcon /> Service Request
                </IconButton>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        variant="persistent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}>
        {drawer}
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: mobileOpen
        })}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  locations: state.location.locations,
  selectedLocation: state.location.selectedLocation,
  user: state.user.userData,
  
});

const mapDispatchToProps = (dispatch: Dispatch<IAction<any>>): IDispatchToProps => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
