import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";


import RMSTable from "components/Table/RMSTable";
import ApiService, { ENDPOINTS } from "utilities/api-service";
import IRMSServiceRequest from "stores/rms-service-requests/models/IRMSServiceRequest";
import DateFormatter from "utilities/date-formatter";

interface IServiceRequestProps {}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

const ServiceRequests = (props: IServiceRequestProps) => {
  const classes = useStyles(props);
  const [isLoading, setIsLoading] = useState(true);
  const [locationFilter, setLocationFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statusTypes, setStatusTypes] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [serviceRequestCount, setServiceRequestCount] = useState(0);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [orderBy, setOrderBy] = useState("created_at");
  const [orderDirection, setOrderDirection] = useState("desc" as "desc" | "asc");
  const [pages, setPages] = useState([])


  useEffect(() => {
    let url = `${ENDPOINTS.serviceRequests}?page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&order=${orderDirection}`
    if (locationFilter) { url += `&location=${locationFilter}` }
    if (statusFilter) { url += `&status=${statusFilter}` }

    if (serviceRequestCount > 0) {
      const maxPage = Math.ceil((serviceRequestCount / rowsPerPage) - 1);
      const pageArray = [];
      for (var i = 0; i <= maxPage; i++) {
        pageArray.push(i);
      }
      setPages(pageArray);
    }

    ApiService.get(url)
      .then(response => {
        if (response.data && response.data.results) {
          setServiceRequestCount(response.data.total);
          setServiceRequests(response.data.results);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.warn("GET SERVICE REQUESTS: ", error);
        setIsLoading(false);
      });

    if (!serviceLocations || serviceLocations.length <= 0) {
      ApiService.get(ENDPOINTS.locations)
        .then(response => {
          if (response.data && response.data.results) {
            setServiceLocations(response.data.results);
          }
        })
        .catch(error => {
          console.warn("GET SERVICE LOCATIONS: ", error);
        });

      ApiService.get(ENDPOINTS.dataTypes)
        .then(response => {
          if (response.data && response.data.serviceRequestStatusTypes) {
            const statusTypes = []
            for (const key of Object.keys(response.data["serviceRequestStatusTypes"])) {
              const statusType = {
                label: key,
                value: response.data["serviceRequestStatusTypes"][key]
              };
              statusTypes.push(statusType);
            }

            setStatusTypes(statusTypes)
          }
        })
        .catch(error => {
          console.warn("GET STATUS TYPES: ", error);
          setIsLoading(false);
        });
    }
  }, [locationFilter, page, rowsPerPage, serviceLocations, statusFilter, orderBy, orderDirection, serviceRequestCount]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setIsLoading(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeLocationFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocationFilter(event.target.value as any);
    setPage(0);
  };

  const handleChangeStatusFilter = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusFilter(event.target.value as any);
    setPage(0);
  };

  const handleOrderDirection = () => {
    if (orderDirection === 'asc') {
      setOrderDirection('desc')
    } else {
      setOrderDirection('asc')
    }
  }

  const handleOrderBy = (column: string) => {
    setOrderBy(column)
    handleOrderDirection()
  }

  const handleSelectPage = (event: any) => {
    setIsLoading(true);
    setPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Typography variant="h2" gutterBottom={true}>Service Requests</Typography>

      {isLoading ? (
        <CircularProgress  color="primary" />
      ) : (
        <RMSTable>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                {serviceLocations && serviceLocations.length > 0 &&
                  <Select
                    id="location-filter-select"
                    value={locationFilter}
                    onChange={handleChangeLocationFilter}
                    displayEmpty={true}
                  >
                    <MenuItem value={""}>All Locations</MenuItem>
                    {serviceLocations.map(loc => {
                      return (
                        <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
                      )
                    })}
                  </Select>
                }
              </TableCell>
              <TableCell>
                {statusTypes && statusTypes.length > 0 &&
                  <Select
                    id="status-filter-select"
                    value={statusFilter}
                    onChange={handleChangeStatusFilter}
                    displayEmpty={true}
                  >
                    <MenuItem value={""}>All Statuses</MenuItem>
                    {statusTypes.map(type => {
                      return (
                        <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                      )
                    })}
                  </Select>
                }
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'contact'}
                  direction={orderBy === 'contact' ? orderDirection : 'asc'}
                  onClick={() => handleOrderBy('contact')}
                >
                  Contact Name
                  {orderBy === 'contact' ? (
                    <span className={classes.visuallyHidden}>
                      {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? orderDirection : 'asc'}
                  onClick={() => handleOrderBy('created_at')}
                >
                  Date Created
                  {orderBy === 'created_at' ? (
                    <span className={classes.visuallyHidden}>
                      {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(serviceRequests && serviceRequests.length > 0 && serviceLocations && serviceLocations.length > 0) && serviceRequests.map((sr: IRMSServiceRequest) => {
              const location = serviceLocations.find(loc => {
                return loc.id === sr.service_location_id
              });

              const createdAt = sr.created_at ? DateFormatter.defaultDateFormat(sr.created_at.toString()) : "";

              return (
                <TableRow key={sr.id}>
                  <TableCell>{sr.id && (<Link to={`/service-requests/${sr.id}`}>{sr.id}</Link>)}</TableCell>
                  <TableCell>{location && location.name}</TableCell>
                  <TableCell>{sr.status && sr.status.toUpperCase()}</TableCell>
                  <TableCell>
                    {sr.contact && sr.contact.firstname ? sr.contact.firstname : ""}
                    {sr.contact && sr.contact.firstname && sr.contact.lastname ? " " : ""}
                    {sr.contact && sr.contact.lastname ? sr.contact.lastname : ""}
                  </TableCell>
                  <TableCell>
                    {createdAt}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={1}>
                <Select
                  id="page-select"
                  value={page}
                  onChange={handleSelectPage}
                  displayEmpty={true}
                >
                  {pages.map(pageNumber => {
                    return (
                      <MenuItem key={pageNumber} value={pageNumber}>{(pageNumber + 1).toString()}</MenuItem>
                    )
                  })}
                </Select>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                colSpan={4}
                count={serviceRequestCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </RMSTable>
      )}
    </>
  );
};

export default ServiceRequests;
