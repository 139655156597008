import * as React from "react";

import { CircularProgress } from "@material-ui/core";
interface ILoadingProps {
  isLoading: boolean;
  message?:string
}
// tslint:disable-next-line
const Loading: React.SFC<ILoadingProps> = props => {
  if (!props.isLoading) {
    return null;
  }
// TODO: swap this for material component when ready
return <CircularProgress  color="primary" />
  // return <div>{props.message ? props.message : "Loading..."}</div>;
};
export default Loading;
