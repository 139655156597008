import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles(() => ({
  box: {
    padding: "24px 0px"
  }
}));

const TabPanel = (props: TabPanelProps) => {
  const classes = useStyles(props);
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && 
        <Box className={classes.box} p={3}>{children}</Box>
      }
    </Typography>
  );
}
export default TabPanel;
