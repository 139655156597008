import { amber, green } from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export type SnackbarVariant = 'error' | 'info' | 'success' | 'warning';
export interface ISnackbarProps {
  message: string
  isOpen: boolean
  onClose: () => void
  variant: SnackbarVariant
}

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

const useStyles1 = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    alignItems: 'center',
    display: 'block',
  },
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: amber[700],
  }
}));

export default function MySnackbarContentWrapper(props: ISnackbarProps) {
  const classes = useStyles1(props);
  const { message, isOpen, onClose, variant, ...other } = props;
  // tslint:disable-next-line
  const Icon = variantIcon[variant];

  return (
    <Snackbar
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          open={isOpen}
          autoHideDuration={6000}
          onClose={onClose}
    >
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Grid container={true} alignItems="flex-start">
              <Grid item={true} xs={2}><Icon className={clsx(classes.icon, classes.iconVariant)} /></Grid>
              <Grid item={true} xs={10}>{message}</Grid>
            </Grid>
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
}